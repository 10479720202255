import React, { useState } from "react";
import { Modal, ModalOverlay } from "./styles";
import { PrimaryButton } from "../FlowDetail";
import { mobileAppStore } from "../store/store";
import image from "../../../assets/onboarding/timer.png";

export const Timer = () => {
  const showOnboarding = mobileAppStore.use.showOnboarding();
  const [gotItClicked, setGotItClicked] = useState(false);

  if (!showOnboarding || gotItClicked) {
    return null;
  }

  return (
    <>
      <Modal style={{ display: showOnboarding ? "block" : "none" }}>
        <img src={image} />
        <h2>Timer</h2>
        <p>
          Some steps include a timer. Once time is up, a button will appear to advance to the next step. If you finish
          early, click the X button near the timer to advance.
        </p>
        <PrimaryButton
          onClick={() => {
            setGotItClicked(true);
          }}
        >
          Got it
        </PrimaryButton>
      </Modal>
      <ModalOverlay style={{ display: showOnboarding ? "block" : "none" }} />
    </>
  );
};
