import React, { useEffect } from "react";
import sanitizeHtml from "sanitize-html";
import { useNavigate, useParams } from "react-router";
import { OnDeviceParticipant, mobileAppStore } from "../store/store";
import styled from "styled-components";
import { LargeClose } from "../../../assets/icons/Icons";
import { determineContent } from "../../../session/determineContent";
import { wsCreateIntl } from "../../../session/intl";
import { PrimaryButton } from "../FlowDetail";
import { LocalSessionCore } from "./LocalSessionCore";
import { CountDownV2 } from "../../UI/CountDownV2/CountDownV2";
import { KeepAwake } from "@capacitor-community/keep-awake";
import { Haptics } from "@capacitor/haptics";
import { TurnTaking } from "../Modals/TurnTaking";
import { PassItOn } from "../Modals/PassItOn";
import { Timer } from "../Modals/Timer";
import { EndFlow } from "../Modals/EndFlow";
import { SFX2 } from "../../../assets/SFX2";

const TRANSITION_DURATION = 250;

const SessionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
`;

const Top = styled.div`
  position: relative;
  #close {
    position: absolute;
    right: 0px;
  }
`;

const Content = styled.div`
  font-family: "ITC Clearface";
  font-size: 30px;
  text-align: center;
  white-space: pre-wrap;

  display: flex;
  flex-direction: column;
  align-items: center;

  opacity: 1;
  position: relative;
  top: 0px;
  transition: all ${TRANSITION_DURATION}ms ease-in-out;

  &.transitioning {
    top: -15px;
    opacity: 0;
  }

  small {
    display: block;
    font-size: 26px;
  }

  * {
    font-family: "ITC Clearface";
  }

  .quote {
    background-color: #000;
    color: #fff;
    padding: 25px 35px;
    border-radius: 20px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 30px;
    max-width: 400px;
    position: relative;

    margin-left: 10px;
    margin-right: 10px;

    &:after {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0px;
      width: 0;
      height: 0;
      border-top: solid 30px transparent;
      border-left: solid 50px black;
      border-bottom: solid 30px transparent;
    }
  }
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  height: 100px;

  opacity: 1;
  position: relative;
  top: 0px;
  transition: all ${TRANSITION_DURATION}ms ease-in-out;
  transition-delay: 100ms;

  &.transitioning {
    top: -15px;
    opacity: 0;
  }

  button {
    position: absolute;
    top: 0px;
  }

  div {
    position: absolute;
  }
`;

const Choices = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  margin-top: 15px;

  button {
    margin: 0px;
  }
`;

interface SessionProps {
  flow: AppFlow;
}

const intl = wsCreateIntl("en");

export const Session: React.FC<SessionProps> = ({ flow }) => {
  const params = useParams();
  const flowId = params.flowId;
  const navigate = useNavigate();

  const [transitioning, setTransitioning] = React.useState(false);
  const [renderTrigger, setRenderTrigger] = React.useState(Date.now());
  const [content, setContent] = React.useState<StepContent | undefined>(undefined);
  const [timeOnStep, setTimeOnStep] = React.useState(0);
  const [vibrating, setVibrating] = React.useState(false);
  const [chimePlayed, setChimePlayed] = React.useState(false);

  const [showTurntaking] = React.useState(false);
  const [showTimer] = React.useState(false);
  const [showPassItOn] = React.useState(false);
  const [showEndFlow, setShowEndFlow] = React.useState(false);

  const participants = mobileAppStore.use.participants();

  const [sessionCore] = React.useState(
    new LocalSessionCore(flow, participants, () => {
      navigate("/flows/" + flowId + "/session-end");
    }),
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;

    const timeout = setTimeout(() => {
      interval = setInterval(() => {
        setTimeOnStep((prev) => prev + 1);
      }, 1000);
    }, 400);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [renderTrigger]);

  useEffect(() => {
    sessionCore.tick(0);
  }, []);

  useEffect(() => {
    const keepAwake = async () => {
      try {
        await KeepAwake.keepAwake();
      } catch (e) {
        console.log("Error keeping awake", e);
      }
    };

    keepAwake();

    return () => {
      const allowSleep = async () => {
        await KeepAwake.allowSleep();
      };

      allowSleep();
    };
  }, []);

  useEffect(() => {
    setTransitioning(true);

    setTimeout(() => {
      setTimeOnStep(0);
      setVibrating(false);
      setChimePlayed(false);
      setContent(
        determineContent(
          sessionCore.currentStep(),
          sessionCore.userResponses,
          {}, // TODO vg: add support for this
          sessionCore.participants.reduce((a, v) => ({ ...a, [v.id]: v }), {}),
          (sessionCore.participants.find((p) => p.role === "A") as OnDeviceParticipant).id,
          intl,
          "en",
          flow.data?.data || {},
          "LocalSession-" + sessionCore.randomSeed,
        ),
      );
      setTransitioning(false);
    }, TRANSITION_DURATION + 100);
  }, [renderTrigger]);

  const totalTime = content ? content.actionTiming : 0;
  const timeRemainingOnStep = Math.max(totalTime - timeOnStep, 0);

  useEffect(() => {
    if (timeOnStep >= totalTime + 1 && totalTime > 0 && !chimePlayed) {
      SFX2.playAudio("timeChime", 0.7);
      setChimePlayed(true);
    }

    if (timeOnStep > totalTime + 5 && !vibrating) {
      setVibrating(true);
    }
  }, [timeRemainingOnStep, timeOnStep, totalTime, vibrating, chimePlayed]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    let timeout: NodeJS.Timeout;

    if (vibrating) {
      const doVibrate = async () => {
        await Haptics.vibrate({ duration: 200 });
        timeout = setTimeout(async () => {
          await Haptics.vibrate({ duration: 500 });
        }, 300);
      };

      interval = setInterval(() => {
        doVibrate();
      }, 4500);
    }

    return () => {
      if (interval) clearInterval(interval);
      if (timeout) clearTimeout(timeout);
    };
  }, [vibrating]);

  const buttonClasses = [""];
  if (timeRemainingOnStep !== 0 && !Number.isNaN(timeRemainingOnStep)) buttonClasses.push("hidden");
  if (totalTime && timeRemainingOnStep) buttonClasses.push("entering");
  if (vibrating) buttonClasses.push("pulsing");

  const choiceAvailable = (
    step: ChoicesStep,
    choice: string,
    userID: number,
    stepState: { [userID: number]: string | boolean },
  ) => {
    if (!stepState) return true;
    if (Object.values(stepState).find((c) => c === choice) && step.uniquePicks) return false;
    if (stepState[userID] === choice) return false;
    return true;
  };

  return (
    <SessionWrapper>
      {showEndFlow && (
        <EndFlow
          visible={showEndFlow}
          hide={() => {
            setShowEndFlow(false);
          }}
          endFlow={() => {
            navigate("/");
          }}
        />
      )}
      {showTurntaking && <TurnTaking />}
      {showTimer && <Timer />}
      {showPassItOn && <PassItOn />}
      <Top>
        <a
          id="close"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setShowEndFlow(true);
          }}
        >
          <LargeClose />
        </a>
      </Top>

      <Content className={transitioning ? "transitioning" : ""}>
        <div
          dangerouslySetInnerHTML={{
            __html:
              sanitizeHtml(content?.text || "", {
                allowedTags: ["div", "b", "i", "u", "p", "br", "small"],
                allowedClasses: {
                  div: ["quote"],
                },
              }) || "",
          }}
        ></div>

        {content?.choices && content.choices.length > 0 && (
          <Choices>
            {content?.choices.map((choice) => {
              return (
                <PrimaryButton
                  disabled={
                    !choiceAvailable(
                      sessionCore.currentStep() as ChoicesStep,
                      choice.text,
                      0,
                      sessionCore.stepState[sessionCore.currentStepIndex],
                    ) || transitioning
                  }
                  key={choice.text}
                  onClick={(e) => {
                    e.preventDefault();
                    sessionCore.doAction(
                      choice.onClick,
                      (sessionCore.participants.find((p) => p.role === "A") as OnDeviceParticipant).id,
                      sessionCore.currentStepIndex,
                      choice.text,
                      setRenderTrigger,
                    );
                  }}
                >
                  {choice.text}
                </PrimaryButton>
              );
            })}
          </Choices>
        )}
      </Content>
      <Bottom className={transitioning ? "transitioning" : ""}>
        <CountDownV2
          totalTime={totalTime}
          timeRemainingOnStep={timeRemainingOnStep}
          transitioning={transitioning}
          onClose={() => {
            if (content) {
              sessionCore.doAction(
                content.actions[0].onClick,
                (sessionCore.participants.find((p) => p.role === "A") as OnDeviceParticipant).id,
                sessionCore.currentStepIndex,
                "",
                setRenderTrigger,
              );
            }
          }}
        />
        {content?.actions.map((action) => {
          return (
            <PrimaryButton
              className={buttonClasses.join(" ")}
              disabled={buttonClasses.includes("hidden") || transitioning}
              key={action.text}
              onClick={(e) => {
                e.preventDefault();

                if (
                  action.onClick === "end_session" ||
                  sessionCore.currentStepIndex === sessionCore.flattenedSteps.length - 1
                ) {
                  navigate("/flows/" + flowId + "/session-end");
                  return;
                }

                sessionCore.doAction(
                  action.onClick,
                  (sessionCore.participants.find((p) => p.role === "A") as OnDeviceParticipant).id,
                  sessionCore.currentStepIndex,
                  "",
                  setRenderTrigger,
                );
              }}
            >
              {action.text}
            </PrimaryButton>
          );
        })}
      </Bottom>
    </SessionWrapper>
  );
};
