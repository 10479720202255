import React from "react";
import { Modal, ModalOverlay } from "./styles";
import { PrimaryButton } from "../FlowDetail";

export const TeamsPromo: React.FC<{ visible: boolean; hide: () => void }> = ({ visible, hide }) => {
  return (
    <>
      <Modal style={{ display: visible ? "block" : "none" }}>
        <h3>This flow is available in Warmspace for Organizations</h3>
        <p style={{ margin: 0 }}>Click below to read more about how to use Warmspace in a team setting.</p>
        <PrimaryButton
          onClick={() => {
            hide();
            window.open("https://warmspace.io/organizations", "_blank");
          }}
        >
          Find out more
        </PrimaryButton>

        <a
          style={{ color: "black" }}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            hide();
          }}
        >
          Close{" "}
        </a>
      </Modal>
      <ModalOverlay
        style={{ display: visible ? "block" : "none" }}
        onClick={() => {
          hide();
        }}
      />
    </>
  );
};
