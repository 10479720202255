import React from "react";
import { Root } from "react-dom/client";
import { hideLoading } from "../lib/hideLoading";
import { BrowserRouter, Outlet, Route, Routes } from "react-router";
import { ProtectedRoute } from "./ProtectedRoute";
import { Header } from "../components/UI/Dashboard/Header/Header";
import { Wrapper } from "../components/UI/Dashboard/Wrapper";
import Logout from "./Logout/Logout";
import { FallbackError } from "../components/UI/ErrorMessages/FallbackError";
import { Home } from "../components/UI/Dashboard/Pages/Home";
import { Analytics } from "../components/UI/Dashboard/Pages/Analytics";
import { Integrations } from "../components/UI/Dashboard/Pages/Integrations";
import { Teams, EditTeam, Team, Settings, Member, CreateTeam } from "../components/UI/Dashboard/Pages/Teams";
import { Profile } from "../components/UI/Dashboard/Pages/Profile";
import { Calendar } from "../components/UI/Dashboard/Pages/Calendar/Calendar";
import { Support } from "../components/UI/Dashboard/Pages/Support";
import { loadFlows, loadMe, loadSettings } from "../components/UI/Dashboard/API/initializationApi";
import { BreadCrumbs } from "../components/UI/Dashboard/BreadCrumbs/BreadCrumbs";
import styled from "styled-components";
import { Login } from "../components/UI/Dashboard/Pages/Login/Login";
import { installNavigatorOnlineListener } from "../lib/navigatorOnline";
import { IntlProvider } from "react-intl";
import { JoinParentInvitation } from "../components/UI/Dashboard/Pages/JoinParentInvitation";
import { Flows } from "../components/UI/Dashboard/Pages/Flows";
import { FlowDetail } from "../components/MobileApp/FlowDetail";
import { FlowStart } from "../components/MobileApp/FlowStart";
import { SessionLoader } from "../components/MobileApp/Session/SessionLoader";
import { SessionEnd } from "../components/MobileApp/Session/SessionEnd";
import { loadMyTeamList } from "../components/UI/Dashboard/API/teamApi";
import { EnterEmail } from "../components/UI/Dashboard/Pages/Login/EnterEmail";
import { EnterName } from "../components/UI/Dashboard/Pages/Login/EnterName";
import { VerifyCode } from "../components/UI/Dashboard/Pages/Login/VerifyCode";

export const PageContainer = styled.div`
  height: calc(100% - 100px);
`;

export class Core {
  root: Root;

  constructor(root: Root) {
    this.root = root;
  }

  static async initialRequests() {
    const controller = new AbortController();

    try {
      // Load the current user and global settings, which must be waited on
      await Promise.all([
        loadMe(controller),
        loadSettings(controller),
        loadFlows(controller),
        loadMyTeamList(controller),
      ]);

      // Start loading the teams, which can only be loaded but no need to wait for them
    } catch (e) {
      console.log("initial requests error:", e);
      controller.abort();
    }
  }

  static async Boot(root: Root) {
    try {
      const core = new Core(root);
      await Core.initialRequests();
      core.boot();
    } catch (e: any) {
      console.log("boot error:", e);
      root.render(<FallbackError error={e.error} />);
      hideLoading();
    }
  }

  async boot() {
    installNavigatorOnlineListener();
    this.render();
    hideLoading();
  }

  render() {
    this.root.render(
      <IntlProvider locale="en">
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Header />
                  <Wrapper>
                    <main>
                      <PageContainer>
                        <BreadCrumbs />
                        <Outlet />
                      </PageContainer>
                    </main>
                  </Wrapper>
                </ProtectedRoute>
              }
            >
              <Route path="" element={<Home />} />
              <Route path="profile">
                <Route path="" element={<Profile />} />
                <Route path=":profileUserId" element={<Profile />} />
              </Route>
              <Route path="calendar" element={<Calendar initialShowScheduleForm={false} />} />
              <Route path="flows">
                <Route path="" element={<Flows />} />
                <Route path=":flowId" element={<FlowDetail key="flow" />} />
                <Route path=":flowId/start" element={<FlowStart key="flowStart" />} />
                <Route path=":flowId/session" element={<SessionLoader key="session" />} />
                <Route path=":flowId/session-end" element={<SessionEnd key="session-end" />} />
              </Route>
              <Route path="teams">
                <Route path="" element={<Teams />} />
                <Route path="create-team" element={<CreateTeam />} />
                <Route path=":teamHashId/edit" element={<EditTeam />} />
                <Route path=":teamHashId/schedule/:flowHashId?" element={<Calendar initialShowScheduleForm={true} />} />
                <Route path=":teamHashId" element={<Team />} />
                <Route path=":teamHashId/settings" element={<Settings />} />
                <Route path=":teamHashId/:memberId" element={<Member />} />
              </Route>
              <Route path="analytics" element={<Analytics />} />
              <Route path="integrations" element={<Integrations />} />
              <Route path="support" element={<Support />} />
            </Route>
            <Route path="/join/:parentInvitationHashId" element={<JoinParentInvitation />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/login" element={<Login />} />
            <Route path="/email" element={<EnterEmail />} />
            <Route path="/create-account" element={<EnterName />} />
            <Route path="/verify" element={<VerifyCode />} />

            <Route path="*" element={<h1>Page not found</h1>} />
          </Routes>
        </BrowserRouter>
      </IntlProvider>,
    );
  }
}
