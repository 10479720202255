import React, { useState } from "react";
import { Modal, ModalOverlay } from "./styles";
import { PrimaryButton } from "../FlowDetail";
import { mobileAppStore } from "../store/store";
import image from "../../../assets/login-carousel/login-swipe-2.png";

export const IntroducingFlows = () => {
  const showOnboarding = mobileAppStore.use.showOnboarding();
  const [gotItClicked, setGotItClicked] = useState(false);

  if (!showOnboarding || gotItClicked) {
    return null;
  }

  return (
    <>
      <Modal style={{ display: showOnboarding ? "block" : "none" }}>
        <img src={image} />
        <h2>Introducing Flows</h2>
        <p>
          A Flow is a guided interaction for at least two people. If you’re by yourself, create a pseudo name to
          simulate the experience.
        </p>
        <PrimaryButton
          onClick={() => {
            setGotItClicked(true);
          }}
        >
          Got it
        </PrimaryButton>
      </Modal>
      <ModalOverlay style={{ display: showOnboarding ? "block" : "none" }} />
    </>
  );
};
