export function addOverlapInfo(events: CalendarEvent[]): CalendarEvent[] {
  // Sort events by start time to ensure consistent overlap ordering
  events = events.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return events.map((event, i) => {
    let overlaps = 0;
    let overlapIndex = 0;
    const eventStart = new Date(event.date).getTime();
    const eventEnd = eventStart + event.duration * 60 * 1000; // Convert duration to milliseconds

    const overlapIndices = [];

    for (let j = 0; j < events.length; j++) {
      if (i === j) continue;

      const otherStart = new Date(events[j].date).getTime();
      const otherEnd = otherStart + events[j].duration * 60 * 1000;

      if (
        (otherStart >= eventStart && otherStart < eventEnd) || // Other event starts inside current event
        (eventStart >= otherStart && eventStart < otherEnd) // Current event starts inside other event
      ) {
        overlaps++;
        overlapIndices.push(j);
      }
    }

    // Assign an index based on its position among overlapping events
    overlapIndex = overlapIndices.filter((index) => index < i).length;

    return { ...event, overlaps, overlapIndex };
  });
}
