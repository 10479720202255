import React, { useState } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router";
import styled from "styled-components";

import { FormattedMessage, useIntl } from "react-intl";
import { saveToStoreAndDevice } from "../../../../MobileApp/store/store";
import { refreshStoreFromApi } from "../../../../MobileApp/store/store";
import { mobileAppStore } from "../../../../MobileApp/store/store";
import { postSignup } from "../../../../MobileApp/apiRequests/postSignup";
import { PrimaryButton } from "../../../../MobileApp/FlowDetail";
import { LoginWrapper } from "./Login";
import { Message } from "./EnterEmail";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  padding-top: 130px;

  box-sizing: border-box;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  justify-content: space-between;

  h2 {
    margin: 0px;
    font-family: "ITC Clearface";
    font-weight: normal;
    text-align: center;
    font-size: 35px;
  }

  h3 {
    margin: 0px;
    margin-bottom: 15px;
  }

  input {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    font-family: Avenir;
    font-weight: normal;
    padding: 14px 15px;
    font-size: 20px;
    border: 0px;
  }
`;

export const FormWrapper = styled.div`
  padding: 0px 20px;
`;

export const Form = styled.form``;

export const handleAuthResponse = async (
  response: AuthResponse,
  setHeading: (value: ((prevState: string | null) => string | null) | string | null) => void,
  setMessage: (value: ((prevState: string | null) => string | null) | string | null) => void,
  navigate: NavigateFunction,
  setButtonLoading: (value: ((prevState: boolean | null) => boolean | null) | boolean | null) => void,
) => {
  const authResponse = response as AuthResponse;

  setButtonLoading(false);

  if (authResponse.heading) {
    setHeading(authResponse.heading);
  }

  if (authResponse.message) {
    setMessage(authResponse.message);
  }

  saveToStoreAndDevice("authResponse", authResponse);

  if (authResponse.currentUser) {
    await refreshStoreFromApi();
    navigate("/");
  } else if (authResponse.verificationHash) {
    navigate("/verify");
  }
};

export const EnterName: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState<boolean | null>(null);
  const [name, setName] = useState<string>("");

  const email = mobileAppStore.use.email() || "";

  const [heading, setHeading] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setButtonLoading(true);

    postSignup({
      name: name,
      email: email,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      school: "Warmspace",
    }).then((response) => {
      setButtonLoading(false);
      handleAuthResponse(response, setHeading, setMessage, navigate, setButtonLoading);
    });
  }

  return (
    <LoginWrapper>
      <h2>
        <FormattedMessage id="signup.enter_name" defaultMessage="Enter your name" />
      </h2>

      <FormWrapper>
        <Message className={message && !buttonLoading ? "visible" : ""}>
          <b>{heading}</b>
          <br />
          <span dangerouslySetInnerHTML={{ __html: message || "" }}></span>
        </Message>
        <Form onSubmit={handleSubmit}>
          <input
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder={intl.formatMessage({
              id: "signup.name_placeholder",
              defaultMessage: "Your name",
            })}
          />
        </Form>
      </FormWrapper>

      <PrimaryButton onClick={handleSubmit} disabled={!name}>
        <FormattedMessage id="signup.continue" defaultMessage="Continue" />
      </PrimaryButton>
      <Link to="/">
        <FormattedMessage defaultMessage="Cancel" id="login.cancel" />
      </Link>
    </LoginWrapper>
  );
};
