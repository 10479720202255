import { Preferences } from "@capacitor/preferences";
import { create } from "zustand";
import { combine } from "zustand/middleware";
import LZUTF8 from "lzutf8";
import { getStats } from "../apiRequests/getStats";
import { getFlows } from "../apiRequests/getFlows";
import { getMe } from "../apiRequests/getMe";
import { createSelectors } from "../../../lib/createSelectors";
import { Capacitor } from "@capacitor/core";
import { getOnlineStatus } from "../../../lib/navigatorOnline";

// Using the 'combine' way of creating a store so that the type
// of the store gets inferred automatically.
// So no need to maintain a type for the store.
export const useStore = create(
  combine(
    {
      settings: {} as GlobalSettings,
      heatType: "Ignite" as string,

      flows: { flows: [] as AppFlow[] } as AppFlows,
      stats: {} as AppStats,
      flowsWithData: {} as AppFlowsWithData,
      authResponse: undefined as AuthResponse | undefined,
      email: undefined as string | undefined,

      myTeams: undefined as undefined | string[],
      navOpen: false as boolean,
      navSubMenu: "" as "" | "index" | "organizations" | "appearance",
      selectedTeamId: undefined as string | undefined,
      stages: {} as Stages,
      teamMembers: {} as TeamMembers,
      member: {} as TeamMember,
      teams: {} as Teams,
      teamsFlows: {} as TeamFlows,

      // On device flow state
      sessionStartTime: undefined as Date | undefined,
      participants: [] as OnDeviceParticipant[],

      // Show onboarding tutorial, persisted to device storage
      showOnboarding: true,

      // If we have a connection to the internet
      online: getOnlineStatus(),
    },
    () => ({}),
  ),
);

export const mobileAppStore = createSelectors(useStore);

export interface OnDeviceParticipant {
  name: string;
  color: string;
  id: number;
  role: Roles;
  actionStatus: "NONE" | "PENDING" | "COMPLETED";
}

export const appUrlPrefix = `${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/api/v1/app`;

export const clearLocalDeviceCache = async () => {
  if (Capacitor.isNativePlatform()) {
    await Preferences.clear();
  } else {
    localStorage.clear();
  }
};
export const saveToDevice = async (key: string, value: any) => {
  const originalJsonString = JSON.stringify(value);
  const compressedJsonString = LZUTF8.compress(originalJsonString, { outputEncoding: "StorageBinaryString" });

  if (Capacitor.isNativePlatform()) {
    await Preferences.set({
      key,
      value: compressedJsonString as string,
    });
  } else {
    localStorage.setItem(key, compressedJsonString);
  }
};

export const updateStoreValue = (key: string, value: any) => {
  useStore.setState({ [key]: value });
};

// Sets a value in the store and also caches it.
export async function saveToStoreAndDevice(key: string, data: any, skipStore: boolean = false) {
  if (!skipStore) {
    updateStoreValue(key, data);
  }
  await saveToDevice(key, data);
}

async function getStoredValue(key: string) {
  if (Capacitor.isNativePlatform()) {
    const getResult = await Preferences.get({ key });
    return getResult.value;
  } else {
    return localStorage.getItem(key) as string | undefined;
  }
}

export const loadFromCache = async (key: string) => {
  const value = await getStoredValue(key);

  if (value) {
    try {
      const decompressedValue = LZUTF8.decompress(value, { inputEncoding: "StorageBinaryString" });
      return JSON.parse(decompressedValue);
    } catch (error) {
      console.log("Error decompressing or parsing cache value", key, value, error);
    }
  }
  return null;
};

// Initializes the store from cache.
export const initializeStoreFromCache = async () => {
  const keys = Object.keys(useStore.getState());

  // Load all the keys from the cache in parallel
  const promises = keys.map(async (key) => {
    const value = await loadFromCache(key);

    if (value === undefined || value === null) {
      return;
    }
    useStore.setState({ [key]: value });
  });
  // Wait for all the loads to complete
  await Promise.all(promises);

  // Load all the flow datas from the cache in parallel
  const allFlowHashIds = getAllFlowHashIds(useStore.getState().flows);
  const flowPromises = allFlowHashIds.map(async (hashId) => {
    const flowWithData = (await loadFromCache(`flows/${hashId}`)) as AppFlow;
    storeFlowWithData(flowWithData, false);
  });
  // Wait for all the loads to complete
  await Promise.all(flowPromises);
};

export const refreshStoreFromApi = async () => {
  const loginToken = useStore.getState().authResponse?.loginToken;

  if (loginToken) {
    await getFlows();
    await getMe();
    await getStats();
  }
};

export const storeFlowWithData = (flowWithData: AppFlow, override: boolean = true) => {
  if (!flowWithData) {
    console.debug("Received null flowWithData", flowWithData);
    return;
  }
  useStore.setState((state) => {
    const newFlowsWithData = Object.assign({}, state.flowsWithData);
    if (override || !newFlowsWithData[flowWithData.hashId]) {
      newFlowsWithData[flowWithData.hashId] = flowWithData;
      return {
        flowsWithData: newFlowsWithData,
      };
    }
    // Don't return any updates if we didn't replace the existing flow with data
    else {
      return {};
    }
  });
};

// Recursively load all the teams from the device
async function restoreTeams(myTeams: string[], teams: Teams) {
  if (myTeams) {
    for (const teamHashId of myTeams) {
      const team: Team = await loadFromCache(`team/${teamHashId}`);
      teams[teamHashId] = team;
      if ((team.subTeamIds?.length || 0) > 0) {
        const subteamsToRestore = team.subTeamIds!.filter((subTeamHashId) => !teams[subTeamHashId]);
        restoreTeams(subteamsToRestore, teams);
      }
    }
  }
}

export const initSettings = async () => {
  if (useStore.getState().settings.stages) return;

  const settings: GlobalSettings = await loadFromCache("settings");

  if (settings) {
    useStore.setState({ settings: settings });
  }
};

export const initAuth = async () => {
  if (useStore.getState().authResponse) return;

  const authResponse: AuthResponse = await loadFromCache("authResponse");

  useStore.setState({ authResponse: authResponse });
};

export const initTeamMembers = async (teamHashId: string) => {
  // First make sure all the teams are restored
  const teams = useStore.getState().teams;
  if (!teams[teamHashId]) {
    await initTeams([teamHashId], teams);
  }

  // Then load the members from the cache
  const members: TeamMember[] = await loadFromCache(`team/${teamHashId}/members`);

  // Update the state
  const updatedTeamMembers: TeamMembers = Object.assign({}, useStore.getState().teamMembers);
  updatedTeamMembers[teamHashId] = members;
  useStore.setState({ teamMembers: updatedTeamMembers });
};

async function initTeams(teamHashIds: string[], teams: Teams) {
  const updatedTeams = Object.assign({}, teams);
  await restoreTeams(teamHashIds, updatedTeams);

  const allTeams = Object.values(updatedTeams);

  // Initialize the graph of teams
  for (const team of allTeams) {
    if (!team.subTeams) {
      team.subTeams = team.subTeamIds?.map((teamHashId) => updatedTeams[teamHashId]);
    }
  }

  useStore.setState({
    teams: updatedTeams,
  });

  return updatedTeams;
}

export const initMyTeams = async (teams: Teams = {}) => {
  if (Object.keys(useStore.getState().teams).length === 0) {
    const myTeams: string[] | undefined = (await loadFromCache("my-teams")) || undefined;
    const selectedTeamId: string | undefined = await loadFromCache("selected-team");
    useStore.setState({
      myTeams: myTeams,
      selectedTeamId: selectedTeamId || myTeams?.length ? myTeams?.[0] : undefined,
    });

    if (!myTeams) return;

    await initTeams(myTeams, teams);

    for (const teamHashId of myTeams) {
      initTeamMembers(teamHashId);
    }
  }
};

export const initTeamFlows = async (teamHashId: string) => {
  const teamFlows: AppFlows | undefined = await loadFromCache(`flows/${teamHashId}`);
  if (!teamFlows) return;

  const newTeamFlows = Object.assign({}, useStore.getState().teamsFlows);
  newTeamFlows[teamHashId] = teamFlows;
  useStore.setState({ teamsFlows: newTeamFlows });
};

export const storeMyTeamIds = (myTeams: string[] | undefined) => {
  saveToDevice("my-teams", myTeams);
  useStore.setState({
    myTeams: myTeams,
  });

  if (!useStore.getState().selectedTeamId && myTeams && myTeams.length > 0) {
    const selectedTeamId = myTeams[0];
    useStore.setState({
      selectedTeamId: selectedTeamId,
    });
    saveToDevice("selected-team", selectedTeamId);
  }
};

export const storeTeamMembers = (teamHashId: string, members: TeamMember[]) => {
  saveToDevice(`team/${teamHashId}/members`, members);

  useStore.setState((state) => {
    const updatedTeamMembers = Object.assign({}, state.teamMembers);
    updatedTeamMembers[teamHashId] = members;
    return {
      teamMembers: updatedTeamMembers,
    };
  });
};

export const storeMember = (teamHashId: string, member: TeamMember) => {
  saveToDevice(`team/${teamHashId}/${member.id}`, member);
  useStore.setState({ member: member });
};

export const storeTeamList = (updateWhenDone: boolean, teamList?: Team[], updatedTeams: Teams = {}) => {
  if (!teamList) return;

  if (teamList.length > 0) {
    for (const team of teamList) {
      if (updatedTeams[team.hashId]) continue;

      updatedTeams[team.hashId] = team;

      // If there are subteams, then don't save the whole graph here
      if ((team?.subTeams?.length || 0) > 0) {
        const storedTeam = Object.assign({}, team);
        storedTeam.subTeams = undefined;
        saveToDevice(`team/${team.hashId}`, team);
      } else {
        saveToDevice(`team/${team.hashId}`, team);
      }
      storeTeamList(false, team.subTeams, updatedTeams);
    }
  }

  if (updateWhenDone) {
    useStore.setState((state) => {
      const newTeams = Object.assign({}, state.teams);
      Object.assign(newTeams, updatedTeams);

      return {
        teams: teamList.length > 0 ? newTeams : {},
      };
    });
  }
};

export const getAllFlowHashIds = (appFlows: AppFlows): string[] => {
  const allHashIds = new Set<string>();

  for (const flow of appFlows.flows || []) {
    allHashIds.add(flow.hashId);
  }

  const flowlists = appFlows.flowLists;
  for (const flowlist of flowlists || []) {
    getAllFlowHashIds(flowlist).forEach((f) => allHashIds.add(f));
  }
  return Array.from(allHashIds);
};
