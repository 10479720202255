import React from "react";
import { styled } from "styled-components";
import { Dot } from "../../../../../assets/icons/Icons";
import Button from "../../Button";
import { useNavigate } from "react-router";
import { Plan } from "grommet-icons";

export const TeamInfo = styled.div`
  background-color: var(--clr-bg-200);

  border-radius: 15px;
  padding: 23px 25px;
  flex: 1 1 70%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;

  @media (max-width: 768px) {
    padding: 20px;
  }

  color: light-dark(#333, #fff) !important;

  p strong,
  p span,
  p em {
    color: light-dark(#333, #fff) !important;
  }
`;

const RecommendationCard = styled(TeamInfo)<{ $expanded: boolean }>`
  flex: 1 1 ${({ $expanded }) => ($expanded ? "70%" : "30%")};
  gap: 10px;
  padding: 15px;

  small {
    font-size: 12px;
  }

  p {
    font-size: 16px;
    margin: 0;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    max-width: 100%;
  }
`;
const Separator = styled.div`
  border-bottom: 1px solid #8c8888;
`;

const FlowTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    font-weight: bold;
    color: #ff6c2c;
  }
`;

const StyledButton = styled(Button)`
  background: transparent;
  color: #75c770;
  border: 1px solid #75c770;
  width: fit-content;

  &:hover {
    background: transparent;
  }
`;

const ShortFlowDescription = styled.div`
  font-size: 12px;
  line-height: 24px;
  height: 50px;
  overflow: hidden;
  position: relative;
  p {
    margin: 0;
  }
`;

const MoreDescription = styled.div`
  position: absolute;
  font-size: 12px;
  background-color: var(--clr-bg-200);
  right: 0;
  padding: 0 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  &.more {
    top: 30px;
  }
`;

export const Recommendation = ({
  newRecommendation,
  teamHashId,
  teamName,
}: {
  newRecommendation: AppFlow;
  teamHashId: string;
  teamName?: string;
}) => {
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = React.useState(false);

  return (
    <RecommendationCard $expanded={showFullDescription}>
      <div>
        {teamName ? (
          <b>{teamName}</b>
        ) : (
          <>
            <Dot fill="#FF0000" /> <small>Recommendation</small>
          </>
        )}
      </div>
      {/* TODO: Check what is the correct recommended time period */}
      <p>Schedule a {newRecommendation.name} within the next two weeks</p>
      <Separator />
      <FlowTitle>
        <p>{newRecommendation.name}</p> <i>({newRecommendation.duration} min)</i>
      </FlowTitle>
      <div style={{ position: "relative" }}>
        {showFullDescription ? (
          <div dangerouslySetInnerHTML={{ __html: newRecommendation.description as string }} />
        ) : (
          <ShortFlowDescription dangerouslySetInnerHTML={{ __html: newRecommendation.description as string }} />
        )}
        <MoreDescription
          className={showFullDescription ? "" : "more"}
          onClick={() => setShowFullDescription(!showFullDescription)}
        >
          {showFullDescription ? "Show Less" : "...more"}
        </MoreDescription>
      </div>

      <StyledButton onClick={() => navigate(`/teams/${teamHashId}/schedule/${newRecommendation.hashId}`)}>
        <Plan color="#75c770" />
        Schedule now
      </StyledButton>
    </RecommendationCard>
  );
};
