import { ROLE_A, ROLE_B, EVERYONE_ELSE, ROLES } from "../../../lib/defaults";
import { OnDeviceParticipant } from "../store/store";
import {
  isBranchStep,
  isChoicesStep,
  isContentStep,
  isFeedbackScoreStep,
  isFeedbackWordsStep,
  isGoToStep,
  isIframeStep,
  isRoleRandomiserStep,
  isRoleRotationStep,
} from "../../../types/type_guards";
import { SetStateAction } from "react";

export interface StepState {
  [stepIndex: number]: {
    [userID: number]: string | boolean;
  };
}

export class LocalSessionCore {
  flow?: AppFlow;

  currentStepIndex: number = 0;
  stepState: StepState = {};
  userResponses: UserResponses = {};
  randomSeed: number = Date.now();
  flattenedSteps: AnyStep[] = [];
  participants: OnDeviceParticipant[] = [];
  onEndFlow: () => void;

  constructor(flow: AppFlow, participants: OnDeviceParticipant[], onEndFlow: () => void) {
    this.loadFlow(flow);
    this.setParticipants(participants);
    this.onEndFlow = onEndFlow;
  }

  loadFlow(flow: AppFlow) {
    this.flow = flow;
    this.flattenedSteps = flow.data?.sections.flatMap((section) => section.steps) || [];
  }

  setParticipants(participants: OnDeviceParticipant[]) {
    this.participants = participants;
  }

  currentStep() {
    return this.flattenedSteps?.[this.currentStepIndex];
  }

  // advanceOneStep advances the step index by one, but avoids going beyond
  // the amount of actual steps
  advanceSteps(count: number) {
    const newStepIndex = Math.min(this.flattenedSteps.length - 1, this.currentStepIndex + count);

    if (newStepIndex !== this.currentStepIndex) {
      this.currentStepIndex = newStepIndex;
      this.tick(newStepIndex);
    }
  }

  randomiseRoles() {
    const randomRoleStartIndex = Math.floor(Math.random() * this.participants.length);

    this.participants[randomRoleStartIndex].role = ROLE_A;
    this.participants[(randomRoleStartIndex + 1) % this.participants.length].role = ROLE_B;

    this.participants.forEach((participant, i) => {
      if (i !== randomRoleStartIndex && i !== (randomRoleStartIndex + 1) % this.participants.length) {
        participant.role = EVERYONE_ELSE;
      }
    });
  }

  rotateRoles() {
    const currentRoleStartIndex = this.participants.findIndex((u) => u.role === ROLE_A);
    const nextRoleStartIndex = (currentRoleStartIndex + 1) % this.participants.length;

    const newParticipants = this.participants.map((u, i) => {
      let assign = { role: EVERYONE_ELSE };

      ROLES.forEach((role, roleIndex) => {
        if (roleIndex > this.participants.length - 1) return; // Don't try to assign more roles than there are users.
        if (i === (nextRoleStartIndex + roleIndex) % this.participants.length) {
          assign = { role: role };
        }
      });

      return Object.assign({}, u, assign);
    });

    this.participants = newParticipants;
  }

  // countActions takes a step and figures out how many actions are on that step
  // this is used to figure out if a step is done or not.
  countActions(step: AnyStep, userCount: number) {
    let actionCount = 0;

    // Determine number of actions on this step for shared content choices step
    if (isChoicesStep(step) && step.shareContent) {
      if (step.content[EVERYONE_ELSE]) {
        const content = step.content[EVERYONE_ELSE];
        if (content.choices && content.choices.length > 0) {
          actionCount = userCount;
        }
      }
    }

    // Determine number of actions on this step for shared content feedback score step
    if (isFeedbackScoreStep(step) && step.shareContent) {
      if (step.content[EVERYONE_ELSE]) {
        if (step.content[EVERYONE_ELSE].maxValue) {
          actionCount = userCount;
        }
      }
    }

    // Determine number of actions on this step for shared content
    if (isContentStep(step) && step.shareContent) {
      if (step.content[EVERYONE_ELSE]) {
        if (step.content[EVERYONE_ELSE].actions.length > 0 || step.audioFile) {
          actionCount = userCount;
        }
      }
    }

    // Determine number of actions on this step for not-shared content choices step
    if (isChoicesStep(step) && !step.shareContent) {
      ROLES.forEach((role) => {
        if (step.content[role]) {
          const content = step.content[role];
          if (content.choices && content.choices.length > 0) {
            actionCount += 1;
          }
        }
      });
    }

    // Determine number of actions on this step for not-shared content feedback score step
    if (isFeedbackScoreStep(step) && !step.shareContent) {
      ROLES.forEach((role) => {
        if (step.content[role]) {
          const content = step.content[role];
          if (content.maxValue) {
            actionCount += 1;
          }
        }
      });
    }

    // Determine number of actions on this step for not-shared content
    if (isContentStep(step) && !step.shareContent) {
      ROLES.forEach((role) => {
        if (step.content[role]) {
          const content = step.content[role];
          if (content.actions && content.actions.length > 0) {
            actionCount += 1;
          }
        }
      });
    }

    return actionCount;
  }

  // // countDone counts how many 'done' actions have been made on a step.
  countDone(
    step: ContentStep | ChoicesStep | FeedbackScoreStep,
    stepIndex: number,
    users: OnDeviceParticipant[],
    flowState: StepState,
  ) {
    let doneCount = 0;

    if (step.shareContent) {
      // If the step shares its content with everyone, then the amount of done
      // actions is just the number of times it has been marked done by any user.
      doneCount = Object.values(users).filter((u) => flowState[stepIndex][u.id]).length;
    } else {
      // If the step does Not share its content with everyone, then we only count
      // it as done if the person with the given role has completed the action
      // for their role. This lets us revisit a step with a new role assignment
      // and makes the system consider it incomplete.
      ROLES.forEach((role) => {
        let choices: Choice[] = [];

        if (isChoicesStep(step)) {
          choices = step.content[role].choices;
        }

        if (step.content[role].actions.length > 0 || (choices && choices?.length > 0)) {
          const userWithRole = users.find((u) => u.role === role);

          if (userWithRole) {
            if (flowState[stepIndex][userWithRole.id]) {
              doneCount += 1;
            }
          }
        }
      });

      if (isFeedbackScoreStep(step)) {
        ROLES.forEach((role) => {
          if (step.content[role].maxValue) {
            const userIDWithRole = users.find((u) => u.role === role)?.id;

            if (userIDWithRole) {
              if (flowState[stepIndex][userIDWithRole]) {
                doneCount += 1;
              }
            }
          }
        });
      }
    }

    return doneCount;
  }

  // DoAction
  doAction(
    action: ActionConsequence,
    userID: number,
    stepIndex: number,
    choice: string,
    setRenderTrigger: React.Dispatch<SetStateAction<number>>,
  ) {
    // If it is a goTo action:
    if (typeof action === "number") {
      const newStepIndex = this.flattenedSteps.findIndex((s) => s.id === action);
      if (newStepIndex > -1) {
        this.currentStepIndex = newStepIndex;
        this.tick(newStepIndex);
        return;
      }
    }

    // Initialize flowstate at this stepIndex.
    this.stepState[stepIndex] = this.stepState[stepIndex] || {};

    // Store user's choice / mark it as done.
    this.stepState[stepIndex][userID] = choice || true;

    this.userResponses[this.flattenedSteps[stepIndex].title.en] = Object.assign(
      {},
      this.userResponses[this.flattenedSteps[stepIndex].title.en],
      { A: choice },
    );

    // If it's a shareContent step, mark it as done for everyone.
    const step = this.flattenedSteps[stepIndex];
    if ((isContentStep(step) || isChoicesStep(step)) && step.shareContent) {
      this.participants.forEach((p) => {
        this.stepState[stepIndex][p.id] = true;
      });
    }

    this.tick(stepIndex);

    setRenderTrigger(Date.now());
  }

  tick(stepIndex: number) {
    const step = this.flattenedSteps[stepIndex];

    const settings = (step as AnySettingsStep).settings;
    if (settings.visibility == "video" || settings.sequenceVisibility == "sequenced") {
      this.advanceSteps(1);
      return;
    }

    if (isRoleRotationStep(step)) {
      this.rotateRoles();
      this.advanceSteps(1);
    }

    if (isRoleRandomiserStep(step)) {
      this.randomiseRoles();
      this.advanceSteps(1);
    }

    if (isGoToStep(step)) {
      const newStepIndex = this.flattenedSteps.findIndex((s) => s.id === step.settings.stepID);
      this.currentStepIndex = newStepIndex;
    }

    // if (isMarkStepComplete(step)) {
    //   const skipStepIndex = flattenedSteps.findIndex((s) => s.id === step.settings.stepID);
    //   const roleAUser = users.find((u) => u.role == ROLE_A);
    //   if (roleAUser) {
    //     flowState[skipStepIndex] = flowState[skipStepIndex] || {};
    //     flowState[skipStepIndex][roleAUser.id] = true;
    //     advanceOneStep(stepIndex);
    //   }
    //   return;
    // }

    if (isBranchStep(step)) {
      if (step.settings.conditional === "allCompleted") {
        const targetStepIndex = this.flattenedSteps.findIndex((s) => s.id === step.settings.stepID);
        const allComplete = this.participants.every((u) => {
          return this.stepState[targetStepIndex][u.id];
        });

        if (allComplete) {
          this.advanceSteps(2);
        } else {
          this.advanceSteps(1);
        }
      }
    }

    // Skip feedback and iframe steps on mobile
    if (isFeedbackWordsStep(step) || isFeedbackScoreStep(step) || isIframeStep(step)) {
      this.advanceSteps(1);
    }

    // If it's not a choices or content step, check if it's the last step, and if so end the flow.
    if (!isChoicesStep(step) && !isContentStep(step)) {
      if (this.flattenedSteps.length === this.currentStepIndex + 1) {
        this.onEndFlow();
        return;
      }

      return;
    }

    if (!this.stepState[stepIndex]) return;

    const actionCount = this.countActions(step, Object.values(this.participants).length);
    const doneCount = this.countDone(step, stepIndex, this.participants, this.stepState);

    if (step.settings.clearDone && doneCount == actionCount) {
      this.stepState[stepIndex] = {};
    }

    if (doneCount === actionCount) {
      this.advanceSteps(1);
    }
  }
}
