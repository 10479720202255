import React, { ReactNode } from "react";
import { Navigate } from "react-router";
import { mobileAppStore } from "../components/MobileApp/store/store";
import { useTripleCPress } from "../hooks/useTripleCPress";
import toast from "react-simple-toasts";

export const ProtectedRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
  const currentUser = mobileAppStore.use.authResponse()?.currentUser;

  useTripleCPress(() => {
    if (location.host.startsWith("localhost")) {
      const colorScheme = document.getElementById("color-scheme") as HTMLSelectElement;
      colorScheme.value = colorScheme.value === "dark" ? "light" : "dark";
      colorScheme.dispatchEvent(new Event("input"));
      toast("DevShortcut: Color scheme changed");
    }
  }, 500);

  // Redirect to login if not logged in
  if (!currentUser) return <Navigate to={`/login?redirect-uri=${location.pathname}`} replace={true} />;

  // Allow access if user is an admin
  if (currentUser.authorizations.includes("admin")) return children;

  // 🚧 Allow access to non-admins if running locally while under construction
  if (currentUser && location.host.startsWith("localhost")) return children;

  // Redirect to schedule app otherwise
  return <Navigate to="https://schedule.warmspace.io" />;
};
