import React, { useEffect, useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useNavigate, useParams } from "react-router";
import { BackButton, PrimaryButton } from "./FlowDetail";
import { ChevronLeft, LargeClose } from "../../assets/icons/Icons";
import { mobileAppStore, OnDeviceParticipant, useStore } from "./store/store";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { IntroducingFlows } from "./Modals/IntroducingFlows";
import { Keyboard } from "@capacitor/keyboard";

const FlowStartPage = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));



  margin-bottom: 0px;

  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-between;

  padding: 45px 20px;
  padding-bottom: 20px;

  h1 {
    font-family: "ITC Clearface";
    margin-bottom: 0px;
    margin: 0px;l
  }

  h2 {
    font-weight: normal;
    margin-bottom: 15px;
    font-size: 24px;
  }
`;

const ParticipantNames = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;

  z-index: 10;
`;

const Participant = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  font-size: 23px;
  border-bottom: 1px solid #000;
  padding: 10px 0px;
  flex-grow: 0;
  box-sizing: border-box;

  height: 53px;

  &:last-child {
    border: 0px;
  }

  span {
    width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  div {
    width: 26px;
    flex-shrink: 0;
  }

  input {
    font-size: 25px;
    background-color: transparent;
    border: 1px solid #ccc;
    width: 100%;
    padding: 8px;
  }
`;

const DeleteParticipant = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }
`;

const AddNewUserButton = styled.button`
  color: #ff5f25;
  font-size: 25px;
  border: 0px;
  width: 100%;
  background-color: transparent;
  margin-top: 10px;
`;

const ParticipantBubble = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 10px;
  box-sizing: border-box;
`;

const DEFAULT_COLORS = ["#CA5AFF", "#42FF4A", "#5AEBFF", "#FF9142", "#FF5AA9", "#FFEC42", "#5AB0FF", "#FF4242"];

export const FlowStart: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [adding, setAdding] = useState(false);
  const params = useParams();
  const flowId = params.flowId;
  const namesList = useRef<HTMLDivElement>(null);

  const flowsWithData = mobileAppStore.use.flowsWithData();
  const currentUser = mobileAppStore.use.authResponse()?.currentUser;
  const participants = mobileAppStore.use.participants();

  useEffect(() => {
    if (participants.length === 0) {
      const color = DEFAULT_COLORS[Math.floor(Math.random() * DEFAULT_COLORS.length)];
      setParticipants([{ name: currentUser?.name || "Participant", id: 0, color, role: "A", actionStatus: "PENDING" }]);
      useStore.setState({ sessionStartTime: new Date() });
    }
  }, []);

  useEffect(() => {
    Keyboard.addListener("keyboardWillShow", () => {
      input.current?.focus();
      namesList.current?.scrollTo(0, namesList.current.scrollHeight);
    });

    return () => {
      Keyboard.removeAllListeners();
    };
  }, []);

  const setParticipants = (participants: OnDeviceParticipant[]) => {
    useStore.setState({ participants });
  };

  const addUser = (name: string) => {
    // Random timestamp id
    const id = Date.now();

    // Random color
    let color = "#000";
    let tries = 0;
    do {
      tries += 1;
      color = DEFAULT_COLORS[Math.floor(Math.random() * DEFAULT_COLORS.length)];
    } while (participants.some((participant) => participant.color === color) && tries < 8);

    const role = participants.length > 1 ? "EVERYONE_ELSE" : "B";

    setParticipants([
      ...participants,
      {
        name,
        id,
        color,
        role,
        actionStatus: "PENDING",
      },
    ]);
  };

  const deleteUser = (index: number) => {
    const newParticipants = [...participants];
    newParticipants.splice(index, 1);
    setParticipants(newParticipants);
  };

  const input = useRef<HTMLInputElement>(null);

  if (!flowId) {
    return (
      <h1>
        <FormattedMessage id="error.missing_flow_id" defaultMessage="Missing flow ID" />
      </h1>
    );
  }

  const flow = flowsWithData[flowId];

  const participantRefs = participants.map(() => React.createRef<HTMLInputElement>());

  return (
    <>
      <IntroducingFlows />

      <BackButton
        onClick={(e) => {
          navigate(-1);

          e.preventDefault();
        }}
      >
        <ChevronLeft stroke="#fff" />
      </BackButton>
      <FlowStartPage>
        <div>
          <h1>{flow.name}</h1>
          <h2>
            <FormattedMessage defaultMessage="Enter participant's names" id="flow_start.enter_participant_names" />
          </h2>
        </div>

        <ParticipantNames ref={namesList}>
          <TransitionGroup appear>
            {participants.map((participant, i) => (
              <CSSTransition
                timeout={300}
                classNames="slide"
                key={`participant-${participant.id}`}
                nodeRef={participantRefs[i]}
              >
                <Participant ref={participantRefs[i]}>
                  <ParticipantBubble
                    style={{
                      backgroundColor: participant.color,
                    }}
                  />
                  <span>{participant.name}</span>
                  {i !== 0 ? (
                    <DeleteParticipant
                      onClick={() => {
                        deleteUser(i);
                      }}
                    >
                      <LargeClose />
                    </DeleteParticipant>
                  ) : (
                    <div></div>
                  )}
                </Participant>
              </CSSTransition>
            ))}
          </TransitionGroup>

          <CSSTransition timeout={300} classNames="slide" key={`participant-addButton`}>
            <Participant style={{ borderTop: "1px solid #000", height: "auto" }}>
              <div></div>
              {adding ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setAdding(false);
                    if (input.current?.value) addUser(input.current.value);
                  }}
                >
                  <input
                    ref={input}
                    autoFocus
                    type="text"
                    placeholder={intl.formatMessage({
                      defaultMessage: "Enter name",
                      description: "Enter name",
                      id: "flow_start.enter_name",
                    })}
                    onBlur={() => {
                      setAdding(false);
                      if (input.current?.value) addUser(input.current.value);
                    }}
                  />
                </form>
              ) : (
                <AddNewUserButton
                  onClick={() => {
                    setAdding(true);
                  }}
                >
                  <FormattedMessage defaultMessage="Add another person" id="flow_start.add_another_person" />
                </AddNewUserButton>
              )}
              <div></div>
            </Participant>
          </CSSTransition>
        </ParticipantNames>

        <PrimaryButton
          style={{
            // These styles are a hack to prevent a visual glitch when
            // the start flow button is moved around while the software keyboard
            // is open and closed.
            opacity: adding ? 0 : 1,
            height: adding ? 0 : "auto",
            margin: adding ? 0 : "10px",
            transition: adding ? "all 0s" : "opacity 0.6s 0.3s",
          }}
          disabled={participants.length < 2 || adding}
          onClick={() => {
            navigate(`/flows/${flowId}/session`);
          }}
        >
          <FormattedMessage defaultMessage="Start Flow" id="flow_start.start" />
        </PrimaryButton>
      </FlowStartPage>
    </>
  );
};
