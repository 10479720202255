import { getAllFlowHashIds } from "../store/store";
import { getAndSaveFromApi } from "./ApiUtil";
import { getFlowWithData } from "./getFlowWithData";

export const getFlows = async (): Promise<AppFlows> => {
  const flows = await getAndSaveFromApi("flows");

  // Load all the flow data in the background (so don't use await here)
  getFlowsWithData(flows);

  return flows;
};

// Obtain each flow with data one at a time to avoid overloading the server when many users connect at once
export async function getFlowsWithData(flows: AppFlows) {
  for (const hashId of getAllFlowHashIds(flows)) {
    await getFlowWithData(hashId);
  }
}
