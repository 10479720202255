import React from "react";
import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import { ChevronLeft } from "../../assets/icons/Icons";
import participantCountIcon from "../../assets/icons/participant_count_icon.svg";

import timeIcon from "../../assets/icons/time_icon.svg";
import { mobileAppStore } from "./store/store";
import { FormattedMessage } from "react-intl";

export const BackButton = styled.button`
  position: fixed;
  top: calc(env(safe-area-inset-top) + 15px);
  left: 5px;
  z-index: 5;
  background: none;
  border: none;
  svg {
    width: 25px;
    height: 25px;
    position: relative;
    top: 2px;
  }
  cursor: pointer;
  padding: 10px 15px 10px 10px;
  border-radius: 100px;
  background-color: #0000007f;
`;

const FlowPage = styled.div`
  position: fixed;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #ffeecc;

  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  padding: 30px 25px;

  h1 {
    font-family: "ITC Clearface";
    margin: 0px;
    font-size: 30px;
  }

  p {
    font-size: 18px;
    margin-top: 10px;
  }

  .stats {
    margin-top: 3px;
    margin-bottom: 3px;
    display: flex;
    gap: 20px;
    font-size: 18px;
    width: 100%;

    padding-right: 14px;
    box-sizing: border-box;
    width: 85%;

    span {
      display: flex;
      gap: 5px;
    }

    img {
      position: relative;
      top: -2px;
      width: 19px;
      margin-right: 8px;
    }
  }
`;

const FlowImage = styled.img`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 100%;
  object-fit: cover;

  width: 100%;

  z-index: 3;
`;

export const PrimaryButton = styled.button`
  width: 100%;
  background: linear-gradient(90deg, #ff5f25 0%, #ff9900 100%);
  border: 0px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 25px;
  cursor: pointer;

  transition:
    top 0.5s ease-in-out,
    opacity 0.5s ease-in-out;
  transition-delay: 0.8s;
  position: relative;
  top: 0px;

  &:disabled {
    background: #aaa;
  }

  &.entering {
    transition: all 0s ease-in-out;
    transition-delay: 0s;
  }

  &.hidden {
    top: 150px;
    opacity: 0;
  }

  &.pulsing {
    animation-name: pulsing;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    transition: none !important;
  }

  @keyframes pulsing {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.08);
    }
    20% {
      transform: scale(0.98);
    }
    30% {
      transform: scale(1);
    }

    100% {
      transform: scale(1);
    }
  }
`;

export const FlowDetail: React.FC = () => {
  const params = useParams();
  const flowId = params.flowId;
  const navigate = useNavigate();

  const flowsWithData = mobileAppStore.use.flowsWithData();

  if (!flowId) {
    return (
      <h1>
        <FormattedMessage id="error.missing_flow_id" defaultMessage="Missing flow ID" />
      </h1>
    );
  }

  const flow = flowsWithData[flowId];

  if (!flow) {
    return (
      <h1>
        <FormattedMessage id="error.flow_not_found" defaultMessage="Flow not found " />
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <FormattedMessage id="buttons.back" defaultMessage="Back" />
        </a>
      </h1>
    );
  }

  return (
    <>
      <BackButton
        onClick={(e) => {
          navigate(-1);
          e.preventDefault();
        }}
      >
        <ChevronLeft />
      </BackButton>
      <FlowImage src={process.env.WARMSPACE_SCHEDULE_BACKEND_URL + "/" + flow.imageUrl} />
      <FlowPage>
        <h1>{flow.name}</h1>

        {<div dangerouslySetInnerHTML={{ __html: flow.description || "" }}></div>}
        {flow.description ? null : (
          <p>
            <FormattedMessage id="flow_details.no_description" defaultMessage="Flow has no description yet" />
          </p>
        )}

        <div className="stats">
          <span>
            <img src={timeIcon} />
            5m
          </span>
          <span>
            <img src={participantCountIcon} />
            2-4
          </span>
        </div>
        <PrimaryButton
          onClick={() => {
            navigate(`/flows/${flowId}/start`);
          }}
        >
          <FormattedMessage defaultMessage="Start Flow" id="flow_start.start" />
        </PrimaryButton>
      </FlowPage>
    </>
  );
};
