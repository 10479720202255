import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { clearLocalDeviceCache, useStore } from "../../components/MobileApp/store/store";

const Logout: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const _perform = async () => {
      try {
        document.cookie = "s=; Max-Age=0";
        useStore.setState({ authResponse: undefined });
        clearLocalDeviceCache();
      } finally {
        navigate("/");
      }
    };

    _perform();

    return () => {
      // Cleanup
    };
  }, []);
  return <div></div>;
};

export default Logout;
