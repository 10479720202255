import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0px;

  display: flex;
  flex-direction: row;
  padding-left: 20px;
  width: 100%;

  main {
    width: calc(100% - 178px);
    margin-left: calc(168px);
    margin-top: calc(75px + 20px);
    margin-right: 20px;
    margin-bottom: 10px;

    @media (max-width: 600px) {
      margin-left: 0px;
      width: calc(100% - 20px);
    }
  }
`;
