import React, { useState } from "react";
import { Modal, ModalOverlay } from "./styles";
import { PrimaryButton } from "../FlowDetail";
import { mobileAppStore } from "../store/store";
import image from "../../../assets/onboarding/turn-taking.png";

export const TurnTaking = () => {
  const showOnboarding = mobileAppStore.use.showOnboarding();
  const [gotItClicked, setGotItClicked] = useState(false);

  if (!showOnboarding || gotItClicked) {
    return null;
  }

  return (
    <>
      <Modal style={{ display: showOnboarding ? "block" : "none" }}>
        <img src={image} />
        <h2>Turn taking</h2>
        <p>You will take turns speaking at each step of the Flow. Pay attention to whose turn it is here.</p>
        <PrimaryButton
          onClick={() => {
            setGotItClicked(true);
          }}
        >
          Got it
        </PrimaryButton>
      </Modal>
      <ModalOverlay style={{ display: showOnboarding ? "block" : "none" }} />
    </>
  );
};
