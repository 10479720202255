import React, { useEffect } from "react";
import { PageContainer } from "../../../../../src/dashboard/core";
import { mobileAppStore } from "../../../MobileApp/store/store";
import { Empty } from "./Teams";
import { Recommendation } from "./Teams/Recommendation";
import { loadMyTeamList, loadTeamFlows } from "../API/teamApi";
import { styled } from "styled-components";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
`;
export const Home: React.FC = () => {
  const teams = mobileAppStore.use.teams();
  const myTeams = mobileAppStore.use.myTeams();
  const currentUser = mobileAppStore.use.authResponse()?.currentUser;
  const todos: (null | React.JSX.Element)[] = [];
  const teamFlows = mobileAppStore.use.teamsFlows();

  useEffect(() => {
    if (myTeams) {
      myTeams.forEach((teamHashId) => {
        loadTeamFlows(teamHashId);
      });
    }
  }, [myTeams]);

  useEffect(() => {
    loadMyTeamList();
  }, []);

  if (myTeams) {
    myTeams.forEach((teamHashId) => {
      const team = teams[teamHashId];
      const curMissingStageFlows = team.missingStageFlows;

      if ((curMissingStageFlows?.length ?? 0) === 0) return;

      const newRecommendation = teamFlows[teamHashId]?.flowLists?.[0]?.flows?.[0] || null;

      if (!newRecommendation) return;

      todos.push(
        <Recommendation
          key={"todo-" + team.hashId}
          teamHashId={teamHashId}
          newRecommendation={newRecommendation}
          teamName={team.name}
        />,
      );
    });
  }

  return (
    <PageContainer>
      <h1 style={{ margin: "0px", fontFamily: "ITC Clearface" }}>Hi {currentUser?.name} </h1>
      {myTeams && myTeams.length === 0 && <Empty />}
      {todos.length >= 1 && (
        <div>
          <h2>My Todos</h2>
          <StyledGrid>{todos.map((t) => t)}</StyledGrid>
        </div>
      )}
    </PageContainer>
  );
};
