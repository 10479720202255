import React, { useEffect, useState } from "react";
import { PageHeader } from "../PageHeader";
import { useNavigate, useParams } from "react-router";
import { loadMember, loadMemberTeams, updateTeamMember } from "../../API/teamApi";
import toast from "react-simple-toasts";
import { SectionStyling, SectionTitle, SettingsSection, StyledButton, StyledInfo, StyledLabel } from "./Settings";
import { styled } from "styled-components";
import { mobileAppStore } from "../../../../MobileApp/store/store";

const TeamSettingsContainer = styled.div`
  display: flex;
  gap: 18px;
  justify-content: start;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
  }
`;

const RoundCheckBox = styled.input`
  appearance: none;
  min-width: 14px;
  height: 14px;
  border: 2px solid #ff9900;
  cursor: pointer;
  background: transparent;
  border-radius: 22px;
  margin: 3px 0;
  &:checked {
    background: #ff9900;
  }
`;

const MemberTeams = styled.div`
  padding-top: 1.5rem;
  width: 20%;
  font-size: 16px;
  text-decoration: underline;

  color: #d3d3d3;
  span {
    cursor: pointer;
    &.current-team {
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 0;
  }
`;

export const Member: React.FC = () => {
  const navigate = useNavigate();
  const { teamHashId, memberId } = useParams<{ teamHashId: string; memberId: string }>();
  const teams = mobileAppStore.use.teams();
  const team = teamHashId ? teams[teamHashId] : null;

  const [teamMember, setTeamMember] = useState<TeamMember | null>(null);
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState({ active: true, lead: false, observer: false, facilitator: false });
  const [memberTeams, setMemberTeams] = useState<Team[]>([]);

  const handleChange = async (key: keyof typeof formState, value: boolean) => {
    let newFormState = { ...formState, [key]: value };

    if (key === "active" && !value) {
      newFormState = { active: false, lead: false, observer: false, facilitator: false };
    } else if (key === "active" && value) {
      newFormState.active = true;
    }

    setFormState(newFormState);

    const newTeamMember = { ...teamMember, ...newFormState };
    if (teamMember && teamHashId) {
      try {
        await updateTeamMember(teamHashId, newTeamMember as TeamMember);
        toast("Team member updated successfully");
      } catch {
        toast("Failed to update team member");
      }
    }
  };

  const handleRemove = async () => {
    handleChange("active", false);
    await updateUserList();
    toast(`${teamMember?.name} has been removed from ${team?.name}`);
    navigate(`/teams/${teamHashId}`);
  };

  const updateUserList = async () => {
    if (teamHashId && memberId) {
      const members = await loadMemberTeams(teamHashId, memberId);
      if (members) setMemberTeams(members);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (teamHashId && memberId) {
          const member = await loadMember(teamHashId, memberId);
          if (member) {
            setTeamMember(member);
            setFormState({
              active: member.active,
              lead: member.active ? member.lead : false,
              observer: member.active ? member.observer : false,
              facilitator: member.active ? member.facilitator : false,
            });
            const teams = await loadMemberTeams(teamHashId, memberId);
            if (teams) setMemberTeams(teams);
          }
        }
      } catch {
        toast("Failed to load team member");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [teamHashId, memberId]);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <PageHeader searchBarPresent={false} title={teamMember?.name} subtitle={teamMember?.memberSince} buttons={[]} />
      <TeamSettingsContainer>
        <MemberTeams>
          <SettingsSection style={{ gap: "3px" }}>
            {memberTeams.map((team) => (
              <span
                key={team.hashId}
                className={team.hashId === teamHashId ? "current-team" : ""}
                onClick={() => navigate(`/teams/${team.hashId}/${memberId}`)}
              >
                {team.name}
              </span>
            ))}
          </SettingsSection>
        </MemberTeams>
        <div style={{ width: "100%" }}>
          <SectionTitle>{team?.name}</SectionTitle>
          {formState.active && (
            <SettingsSection>
              {Object.keys(formState).map(
                (role) =>
                  role !== "active" && (
                    <div key={role} style={{ display: "flex", gap: "8px" }}>
                      <RoundCheckBox
                        type="checkbox"
                        checked={formState[role as keyof typeof formState]}
                        onChange={() =>
                          handleChange(role as keyof typeof formState, !formState[role as keyof typeof formState])
                        }
                        disabled={role !== "active" && !formState.active}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <StyledLabel>{role.charAt(0).toUpperCase() + role.slice(1)}</StyledLabel>
                        <StyledInfo>
                          {role === "lead" &&
                            "Can manage the team’s settings and membership. Responsible for scheduling the team flows."}
                          {role === "observer" &&
                            "An observer’s warmth does not affect the team’s progression, and does not receive participation reminders."}
                          {role === "facilitator" &&
                            "Allows this person to start / stop flows as well as perform other administrative tasks during a session."}
                        </StyledInfo>
                      </div>
                    </div>
                  ),
              )}
            </SettingsSection>
          )}
          {formState.active ? (
            <SettingsSection className="danger">
              <SectionStyling>
                <div>
                  <p style={{ fontWeight: 600 }}>Remove from Team</p>
                  <p>
                    Remove {teamMember?.name} from &quot;{team?.name}&quot;.
                  </p>
                </div>
                <StyledButton onClick={handleRemove}>
                  Remove
                  <span> from Team</span>
                </StyledButton>
              </SectionStyling>
            </SettingsSection>
          ) : (
            <SettingsSection>
              <SectionStyling>
                <div>
                  <p style={{ fontWeight: 600 }}>Restore Team Member</p>
                  <p>
                    Restore {teamMember?.name} to &quot;{team?.name}&quot;.
                  </p>
                </div>
                <StyledButton onClick={() => handleChange("active", true)}>
                  Restore
                  <span> Team Member</span>
                </StyledButton>
              </SectionStyling>
            </SettingsSection>
          )}
        </div>
      </TeamSettingsContainer>
    </>
  );
};
