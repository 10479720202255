import { NavigateFunction } from "react-router";
import { mobileAppStore, saveToStoreAndDevice, refreshStoreFromApi, useStore, saveToDevice } from "../store/store";
import { postLogin } from "../apiRequests/postLogin";
import { postSignup, SignupRequest } from "../apiRequests/postSignup";
import { postVerify } from "../apiRequests/postVerify";
import { Core } from "../../../dashboard/core";
import { getMicrosoftLoginUrl } from "../../UI/Dashboard/Pages/Login/Login";

export interface AuthState {
  heading: string | null;
  message: string | null;
  buttonLoading: boolean;
}

export const authService = {
  login: async (email: string) => {
    const response = await postLogin(email);
    return response;
  },

  logout: async () => {
    useStore.setState({
      stats: undefined,
      authResponse: undefined,
      flows: undefined,
      flowsWithData: undefined,
    });

    saveToDevice("authResponse", {});
    saveToDevice("stats", {});
    saveToDevice("flows", {});
    saveToDevice("flowsWithData", {});
  },

  signup: async (request: SignupRequest) => {
    const response = await postSignup(request);
    return response;
  },

  verify: async (code: string, authResponse: AuthResponse) => {
    return postVerify(code, authResponse);
  },

  handleAuthResponse: async (
    response: AuthResponse,
    email: string,
    setState: React.Dispatch<React.SetStateAction<AuthState>>,
    navigate: NavigateFunction,
  ) => {
    setState((state) => ({ ...state, buttonLoading: false }));

    if (!response.verificationHash && response.loginMethods && response.loginMethods.includes("microsoft")) {
      window.location.href = getMicrosoftLoginUrl();
      return;
    }

    if (response.heading === "First Register") {
      mobileAppStore.setState({ email });
      navigate("/name");
      return;
    }

    if (response.heading) {
      setState((state) => ({ ...state, heading: response.heading ?? null }));
    }

    if (response.message) {
      setState((state) => ({ ...state, message: response.message ?? null }));
    }

    await saveToStoreAndDevice("authResponse", response);

    if (response.currentUser) {
      await refreshStoreFromApi();
      navigate("/");
    } else if (response.verificationHash) {
      navigate("/verify");
    }
  },

  handleAuthResponseDashboard: async (
    response: AuthResponse,
    email: string,
    setState: React.Dispatch<React.SetStateAction<AuthState>>,
    navigate: NavigateFunction,
  ) => {
    setState((state) => ({ ...state, buttonLoading: false }));

    if (response.heading === "First Register") {
      mobileAppStore.setState({ email });
      navigate("/create-account");
      return;
    }

    if (response.heading) {
      setState((state) => ({ ...state, heading: response.heading ?? null }));
    }

    if (response.message) {
      setState((state) => ({ ...state, message: response.message ?? null }));
    }

    await saveToStoreAndDevice("authResponse", response);

    if (response.currentUser) {
      await Core.initialRequests();
      navigate("/");
    } else if (response.verificationHash) {
      navigate("/verify");
    }
  },
};
