import toast from "react-simple-toasts";
import {
  getAndSaveFromApi,
  getAppApiCall,
  isApiError,
  postAppApiCall,
  putAppApiCall,
} from "../../../MobileApp/apiRequests/ApiUtil";
import {
  initMyTeams,
  initTeamMembers,
  mobileAppStore,
  storeTeamMembers,
  storeMyTeamIds,
  storeTeamList,
  initTeamFlows,
  useStore,
  storeMember,
} from "../../../MobileApp/store/store";

export const loadMyTeamList = async (controller?: AbortController): Promise<void> => {
  // First load any cached teams
  await initMyTeams();

  // Second, check the server for updates
  getAppApiCall("teams", controller ? controller.signal : undefined)
    .then((result) => {
      console.log("loadMyTeamList", result);
      if (isApiError(result)) return;

      const teamsResponse = result as TeamsResponse;

      // Store the individual teams in the state and on the device
      storeTeamList(true, teamsResponse.teams);

      // Update my-teams in the state and on the device
      storeMyTeamIds(teamsResponse.myTeamHashIds);
    })
    .catch((e) => {
      const response = e as Response;
      if (!response.ok && response.status === 401) {
        return; // Ignore since 401s will be handled by logging in
      }
      console.error("loadMyTeamList error:", e);
      toast("Failed to load teams");
    });
};

export const loadTeamFlows = async (teamHashId: string): Promise<void> => {
  // First load any cached team flows
  await initTeamFlows(teamHashId);

  const key = `teams/${teamHashId}/flows`;
  return getAndSaveFromApi(key, key, true).then((result) => {
    if (isApiError(result)) return;

    const newTeamFlows = Object.assign({}, useStore.getState().teamsFlows);
    newTeamFlows[teamHashId] = result;
    useStore.setState({ teamsFlows: newTeamFlows });
  });
};

export const loadUserSuggestions = async (searchString: string): Promise<SuggestedUser[]> => {
  return getAppApiCall(`users/search/${searchString}`);
};

export const createTeam = async (createTeamRequest: CreateTeamRequest): Promise<Team | undefined> => {
  return postAppApiCall("teams", createTeamRequest).then((team: Team) => {
    if (isApiError(team)) return;

    if (team.hashId) {
      const myTeams: string[] = mobileAppStore.getState().myTeams!;

      // Update the list of my teams
      const newMyTeams = [...myTeams];
      newMyTeams.push(team.hashId);
      storeMyTeamIds(newMyTeams);

      // Add this team to the cached teams
      storeTeamList(true, [team]);

      return team;
    }
  });
};

export const updateTeam = async (team: Team, updatedTeamRequest: UpdateTeamRequest): Promise<void> => {
  team.subTeams = undefined;
  return putAppApiCall(`teams/${team.hashId}`, updatedTeamRequest).then((updatedTeam: Team) => {
    storeTeamList(true, [updatedTeam]);
  });
};

export const addTeamMember = async (teamHashId: string, teamMember: TeamMember): Promise<TeamMember> => {
  return postAppApiCall(`team/${teamHashId}/members`, teamMember).then((newTeamMember) => {
    if (isApiError(newTeamMember)) return;

    const teamMembers: TeamMembers = mobileAppStore.getState().teamMembers;
    const members = teamMembers[teamHashId];

    const updatedMembers = [...members];
    updatedMembers.push(newTeamMember);
    storeTeamMembers(teamHashId, updatedMembers);

    return newTeamMember;
  });
};

export const updateTeamMember = async (teamHashId: string, teamMember: TeamMember): Promise<void> => {
  return putAppApiCall(`teams/${teamHashId}/members`, teamMember).then((response) => {
    if (isApiError(response)) return;

    const teamMembers: TeamMembers = mobileAppStore.getState().teamMembers;
    const members = teamMembers[teamHashId];
    const updatedMembers = members.map((m) => (m.id == teamMember.id ? teamMember : m));
    storeTeamMembers(teamHashId, updatedMembers);
  });
};

export const loadTeamMembers = async (teamHashId: string): Promise<TeamMember[] | undefined> => {
  await initTeamMembers(teamHashId);

  return getAppApiCall(`teams/${teamHashId}/members`).then((result) => {
    if (isApiError(result)) return;

    const members = result as TeamMember[];

    // Store the individual teams in the state and on the device
    storeTeamMembers(teamHashId, members);

    return members;
  });
};

export const loadMemberTeams = async (teamHashId: string, memberId: string): Promise<Team[] | undefined> => {
  return getAppApiCall(`/team-member/${memberId}`).then((result) => {
    if (isApiError(result)) return;

    return result as Team[];
  });
};

export const loadMember = async (teamHashId: string, memberId: string): Promise<TeamMember | undefined> => {
  return loadTeamMembers(teamHashId).then((members) => {
    if (!members) return;

    const member = members.find((member) => member.id?.toString() === memberId);
    if (member) {
      storeMember(teamHashId, member);
    }

    return member;
  });
};

export const loadTeam = async (teamHashId: string): Promise<Team | undefined> => {
  const team = mobileAppStore.getState().teams[teamHashId];
  if (team) return team;

  return getAppApiCall(`teams/${teamHashId}`).then((result) => {
    if (isApiError(result)) return;

    const team = result as Team;

    // Store the individual teams in the state and on the device
    storeTeamList(true, [team]);

    return team;
  });
};
