import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { authService } from "../../../../MobileApp/services/authService";
import { PrimaryButton } from "../../../../MobileApp/FlowDetail";
import { AuthState } from "../../../../MobileApp/services/authService";
import { LoginWrapper } from "./Login";

export const FormWrapper = styled.div`
  padding: 0px 20px;
  width: 100%;
`;

export const Form = styled.form``;

export const Message = styled.div`
  border-radius: 8px;
  border: 1px solid light-dark(#00000000, #00000000);
  background-color: light-dark(#ffffff00, #ffffff00);
  padding: 12px 15px;
  box-sizing: border-box;
  color: transparent;
  margin-bottom: 20px;
  display: none;

  &.visible {
    display: block;
    border: 1px solid light-dark(#00000019, #00000019);
    background-color: light-dark(#ffffff19, #ffffff19);
    color: light-dark(#000, #fff);
  }
`;

export const EnterEmail: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [authState, setAuthState] = useState<AuthState>({
    heading: null,
    message: null,
    buttonLoading: false,
  });

  const inputRef = useRef(null);

  async function handleSubmitLogin(e: React.FormEvent) {
    e.preventDefault();
    setAuthState((state) => ({ ...state, buttonLoading: true }));
    const input = inputRef.current! as HTMLInputElement;
    const email = input.value;
    const response = await authService.login(email);
    await authService.handleAuthResponseDashboard(response, email, setAuthState, navigate);
  }

  return (
    <LoginWrapper>
      <h2>
        <FormattedMessage id="signup.enter_email" defaultMessage="Enter your e-mail" />
      </h2>

      <FormWrapper>
        <Message className={authState.message && !authState.buttonLoading ? "visible" : ""}>
          <b>{authState.heading}</b>
          <br />
          <span dangerouslySetInnerHTML={{ __html: authState.message || "" }}></span>
        </Message>
        <Form onSubmit={handleSubmitLogin}>
          <input
            ref={inputRef}
            placeholder={intl.formatMessage({
              id: "signup.email_placeholder",
              defaultMessage: "e.g. youremail@example.com",
            })}
            disabled={authState.buttonLoading}
            autoFocus
          />
        </Form>
      </FormWrapper>

      <PrimaryButton onClick={handleSubmitLogin} disabled={authState.buttonLoading}>
        <FormattedMessage id="signup.login" defaultMessage="Log In" />
      </PrimaryButton>
      <Link to="/">
        <FormattedMessage defaultMessage="Cancel" id="login.cancel" />
      </Link>
    </LoginWrapper>
  );
};
