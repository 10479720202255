import React from "react";
import { useNavigate, useParams } from "react-router";
import { mobileAppStore } from "../store/store";
import { Session } from "./Session";
import { FormattedMessage } from "react-intl";

// SessionLoader is here just to prepare props for the Session component.
// It makes sure we have a flow and params that make sense before rendering the component.
// Renders a 'flow not found' otherwise.
export const SessionLoader: React.FC = () => {
  const params = useParams();
  const flowId = params.flowId;
  const navigate = useNavigate();

  const flowsWithData = mobileAppStore.use.flowsWithData();

  if (!flowId) {
    return (
      <h1>
        <FormattedMessage id="error.missing_flow_id" defaultMessage="Missing flow id" />
      </h1>
    );
  }

  const flow = flowsWithData[flowId];

  if (!flow || !flow.data) {
    return (
      <h1>
        <FormattedMessage id="session_loader.flow_not_found" defaultMessage="Flow not found. " />
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <FormattedMessage id="session_loader.back" defaultMessage="Back" />
        </a>
      </h1>
    );
  }

  return <Session flow={flow} />;
};
