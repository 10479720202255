import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FieldGroup, Form, FormActions, FormLabel, InlineFieldGroup, SecondaryButton } from "./Calendar/Styles";
import { mobileAppStore } from "../../../MobileApp/store/store";
import { useParams, useNavigate } from "react-router";
import { getAndSaveFromApiNow, postAppApiCall } from "../../../MobileApp/apiRequests/ApiUtil";
import { FormattedMessage } from "react-intl";
import Button from "../Button";
import { loadMe } from "../API/initializationApi";
import { TextInput } from "../../TextInput/TextInput";
import { Select } from "../../Select/Select";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 600px;
  margin: auto;

  > ${SecondaryButton} {
    width: 200px;
  }
`;

export const Profile: React.FC = () => {
  const authResponse = mobileAppStore.use.authResponse();
  const currentUser = authResponse?.currentUser;
  const countryCodes = mobileAppStore.use.settings().countryCodes;
  const allLanguages = mobileAppStore.use.settings().languages;
  const allTimeZones = mobileAppStore.use.settings().timeZones;
  const params = useParams();
  const navigate = useNavigate();
  const profileUserId = (params.profileUserId as string) ?? currentUser!.id;

  const refEmail = useRef<HTMLInputElement>(null);
  const refFirstName = useRef<HTMLInputElement>(null);
  const refLastName = useRef<HTMLInputElement>(null);
  const refPreferredName = useRef<HTMLInputElement>(null);
  const refCountryCode = useRef<HTMLSelectElement>(null);
  const refPhone = useRef<HTMLInputElement>(null);
  const refLanguage = useRef<HTMLSelectElement>(null);
  const refTimeZone = useRef<HTMLSelectElement>(null);

  const [profile, setProfile] = useState<ProfileResponse | null>(null);

  const [saveButtonStatus, setSaveButtonStatus] = useState<"updating" | "available" | "saved" | "disabled">("disabled");

  const sortedCountryCodes = Object.values(countryCodes || {}).sort((a, b) => a.phoneCode.localeCompare(b.phoneCode));

  useEffect(() => {
    getAndSaveFromApiNow(`profile/${profileUserId}`, setProfile);
  }, [profileUserId]);

  useEffect(() => {
    if (profile && profile.email) {
      refEmail.current!.value = profile.email;
      refFirstName.current!.value = profile.firstName;
      refLastName.current!.value = profile.lastName;
      refPreferredName.current!.value = profile.nickname;
      refCountryCode.current!.value = profile.countryCode || "";
      refPhone.current!.value = profile.phone || "";
      refLanguage.current!.value = profile.languageCode;
      refTimeZone.current!.value = profile.timeZone;
    }
  }, [profile]);

  function dataChanged() {
    setSaveButtonStatus("available");
  }
  async function updateProfile() {
    const updatedProfile = Object.assign({}, profile);
    updatedProfile.email = refEmail.current!.value;
    updatedProfile.firstName = refFirstName.current!.value;
    updatedProfile.lastName = refLastName.current!.value;
    updatedProfile.nickname = refPreferredName.current!.value;
    updatedProfile.countryCode = refCountryCode.current!.value;
    updatedProfile.phone = refPhone.current!.value.replace(/\D/g, "");
    updatedProfile.languageCode = refLanguage.current!.value;
    updatedProfile.timeZone = refTimeZone.current!.value;
    setSaveButtonStatus("updating");
    await postAppApiCall("profile", updatedProfile).then(() => setSaveButtonStatus("saved"));

    const controller = new AbortController();
    loadMe(controller);
  }

  return (
    <Wrapper>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          updateProfile();
        }}
      >
        <FieldGroup>
          <FormLabel htmlFor="email">Email:</FormLabel>
          <TextInput ref={refEmail} id="email" type="email" placeholder="Email" onChange={dataChanged} />
        </FieldGroup>

        <InlineFieldGroup>
          <div>
            <FormLabel htmlFor="firstName">First Name:</FormLabel>
            <TextInput ref={refFirstName} id="firstName" type="text" placeholder="First Name" onChange={dataChanged} />
          </div>
          <div>
            <FormLabel htmlFor="lastName">Last Name:</FormLabel>
            <TextInput ref={refLastName} id="lastName" type="text" placeholder="Last Name" onChange={dataChanged} />
          </div>
        </InlineFieldGroup>

        <InlineFieldGroup>
          <div>
            <FormLabel htmlFor="preferredName">Preferred Name:</FormLabel>
            <TextInput
              ref={refPreferredName}
              id="preferredName"
              type="text"
              placeholder="Preferred Name"
              onChange={dataChanged}
            />
          </div>
          <div>
            <FormLabel htmlFor="preferredLanguage">Preferred Language:</FormLabel>
            <Select
              ref={refLanguage}
              id="preferredLanguage"
              type="text"
              placeholder="Preferred Language"
              onChange={dataChanged}
            >
              {allLanguages?.map((lang) => (
                <option key={lang.code} value={lang.code}>
                  {lang.name}
                </option>
              ))}
            </Select>
          </div>
        </InlineFieldGroup>

        <InlineFieldGroup>
          <div>
            <FormLabel htmlFor="phone">Phone:</FormLabel>
            <Select ref={refCountryCode} id="countryCode" onChange={dataChanged}>
              {sortedCountryCodes.map((cc) => (
                <option key={cc.code} value={cc.code}>
                  {cc.display}
                </option>
              ))}
            </Select>
          </div>
          <div>
            <FormLabel htmlFor="phone">&nbsp;</FormLabel>
            <TextInput ref={refPhone} id="phone" type="number" placeholder="Phone" onChange={dataChanged} />
          </div>
        </InlineFieldGroup>

        <FieldGroup>
          <FormLabel htmlFor="timeZone">Time Zone:</FormLabel>
          <Select ref={refTimeZone} id="timeZone" type="text" placeholder="Time Zone" onChange={dataChanged}>
            {allTimeZones?.map((tz) => (
              <option key={tz} value={tz}>
                {tz}
              </option>
            ))}
          </Select>
        </FieldGroup>

        <FormActions>
          <SecondaryButton
            onClick={() => {
              navigate("/");
            }}
          >
            Cancel
          </SecondaryButton>
          <Button
            disabled={saveButtonStatus !== "available"}
            // loading={saveButtonStatus === "updating"}
            onClick={updateProfile}
          >
            {saveButtonStatus === "disabled" || saveButtonStatus === "available" ? (
              <FormattedMessage id="profile.save_button_available" defaultMessage="Save" />
            ) : saveButtonStatus === "saved" ? (
              <FormattedMessage id="profile.save_button_available" defaultMessage="Saved" />
            ) : (
              <></>
            )}
          </Button>
        </FormActions>
      </Form>

      <SecondaryButton
        onClick={() => {
          navigate("/logout");
        }}
      >
        Logout
      </SecondaryButton>
      <SecondaryButton
        onClick={() => {
          alert("Not implemented");
        }}
      >
        Delete Account
      </SecondaryButton>
    </Wrapper>
  );
};
