import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { PageHeader } from "../PageHeader";
import { CircleQuestion, FormAdd, Plan } from "grommet-icons";
import { mobileAppStore } from "../../../../MobileApp/store/store";
import { useNavigate, useParams } from "react-router";
import { StyledCell, StyledHeader, StyledTable } from "./List";
import { loadMyTeamList, loadTeamFlows, loadTeamMembers } from "../../API/teamApi";
import { getLevel } from "../../../../../components/UI/Participants/getLevel";
import { Recommendation } from "./Recommendation";

const TeamInfoWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 10px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
  }
`;

const TeamInfo = styled.div`
  background-color: var(--clr-bg-200);
  border-radius: 15px;
  padding: 23px 25px;
  flex: 1 1 70%;
  max-width: 70%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  justify-content: center;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 20px;
  }
`;

const TeamDataTitle = styled.div`
  color: var(--clr-fg-100);
  font-size: 14px;
  max-width: 150px;
`;

const TeamDataValue = styled.div`
  color: var(--clr-fg-0);
  font-size: 30px;
  font-weight: bold;
`;

const TeamData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 5px;

    ${TeamDataTitle} {
      font-size: 12px;
      max-width: 100px;
    }
  }
`;

const TeamProgression = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ProgressionTitle = styled.div`
  color: var(--clr-fg-100);
  font-size: 14px;
  display: flex;
  gap: 5px;
  align-items: center;
`;

const ProgressionBar = styled.div`
  background-color: var(--clr-bg-300);
  border-radius: 10px;
  height: 26px;
  width: 100%;
  border: 1px solid var(--clr-fg-900);
  overflow: hidden;
`;

const ProgressionFill = styled.div<{ width: string }>`
  background-color: #ef6b3b;
  border-radius: 9px;
  height: 100%;
  width: ${({ width }) => width};
`;

const Stages = styled.div`
  display: flex;
  justify-content: space-between;

  small {
    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const StyledRow = styled.tr`
  cursor: pointer;
  &:nth-child(even) {
    background-color: var(--clr-bg-300);
  }

  &:nth-child(odd) {
    background-color: var(--clr-bg-200);
  }

  &:hover {
    background-color: #3f3f3f;
  }
`;

const MembersWrapper = styled.div`
  margin-top: 28px;
  overflow-x: auto;

  h2 {
    font-size: 20px;
  }
`;

const TeamStat = ({ value, label }: { value: string | number; label: string }) => (
  <div style={{ flexGrow: 1 }}>
    <TeamDataValue>{value}</TeamDataValue>
    <TeamDataTitle>{label}</TeamDataTitle>
  </div>
);

export const Team: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  // Get team from store
  const teams = mobileAppStore.use.teams();
  const teamHashId = params.teamHashId as string;
  const team = teamHashId ? teams[teamHashId] : null;

  // Get stage from store
  const stageHashId = team?.stageHashId;
  const settings = mobileAppStore.use.settings();
  const stages = settings.stages;
  const stage = stageHashId ? stages[stageHashId] : null;

  const teamFlows = mobileAppStore.getState().teamsFlows;

  const [membersFilter, setMembersFilter] = useState<string>("all");
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  // Sort stages by minCumulativeHeat
  const sortedStages = [...Object.values(stages)].sort((a, b) => a.minCumulativeHeat - b.minCumulativeHeat);

  // Find the current stage
  const currentStageIndex = sortedStages.findIndex((stage) => stage.stageHashId === team?.stageHashId);
  if (currentStageIndex === -1) {
    throw new Error("Invalid stageHashId for team");
  }

  const currentStage = sortedStages[currentStageIndex];
  const nextStage = sortedStages[currentStageIndex + 1] || null;

  const getFlowProgression = (team: Team) => {
    const currentCumulativeHeat = team.avgCumulativeHeat;

    let stageProgression = 0; // Default to 0%
    if (nextStage) {
      const range = nextStage.minCumulativeHeat - currentStage.minCumulativeHeat;
      stageProgression = Math.max(0, (currentCumulativeHeat - currentStage.minCumulativeHeat) / range);
    } else {
      // If there is no next stage, then is the final stage
      stageProgression = 1;
    }

    return Math.min(stageProgression, 1) * 100;
  };

  const newRecommendation = teamFlows[teamHashId]?.flowLists?.[0]?.flows?.[0] || null;

  useEffect(() => {
    loadMyTeamList();
  }, []);

  useEffect(() => {
    if (teamHashId) {
      loadTeamFlows(teamHashId);
    }
  }, [teamHashId]);

  useEffect(() => {
    if (teamMembers.length === 0 && team) {
      loadTeamMembers(team.hashId).then((members) => {
        if (members) setTeamMembers(members);
      });
    }
  }, [teamMembers, team]);

  const avgRecentHeat = team?.avgRecentHeat || 0;

  const avgCumulativeHeat = team?.avgCumulativeHeat || 0;

  const engagement = team?.engagement ? `${Math.round(team.engagement * 100)}%` : "0%";

  const flowProgression = team && stage ? getFlowProgression(team) : 0;

  const formerTeamMembers = teamMembers.filter((member) => member.active === false);

  let filteredMembers = useMemo(() => {
    return membersFilter === "all" ? teamMembers.filter((member) => member.active) : formerTeamMembers;
  }, [membersFilter, teamMembers]);

  filteredMembers = filteredMembers.sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));

  const level = getLevel(avgCumulativeHeat);

  return (
    <>
      <PageHeader
        searchBarPresent={false}
        title={team?.name}
        subtitle={team?.created}
        buttons={[
          {
            label: "Overview",
            isActive: true,
            onClick: () => {
              navigate(`/teams/${teamHashId}`, { replace: true });
            },
          },
          {
            label: "Settings",
            isActive: false,
            onClick: () => {
              navigate(`/teams/${teamHashId}/settings`, { replace: true });
            },
          },
        ]}
        extraButton={{
          show: true,
          icon: <Plan color="#fff" />,
          label: "Schedule a flow",
          onClick: () => {
            navigate(`/teams/${teamHashId}/schedule`);
          },
        }}
      />

      <>
        <TeamInfoWrapper>
          <TeamInfo>
            <TeamData>
              <TeamStat value={avgRecentHeat} label="Average Recent Warmth" />
              <TeamStat value={avgCumulativeHeat} label="Average Lifetime Warmth" />
              <TeamStat value={engagement} label="Engagement Rate" />
            </TeamData>
            <TeamProgression>
              <ProgressionTitle>
                Flow Progression
                <CircleQuestion color="light-dark(#000, #fff)" size="small" />
              </ProgressionTitle>
              <ProgressionBar>
                <ProgressionFill width={`${flowProgression}%`} />
              </ProgressionBar>
              <Stages>
                <small>{currentStage.name}</small>
                {nextStage && (
                  <small>
                    {nextStage.name} (
                    <a
                      onClick={() => {
                        alert("Feature not implemented yet");
                      }}
                    >
                      activate early
                    </a>
                    )
                  </small>
                )}
              </Stages>
            </TeamProgression>
          </TeamInfo>
          {newRecommendation && <Recommendation newRecommendation={newRecommendation} teamHashId={teamHashId} />}
        </TeamInfoWrapper>
        <MembersWrapper>
          <PageHeader
            searchBarPresent={true}
            searchBarPlaceholder="Search by name"
            title="Members"
            buttons={[
              {
                count: teamMembers.length || "0",
                label: "Members",
                isActive: membersFilter === "all",
                onClick: () => setMembersFilter("all"),
              },
              {
                count: formerTeamMembers.length || "0",
                label: (
                  <>
                    Former <span>Members</span>
                  </>
                ),
                isActive: membersFilter === "formerMembers",
                onClick: () => setMembersFilter("formerMembers"),
              },
            ]}
            extraButton={{
              show: true,
              icon: <FormAdd color="#F48900" />,
              label: "Add Member",
              onClick: () => {
                navigate(`/teams/${teamHashId}/edit`);
              },
              type: "secondary",
            }}
          />

          <StyledTable>
            <StyledHeader>
              <tr>
                <StyledCell>Name</StyledCell>
                <StyledCell>Recent Warmth</StyledCell>
                <StyledCell>Level</StyledCell>
              </tr>
            </StyledHeader>
            <tbody>
              {filteredMembers.map((member) => (
                <StyledRow
                  className="members"
                  key={member.id}
                  onClick={() => navigate(`/teams/${teamHashId}/${member.id}`)}
                >
                  <StyledCell>{member.name}</StyledCell>
                  <StyledCell>{member.recentHeat}</StyledCell>
                  <StyledCell>{`Lvl ${level.level}`}</StyledCell>
                </StyledRow>
              ))}
            </tbody>
          </StyledTable>
        </MembersWrapper>
      </>
    </>
  );
};
