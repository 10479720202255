import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  padding-bottom: 80px;

  #body {
    max-width: 800px;
    border-radius: 8px;
    padding: 20px;
  }

  ul {
    border-radius: 8px;
    line-height: 1.8;
    padding: 20px 25px;
    list-style-type: none;
    height: auto;
    margin: 0px;

    margin-right: 10px;
    position: sticky;
    top: 80px;

    border: 1px solid light-dark(#ccc, #444);
    width: 300px;

    a {
      color: light-dark(#222, #fff);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    ul {
      border: 0px;
      padding: 0px;
      padding-left: 20px;
    }
  }

  h1 {
    font-size: 20px;
    margin-bottom: 10px;
    padding-top: 75px;
    margin-top: -75px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    margin: 0px;
    margin-bottom: 20px;
  }
`;

export const Support: React.FC = () => {
  return (
    <Wrapper>
      <div id="sidebar">
        <ul>
          <li>
            <a href="#about-warmspace">About Warmspace</a>
          </li>
          <li>
            <a href="#system-requirements">System Requirements</a>
          </li>
          <li>
            <a href="#flow-descriptions">Flow Descriptions</a>
            <ul>
              <li>
                <a href="#ignite-suite">The Ignite Suite</a>
              </li>
              <li>
                <a href="#other-flows">Other Flows</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#before-session">Before a session</a>
            <ul>
              <li>
                <a href="#editing-profile">Editing your profile</a>
              </li>
              <li>
                <a href="#managing-teams">Managing teams</a>
              </li>
              <li>
                <a href="#scheduling-session">Scheduling a session</a>
              </li>
              <li>
                <a href="#calendar-integration">Calendar Integration</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#during-session">During a session</a>
            <ul>
              <li>
                <a href="#joining-session">Joining a session</a>
              </li>
              <li>
                <a href="#participating-flow">Participating in a Flow</a>
              </li>
              <li>
                <a href="#in-person-mode">About in person mode</a>
              </li>
              <li>
                <a href="#camera-mic-troubleshooting">Camera & Microphone Troubleshooting</a>
              </li>
              <li>
                <a href="#facilitator-guide">Facilitator Guide</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#after-session">After a session</a>
            <ul>
              <li>
                <a href="#viewing-analytics">Viewing analytics</a>
              </li>
              <li>
                <a href="#viewing-recordings">Viewing recordings and transcripts</a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#support-contact">How to get support / contact us</a>
          </li>
          <li>
            <a href="#help-improve">Help us improve Warmspace</a>
          </li>
          <li>
            <a href="#faq">FAQ</a>
          </li>
        </ul>
      </div>

      <div id="body">
        <h1 id="about-warmspace">About Warmspace</h1>
        <p>
          Warmspace is a platform that facilitates meaningful conversations through guided video sessions. Whether
          you&apos;re connecting with a team or strangers, our Flows help spark real connection.
        </p>

        <h1 id="system-requirements">System Requirements</h1>
        <p>
          To ensure a smooth experience, use a modern browser (like Chrome or Firefox) on a desktop or mobile device
          with a stable internet connection, working camera, and microphone.
        </p>

        <h1 id="flow-descriptions">Flow Descriptions</h1>
        <p>
          Flows are structured conversation formats that guide participants through moments of reflection, storytelling,
          and connection.
        </p>

        <h1 id="ignite-suite">The Ignite Suite</h1>
        <p>
          The Ignite Suite includes our most popular Flows, designed to build trust and openness quickly. Perfect for
          team warm-ups or onboarding new members.
        </p>

        <h1 id="other-flows">Other Flows</h1>
        <p>
          Explore our wide range of Flows tailored for everything from deep dives to playful get-to-know-you moments.
        </p>

        <h1 id="before-session">Before a session</h1>
        <p>Get ready for your session by setting up your profile, managing teams, and syncing your schedule.</p>

        <h1 id="editing-profile">Editing your profile</h1>
        <p>Update your display name, photo, and bio so others recognize you during sessions.</p>

        <h1 id="managing-teams">Managing teams</h1>
        <p>Create or join teams to organize regular sessions and keep everyone connected.</p>

        <h1 id="scheduling-session">Scheduling a session</h1>
        <p>Use our built-in scheduling tools to book a session and invite participants in advance.</p>

        <h1 id="calendar-integration">Calendar Integration</h1>
        <p>Connect Warmspace to your calendar to receive event invites, reminders, and join links automatically.</p>

        <h1 id="during-session">During a session</h1>
        <p>Here’s what to expect while you’re live in a session—from joining to participating in the Flow.</p>

        <h1 id="joining-session">Joining a session</h1>
        <p>
          Sessions can be joined via a secure link. Make sure your camera and microphone are ready before you enter.
        </p>

        <h1 id="participating-flow">Participating in a Flow</h1>
        <p>
          Flows will guide the conversation with prompts and time-based transitions. Just follow along and be yourself.
        </p>

        <h1 id="in-person-mode">About in person mode</h1>
        <p>Use Warmspace’s in-person mode to facilitate Flows with groups in the same room, no video needed.</p>

        <h1 id="camera-mic-troubleshooting">Camera & Microphone Troubleshooting</h1>
        <p>
          If your camera or mic isn’t working, check your browser permissions or device settings. We’ve got tips to help
          you fix common issues quickly.
        </p>

        <h1 id="facilitator-guide">Facilitator Guide</h1>
        <p>
          If you’re leading a session, this guide will help you prepare, support your group, and handle any bumps along
          the way.
        </p>

        <h1 id="after-session">After a session</h1>
        <p>
          Once your session ends, you can explore analytics and revisit recordings to reflect and improve future
          sessions.
        </p>

        <h1 id="viewing-analytics">Viewing analytics</h1>
        <p>See how participants engaged, which prompts resonated most, and overall session stats.</p>

        <h1 id="viewing-recordings">Viewing recordings and transcripts</h1>
        <p>
          Access session recordings and AI-generated transcripts to revisit meaningful moments or share with absent team
          members.
        </p>

        <h1 id="support-contact">How to get support / contact us</h1>
        <p>Need help? Reach out to our team or browse our help resources to troubleshoot or ask questions.</p>

        <h1 id="help-improve">Help us improve Warmspace</h1>
        <p>
          Your feedback shapes the future of Warmspace. Share your ideas, bug reports, or experiences with us anytime.
        </p>

        <h1 id="faq">FAQ</h1>
        <p>Quick answers to common questions about sessions, accounts, billing, and more.</p>
      </div>
    </Wrapper>
  );
};
