import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Branding } from "../../Branding/Branding";
import { BarChart, CircleQuestion, Close, Group, HomeRounded, Menu, Plan, ShareRounded } from "grommet-icons";
import { NavLink, useNavigate } from "react-router";
import { mobileAppStore, useStore } from "../../../MobileApp/store/store";
import Button from "../Button";
import { CheckMarkIcon, ChevronDown, ClipboardIcon, WarmspaceIcon } from "../../../../assets/icons/Icons";
import { Overlay } from "../../Modal/ModalStyle";
import toast from "react-simple-toasts";
import { hasRole } from "../../../../helpers/can";
import { postAppApiCall } from "../../../MobileApp/apiRequests/ApiUtil";
import { hideLoading, showLoading } from "../../../../lib/hideLoading";

const HeaderContainer = styled.header`
  height: 75px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  padding-right: 15px;
  justify-content: space-between;
  background-color: var(--clr-bg-app-start);

  #mobileMenuToggle {
    display: none;
    border: 0px;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: transparent;

    svg {
      stroke: light-dark(#222, #bbb);
    }
  }

  @media (max-width: 600px) {
    #mobileMenuToggle {
      display: block;
      z-index: 50;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0px;
    top: 75px;
    height: calc(100vh - 100px);
    width: 150px;
    gap: 10px;
    padding: 10px 10px;

    @media (max-width: 600px) {
      background-color: var(--clr-bg-100);
      display: none;
      position: fixed;
      top: 75px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      &.open {
        display: flex;
      }
    }

    hr {
      width: 100%;
      border: 0;
      border-top: 1px solid #333;
    }

    div {
      flex-direction: column;
      display: flex;
      gap: 10px;
      width: 100%;
    }

    a {
      display: flex;
      flex-direction: row;

      align-items: center;
      color: light-dark(#222, #bbb);
      text-decoration: none;

      svg {
        stroke: light-dark(#222, #bbb);
      }

      gap: 10px;
      padding: 10px 10px;

      border-radius: 8px;
      box-sizing: border-box;

      width: 100%;

      &.active {
        background-color: light-dark(#ccc, #333) !important;
        box-sizing: border-box;
        color: light-dark(#222, #fff) !important;

        svg {
          stroke: light-dark(#222, #fff);
        }
      }

      &:hover {
        color: light-dark(#000, #fff);

        svg {
          stroke: light-dark(#000, #fff);
        }
      }
    }
  }

  svg {
    path.letter {
      fill: light-dark(#222, #fff) !important;
    }
  }

  position: fixed;
  left: 0px;
  right: 0px;
  z-index: 10;

  @media (max-width: 600px) {
    #support {
      display: none;
    }
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .buttonGroup {
    margin-right: 10px;
    display: flex;
    gap: 0px;

    @media (max-width: 600px) {
      display: none;
    }

    ${Button} {
      border-radius: 0;
      border-right: 0;
      z-index: 0;
      position: relative;

      &:hover {
        scale: 1.03;
      }

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        border-right: 1px solid #f48900;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    color: light-dark(#333, #ccc);
    text-decoration: none;
    gap: 7px;

    padding: 10px;
    padding-right: 12px;
    border-radius: 8px;

    svg {
      stroke: light-dark(#222, #ccc);
    }

    &.active {
      box-sizing: border-box;
      text-decoration: underline;
      color: light-dark(#222, #fff);

      svg {
        stroke: light-dark(#222, #fff);
      }
    }

    &:hover {
      color: light-dark(#000, #fff);
      svg {
        stroke: light-dark(#000, #fff);
      }
    }
  }
`;

const ProfilePopover = styled.div`
  position: fixed;
  top: 65px;
  right: 15px;
  background-color: light-dark(#fff, #222);
  border-radius: 8px;
  border: 1px solid light-dark(#e5e5e5, #696969);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 1000;
  font-size: 16px;
  min-width: 300px;

  hr {
    width: 100%;
    margin 0px;
    padding: 0px;
    border: 0px;
    border-top: 1px solid light-dark(#e5e5e5, #888);
  }

  a {
    padding: 0px;
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 13px;
  }

  ${Button} {
    padding: 0px;
    margin: 0px;
    height: auto;
    color: light-dark(#333, #ccc);
    &:hover {
      scale: 1;
      color: light-dark(#333, #fff);
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
`;

const Name = styled.div`
  font-size: 16px;
`;

const Role = styled.div`
  font-size: 12px;
  color: light-dark(#333, #ccc);
`;

const MenuButton = styled.button`
  display: flex;
  align-items: center;

  padding: 0px;
  background-color: transparent;
  gap: 5px;
  border: 0px;
  font-size: 16px;
  color: light-dark(#333, #ccc);

  &.selected {
    font-weight: bold;
  }

  &:hover {
    color: light-dark(#333, #fff);
  }
`;

const OrgBadge = styled.div`
  background-color: #444;
  color: #fff;
  padding: 3px 8px;
  border-radius: 5px;
  font-size: 15px;
  margin-left: 4px;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

function colorFromName(name: string) {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();
  return "#" + "00000".substring(0, 6 - c.length) + c;
}

// Check color contrast
// https://webaim.org/resources/contrastchecker/
function colorContrast(color1: string, color2: string) {
  const hexToRgb = (hex: string) =>
    hex
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
      .substring(1)
      .match(/.{2}/g)!
      .map((x) => parseInt(x, 16));

  const [r1, g1, b1] = hexToRgb(color1);
  const [r2, g2, b2] = hexToRgb(color2);

  const brightness1 = (r1 * 299 + g1 * 587 + b1 * 114) / 1000;
  const brightness2 = (r2 * 299 + g2 * 587 + b2 * 114) / 1000;

  return Math.abs(brightness1 - brightness2);
}

function isPastDate(date?: Date) {
  if (!date) return true;
  return date < new Date();
}

export const Header: React.FC = () => {
  const authResponse = mobileAppStore.use.authResponse();
  const currentUser = authResponse?.currentUser;
  const navOpen = mobileAppStore.use.navOpen();
  const online = mobileAppStore.use.online();
  const navSubMenu = mobileAppStore.use.navSubMenu();
  const navigate = useNavigate();
  const selectedOrganization = authResponse?.organizations?.find(
    (org) => org.hashId === authResponse?.organizationHashId,
  );

  const [switchingColors, setSwitchingColors] = React.useState(false);

  const colorScheme = document.getElementById("color-scheme") as HTMLSelectElement;

  return (
    <HeaderContainer>
      <title>{selectedOrganization ? selectedOrganization.name + "- " : ""}Warmspace</title>
      <nav className={navOpen ? "open" : ""}>
        <div>
          <NavLink to="/" onClick={() => useStore.setState({ navOpen: false })}>
            <HomeRounded />
            Home
          </NavLink>
          <NavLink to="/calendar" onClick={() => useStore.setState({ navOpen: false })}>
            <Plan />
            Calendar
          </NavLink>
          <NavLink to="/teams" onClick={() => useStore.setState({ navOpen: false })}>
            <Group />
            Teams
          </NavLink>
          {currentUser && hasRole(currentUser, "admin") && (
            <NavLink to="/flows" onClick={() => useStore.setState({ navOpen: false })}>
              <WarmspaceIcon fill={"var(--clr-fg-0)"} />
              Flows
            </NavLink>
          )}
          {currentUser && hasRole(currentUser, "admin") && (
            <NavLink to="/analytics" onClick={() => useStore.setState({ navOpen: false })}>
              <BarChart />
              Analytics
            </NavLink>
          )}
          {/* <NavLink to="/integrations">
                        <Connect />
                        <div>Integrations</div>
                      </NavLink> */}
        </div>
      </nav>
      <NavLink to="/" onClick={() => useStore.setState({ navOpen: false })}>
        <Branding fill={"#fff"} style={{ width: "180px", marginBottom: "0px" }} />
      </NavLink>
      {online ? "" : "You are currently offline"}
      <div>
        {authResponse?.lobbySpaceHashId && (
          <div className="buttonGroup">
            <Button onClick={() => window.open(`/s/${authResponse?.lobbySpaceHashId}`)} className="secondary">
              <ShareRounded color="#F48900" /> {currentUser?.name}&apos;s Warmspace
            </Button>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.protocol}//${window.location.host}/s/${authResponse?.lobbySpaceHashId}`,
                );
                toast("Link copied to clipboard");
              }}
              className="secondary"
            >
              <ClipboardIcon color="#F48900" />
            </Button>
          </div>
        )}

        <NavLink to="/support" id="support" onClick={() => useStore.setState({ navOpen: false })}>
          <CircleQuestion color={"#fff"} /> Help
        </NavLink>
        {selectedOrganization && (
          <Button className="tertiary" onClick={() => useStore.setState({ navSubMenu: "index", navOpen: false })}>
            <OrgBadge
              style={{
                backgroundColor: colorFromName(selectedOrganization.name),
                color: colorContrast(colorFromName(selectedOrganization.name), "#fff") > 125 ? "#fff" : "#000",
              }}
            >
              {selectedOrganization.name}
            </OrgBadge>
            <span>{currentUser?.name}</span>

            <div
              style={{
                transform: navSubMenu ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.17  s",
              }}
            >
              <ChevronDown fill="var(--clr-fg-0)" />
            </div>
          </Button>
        )}
        {navSubMenu == "index" && (
          <ProfilePopover>
            <Name>{currentUser?.fullName}</Name>
            <Role>{currentUser?.email}</Role>

            <Role>{isPastDate(currentUser?.microsoftTokenExpiresAt) ? "Expired token" : "Valid token"}</Role>

            <hr />
            <div className="actions">
              <NavLink to="/profile" onClick={() => useStore.setState({ navSubMenu: "" })}>
                My Profile
              </NavLink>
              {authResponse && authResponse.organizations.length >= 2 && (
                <Button className="tertiary" onClick={() => useStore.setState({ navSubMenu: "organizations" })}>
                  Switch Organizations
                  <div
                    style={{
                      transform: "rotate(-90deg)",
                      transition: "all 0.17  s",
                    }}
                  >
                    <ChevronDown />
                  </div>
                </Button>
              )}

              <Button className="tertiary" onClick={() => useStore.setState({ navSubMenu: "appearance" })}>
                Appearance ({colorScheme.value === "system" ? "Use device theme" : colorScheme.value})
                <div
                  style={{
                    transform: "rotate(-90deg)",
                    transition: "all 0.17  s",
                  }}
                >
                  <ChevronDown />
                </div>
              </Button>
              <NavLink to="/logout" onClick={() => useStore.setState({ navSubMenu: "" })}>
                Logout
              </NavLink>
            </div>
          </ProfilePopover>
        )}
        {navSubMenu == "organizations" && (
          <ProfilePopover>
            <MenuButton onClick={() => useStore.setState({ navSubMenu: "index" })}>
              <div style={{ transform: "rotate(90deg)" }}>
                <ChevronDown />
              </div>
              Back
            </MenuButton>
            <hr />
            <div className="actions">
              {authResponse!.organizations.sort().map((org) => (
                <MenuButton
                  key={org.hashId}
                  className={selectedOrganization === org ? "selected" : ""}
                  onClick={() => {
                    showLoading();
                    postAppApiCall(`auth/switch-orgs/${org.hashId}`)
                      .then(() => {
                        window.location.reload();
                      })
                      .catch(() => {
                        hideLoading();
                      });
                  }}
                >
                  {org.name}
                  {selectedOrganization === org ? <CheckMarkIcon stroke="#ffF" /> : null}
                </MenuButton>
              ))}
            </div>
          </ProfilePopover>
        )}
        {navSubMenu == "appearance" && (
          <ProfilePopover>
            <MenuButton onClick={() => useStore.setState({ navSubMenu: "index" })}>
              <div style={{ transform: "rotate(90deg)" }}>
                <ChevronDown />
              </div>
              Back
            </MenuButton>
            <hr />
            <div className="actions">
              {[
                { label: "Use device theme", value: "system" },
                { label: "Dark theme", value: "dark" },
                { label: "Light theme", value: "light" },
              ].map((menuItem) => (
                <MenuButton
                  key={menuItem.value}
                  className={colorScheme.value === menuItem.value ? "selected" : ""}
                  onClick={() => {
                    useStore.setState({ navSubMenu: "" });
                    colorScheme.value = menuItem.value;
                    colorScheme.dispatchEvent(new Event("input"));

                    setSwitchingColors(true);
                    setTimeout(() => {
                      setSwitchingColors(false);
                    }, 200);
                  }}
                >
                  {menuItem.label}
                  {colorScheme.value === menuItem.value ? <CheckMarkIcon stroke="#ffF" /> : null}
                </MenuButton>
              ))}
            </div>
          </ProfilePopover>
        )}

        {navSubMenu || switchingColors ? (
          <Overlay $blur={false} onClick={() => useStore.setState({ navSubMenu: "" })} />
        ) : null}

        <button id="mobileMenuToggle" onClick={() => useStore.setState({ navOpen: !navOpen })}>
          {navOpen ? <Close /> : <Menu />}
        </button>
      </div>
    </HeaderContainer>
  );
};
