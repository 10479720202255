import { getFlowsWithData } from "../../../MobileApp/apiRequests/getFlows";
import { getAndSaveFromApi } from "../../../MobileApp/apiRequests/ApiUtil";
import { initAuth, initSettings, mobileAppStore, useStore } from "../../../MobileApp/store/store";

export const loadSettings = async (controller: AbortController) => {
  await initSettings();
  const key = "settings";

  const promise = getAndSaveFromApi(key, key, false, controller.signal);

  // If the globals weren't cached, then wait for them to load before proceeding
  if (!useStore.getState().settings.stages) {
    const settings = await promise;
    mobileAppStore.setState({ settings: settings });
  }
};

export const loadMe = async (controller: AbortController) => {
  await initAuth();

  const promise = getAndSaveFromApi("auth/me", "authResponse", false, controller.signal).then(
    async (authResponse: AuthResponse) => {
      console.log("loadMe", authResponse);
      // Decorate user response with microsoftTokenExpiresAt 30 days in the future
      // todo: getting this actually and accurately from the server would be cool
      if (authResponse.currentUser) {
        const microsoftTokenExpiresAt = new Date();
        microsoftTokenExpiresAt.setDate(microsoftTokenExpiresAt.getDate() + 30);
        const newCurrentUser = { ...authResponse.currentUser };
        newCurrentUser.microsoftTokenExpiresAt = microsoftTokenExpiresAt;
        useStore.setState({ authResponse: { ...authResponse, currentUser: newCurrentUser } });
      }
    },
  );

  // If the auth response wasn't already cached, then wait for it to load before proceeding
  if (!useStore.getState().authResponse?.currentUser) {
    await promise;
  }
};

export const loadFlows = async (controller: AbortController) => {
  const promise = getAndSaveFromApi("flows", "flows", false, controller.signal);

  // If the flows weren't cached, then wait for them to load before proceeding
  if (!useStore.getState().flows) {
    await promise;
  }

  promise.then((flows) => {
    getFlowsWithData(flows);
  });
};
