import styled from "styled-components";

const Button = styled.button`
  background: linear-gradient(90deg, #ff622e 27.21%, #ff8d24 122.48%);
  border-radius: 8px;
  border: 0px;
  font-weight: bold;
  font-size: 17px;
  padding: 0px 8px;
  align-items: center;
  display: flex;
  gap: 5px;
  transition: 0.1s;
  height: 36px;
  border: transparent;
  border-top: 1px solid #ffad44;
  box-sizing: border-box;
  color: #fff;

  &:hover {
    background: linear-gradient(90deg, #ff8d24 27.21%, #ff622e 122.48%);
    scale: 1.05;
  }

  &:disabled {
    background: #999;
    color: #fff;
    border: 0px;
  }

  @media (max-width: 768px) {
    span {
      display: none;
    }
  }

  &.secondary {
    background: transparent;
    border: 1px solid #f48900;
    color: #f48900;

    &:hover {
      scale: 1.05;
    }
  }

  &.tertiary {
    background: transparent;
    color: light-dark(#222, #ddd);
    font-weight: normal;
    border: 0px;
    &:hover {
      scale: 1;
      color: light-dark(#000, #fff);
    }
  }
`;

export default Button;
