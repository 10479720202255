import React, { useEffect, useRef, useState } from "react";
import { StyledBody } from "./Empty";
import { CircleQuestion, FormClose } from "grommet-icons";
import { CheckBox } from "../../../Modal/ModalStyle";
import { useNavigate } from "react-router";
import Button from "../../Button";
import { mobileAppStore } from "../../../../MobileApp/store/store";
import { createTeam, loadUserSuggestions } from "../../API/teamApi";
import {
  StyledForm,
  StyledLabelHeader,
  StyledInput,
  StyledInputWrapper,
  StyledMessage,
  StyledDiv,
  StyledLink,
  StyledList,
  CheckBoxLabel,
} from "./Edit";

export const CreateTeam = () => {
  const currentUser = mobileAppStore.use.authResponse()!.currentUser as UserResponse;
  const [curTeamMembers, setCurTeamMembers] = useState<TeamMember[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [suggestedUsers, setSuggestedUsers] = useState<SuggestedUser[]>([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState<boolean>(false);

  const teamNameRef = useRef<HTMLInputElement>(null);
  const nameOrEmailRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = React.useState("");
  const [teamLead, setTeamLead] = React.useState<TeamMember[]>(curTeamMembers.filter((m) => m.lead));
  const [selectedUsers, setSelectedUsers] = React.useState<string[]>([]);
  const [showDropdown, setShowDropDown] = React.useState(false);

  const handleSelect = (user: any) => {
    if (user === "Add User") {
      setSelectedUsers([...selectedUsers, searchTerm]);
      setSearchTerm("");
      addMember(undefined, searchTerm);
    } else {
      const userLabel = `${user.name} (${user.email})`;
      setSelectedUsers([...selectedUsers, userLabel]);
      const suggestedUser = suggestedUsers.find((u) => u === user);
      addMember(suggestedUser);
    }
    setShowDropDown(false);
    nameOrEmailRef.current!.value = "";
  };

  useEffect(() => {
    if (teamNameRef?.current) {
      teamNameRef.current.value = currentUser.name + "'s Team";
    }

    const newTeamMembersToAdd = [...curTeamMembers];
    newTeamMembersToAdd.push({
      id: currentUser.id,
      name: currentUser.fullName,
      email: currentUser.email,
      lead: true,
      facilitator: true,
      observer: false,
      active: true,
    });
    setCurTeamMembers(newTeamMembersToAdd);
  }, []);

  function saveTeam() {
    const teamLeadIds = teamLead.map((u) => u.id);
    const newTeamMembers = curTeamMembers.map((m) => {
      return { ...m, lead: teamLeadIds.includes(m.id) };
    });

    createTeam({ name: teamNameRef!.current!.value, members: newTeamMembers as CreateTeamMemberRequest[] }).then(
      (newTeam: Team | undefined) => {
        if (newTeam?.hashId) navigate("/teams/" + newTeam.hashId);
      },
    );
  }

  function addMember(suggestedUser?: SuggestedUser, searchString?: string) {
    // TODO: validate that the searchString is an actual email address.
    //  If it includes a name in the format: name<some@email.com>, then pass the name along in the request
    const email = suggestedUser ? null : searchString;

    const memberToAdd = {
      id: suggestedUser?.id,
      name: suggestedUser?.name,
      email: suggestedUser?.email || email!,
      lead: curTeamMembers.length == 0,
      facilitator: false,
      observer: false,
      active: true,
    };

    const newTeamMembersToAdd = [...curTeamMembers];
    newTeamMembersToAdd.push(memberToAdd);
    setCurTeamMembers(newTeamMembersToAdd);
  }

  const handleChangeTeamLead = (user: TeamMember) => {
    if (teamLead.includes(user)) {
      setTeamLead(teamLead.filter((u) => u !== user));
    } else {
      setTeamLead([...teamLead, user]);
    }
  };

  function searchUsers() {
    if (loadingSuggestions) return;
    setLoadingSuggestions(true);
    setTimeout(() => {
      setLoadingSuggestions(false);
      const searchString = nameOrEmailRef?.current?.value;
      setSearchTerm(searchString || "");

      if (searchString && searchString.length >= 3) {
        loadUserSuggestions(searchString).then((suggestedUsers) => {
          const filteredSuggestions =
            suggestedUsers.length > 0 ? suggestedUsers.filter((u) => !curTeamMembers.find((t) => t.id == u.id)) : [];
          setSuggestedUsers(filteredSuggestions);
        });
      } else {
        setSuggestedUsers([]);
      }
    }, 200);
  }

  const handleKeyDown = (e: { key: any; preventDefault: () => void }) => {
    if (!showDropdown || suggestedUsers.length === 0) return;

    switch (e.key) {
      case "ArrowDown":
        setSelectedIndex((prev) => Math.min(prev + 1, suggestedUsers.length - 1));
        e.preventDefault();
        break;
      case "ArrowUp":
        setSelectedIndex((prev) => Math.max(prev - 1, 0));
        e.preventDefault();
        break;
      case "Enter":
        if (selectedIndex >= 0) {
          handleSelect(suggestedUsers[selectedIndex]);
        }
        break;
      case "Escape":
        setShowDropDown(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      setShowDropDown(true);
    }
  }, [searchTerm]);

  return (
    <StyledBody>
      <StyledLabelHeader>
        <h1>{"Create a new team"}</h1>
        <CircleQuestion color="#fff" />
      </StyledLabelHeader>
      <StyledMessage>
        <StyledForm>
          <div>
            <label>Team name:</label>
            <StyledInputWrapper>
              <StyledInput ref={teamNameRef} type="text" />
            </StyledInputWrapper>
          </div>

          <div>
            <label>Members:</label>
            <div>
              <StyledDiv>
                <StyledInputWrapper>
                  <StyledInput type="search" value={currentUser.fullName} style={{ width: "100%" }} readOnly />
                </StyledInputWrapper>
                <CheckBoxLabel>
                  <CheckBox type="checkbox" checked={true} readOnly />
                  Lead
                </CheckBoxLabel>
                <FormClose color="#fff" />
              </StyledDiv>
              {selectedUsers.map((user, index) => (
                <StyledDiv key={index}>
                  <StyledInputWrapper>
                    {/* TODO: if the user doesn't exist yet, then show the mail icon */}
                    {/* {suggestedUsers.find((u) => u.name === user)?.email ? "" : <MailOption />} */}
                    <StyledInput type="search" value={user} style={{ width: "100%" }} readOnly />
                  </StyledInputWrapper>
                  <CheckBoxLabel>
                    <CheckBox
                      type="checkbox"
                      onChange={() => {
                        const member = curTeamMembers[index + 1];
                        if (!member) return;
                        handleChangeTeamLead(member);
                      }}
                      checked={teamLead.find((u) => u.id === curTeamMembers[index + 1].id) ? true : false}
                    />
                    Lead
                  </CheckBoxLabel>
                  <FormClose
                    color="#fff"
                    onClick={() => {
                      setSelectedUsers(selectedUsers.filter((u) => u !== user));
                      setCurTeamMembers(curTeamMembers.filter((m) => m.name !== user));
                    }}
                  />
                </StyledDiv>
              ))}
            </div>
            <div style={{ position: "relative" }}>
              <StyledInputWrapper>
                <StyledInput
                  ref={nameOrEmailRef}
                  type="search"
                  placeholder="Enter a name or email"
                  onFocus={() => setShowDropDown(true)}
                  onBlur={() => setTimeout(() => setShowDropDown(false), 200)}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    searchUsers();
                  }}
                  onKeyDown={handleKeyDown}
                />
              </StyledInputWrapper>
              {showDropdown && (
                <StyledList onMouseDown={(e) => e.preventDefault()}>
                  {suggestedUsers.length > 0
                    ? suggestedUsers.map((user, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelect(user)}
                          style={{
                            padding: "8px",
                            cursor: "pointer",
                            backgroundColor: index === selectedIndex ? "#3f3f3f" : "transparent",
                          }}
                          tabIndex={0}
                        >
                          {user.name} ({user.email})
                        </li>
                      ))
                    : searchTerm.length >= 3 && (
                        <li onClick={() => handleSelect("Add User")} style={{ padding: "8px", cursor: "pointer" }}>
                          Invite <span style={{ color: "#DF8A00" }}>{searchTerm}</span> to the Team
                        </li>
                      )}
                </StyledList>
              )}
            </div>
          </div>
          <StyledDiv>
            <StyledLink to="/teams">Cancel</StyledLink>
            <Button
              onClick={(e) => {
                e.preventDefault();
                saveTeam();
              }}
            >
              {"Create"}
            </Button>
          </StyledDiv>
        </StyledForm>
      </StyledMessage>
    </StyledBody>
  );
};
