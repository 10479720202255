import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router";
import { Form, FormWrapper, Message } from "./EnterEmail";

import { FormattedMessage, useIntl } from "react-intl";
import { useStore } from "../../../../MobileApp/store/store";
import { authService, AuthState } from "../../../../MobileApp/services/authService";
import { PrimaryButton } from "../../../../MobileApp/FlowDetail";
import { LoginWrapper } from "./Login";

export const VerifyCode: React.FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const inputRef = useRef(null);
  const [buttonLoading] = useState<boolean | null>(null);

  const authResponse = useStore.getState().authResponse;

  const [authState, setAuthState] = useState<AuthState>({
    heading: authResponse?.heading || null,
    message: authResponse?.message || null,
    buttonLoading: false,
  });

  // If we arrive to the verify page without an authResponse, then send people to the login screen
  if (!authResponse) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      navigate("/login");
      return () => {
        // Cleanup
      };
    }, []);
    return;
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setAuthState((state) => ({ ...state, buttonLoading: true }));
    const response = await authService.verify((inputRef.current! as HTMLInputElement).value, authResponse!);
    await authService.handleAuthResponseDashboard(response, "", setAuthState, navigate);
  }

  return (
    <LoginWrapper>
      <div>
        <h2>
          <FormattedMessage id="verify.enter_code_message" defaultMessage="Enter verification code" />
        </h2>
      </div>

      <FormWrapper>
        <Message className={authState.message && !buttonLoading ? "visible" : ""}>
          <b>{authState.heading}</b>
          <br />
          <span dangerouslySetInnerHTML={{ __html: authState.message || "" }}></span>
        </Message>

        <Form onSubmit={handleSubmit}>
          <input
            ref={inputRef}
            placeholder={intl.formatMessage({
              id: "verify.enter_code_placeholder",
              defaultMessage: "Enter code",
            })}
            type={"number"}
            inputMode={"numeric"}
            autoFocus
            autoComplete="oneTimeCode"
          />
        </Form>
      </FormWrapper>

      <PrimaryButton onClick={handleSubmit} disabled={authState.buttonLoading}>
        <FormattedMessage id="verify.submit" defaultMessage="Submit" />
      </PrimaryButton>
      <Link to="/">
        <FormattedMessage defaultMessage="Cancel" id="login.cancel" />
      </Link>
    </LoginWrapper>
  );
};
