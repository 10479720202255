import { appUrlPrefix, loadFromCache, mobileAppStore, saveToDevice, saveToStoreAndDevice } from "../store/store";

export const putAppApiCall = (appUrlPath: string, json: any) => {
  return jsonAppApiCall("PUT", appUrlPath, json);
};

export const postAppApiCall = (appUrlPath: string, json?: any) => {
  return jsonAppApiCall("POST", appUrlPath, json);
};

function apiResponseToJson(response: Response) {
  return response.json();
}

export const jsonAppApiCall = (method: string, appUrlPath: string, json?: any) => {
  return fetch(`${appUrlPrefix}/${appUrlPath}`, {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: json ? JSON.stringify(json) : undefined,
  })
    .then((response) => {
      return apiResponseToJson(response);
    })
    .catch((error) => {
      throw error; // rethrow so that the caller can also decide what to do
    });
};

export const getAppApiCall = (appGetUrl: string, signal?: AbortSignal) => {
  return fetch(`${appUrlPrefix}/${appGetUrl}`, {
    signal,
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
  })
    .then((response) => {
      // If the response is not ok, then throw an error
      if (!response.ok) {
        if (response.status === 401) {
          // If we aren't logged in, then clear the current user
          // this will cause <ProtectedRoute> to redirect the user to the login page.
          mobileAppStore.setState({ authResponse: undefined });
          saveToDevice("authResponse", {});
        }

        throw response;
      }
      return apiResponseToJson(response);
    })
    .catch((error) => {
      throw error; // rethrow so that the caller can also decide what to do
    });
};

export const isApiError = (response: any) => {
  return !response || (response.message && response.heading);
};

export const getAndSaveFromApi = async (
  appGetUrl: string,
  key: string = appGetUrl,
  skipStore: boolean = false,
  signal?: AbortSignal,
) => {
  return getAppApiCall(appGetUrl, signal)
    .then(async (data) => {
      if (!isApiError(data) && Object.keys(data).length > 0) {
        await saveToStoreAndDevice(key, data, skipStore);
      }
      return data;
    })
    .catch((error) => {
      console.log("getAndSaveFromApi error:", error);
      return {};
    });
};

export const getAndSaveFromApiNow = async (
  appGetUrl: string,
  setResult: (result: any) => void,
  key: string = appGetUrl,
  signal?: AbortSignal,
) => {
  const cachedValue = await loadFromCache(key);
  if (cachedValue) {
    setResult(cachedValue);
  }

  const loadedValue = await getAndSaveFromApi(appGetUrl, key, false, signal);
  setResult(loadedValue);

  if (loadedValue) return loadedValue;
  if (cachedValue) return cachedValue;
  return null;
};
