import React, { useState } from "react";
import { Modal, ModalOverlay } from "./styles";
import { PrimaryButton } from "../FlowDetail";
import { mobileAppStore } from "../store/store";
import image from "../../../assets/onboarding/turn-taking.png";

export const PassItOn = () => {
  const showOnboarding = mobileAppStore.use.showOnboarding();
  const [gotItClicked, setGotItClicked] = useState(false);

  if (!showOnboarding || gotItClicked) {
    return null;
  }

  return (
    <>
      <Modal style={{ display: showOnboarding ? "block" : "none" }}>
        <img src={image} />
        <h2>Timer</h2>
        <p>It’s now the next person’s turn. Pass your phone to them to read their prompt.</p>
        <PrimaryButton
          onClick={() => {
            setGotItClicked(true);
          }}
        >
          Got it
        </PrimaryButton>
      </Modal>
      <ModalOverlay style={{ display: showOnboarding ? "block" : "none" }} />
    </>
  );
};
