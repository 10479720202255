import React, { useEffect, useState } from "react";
import { hideLoading, showLoading } from "../../../../lib/hideLoading";
import { useNavigate, useParams } from "react-router";
import { prepareInvitation } from "../API/invitationApi";

export const JoinParentInvitation: React.FC = () => {
  const params = useParams();

  const parentInvitationHashId = params.parentInvitationHashId as string;

  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    showLoading();

    prepareInvitation(parentInvitationHashId)
      .then((preparedInvitation) => {
        // If we received an invitation id, then join there now
        if (preparedInvitation.hashId) {
          // TODO - hey Oli - this line gives us a 404 right now, so I'm using location.href = '...' instead
          // navigate(`/${preparedInvitation.hashId}`);
          window.location.href = `/s/${preparedInvitation.hashId}`;
        }
        // If we need to go somewhere in the scheduling app due to a legacy feature, redirect there now
        else if (preparedInvitation.redirectUrl) {
          window.location.href = preparedInvitation.redirectUrl;
        }
        // Otherwise there must be some kind of error
        else {
          setError(new Error(preparedInvitation.error));
        }
      })
      .catch((error) => {
        setError(error);
      });
    return () => {
      hideLoading();
    };
  }, []);

  if (error) {
    throw error;
  }

  return <></>;
};
