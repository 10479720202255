import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router";
import { mobileAppStore } from "../../../MobileApp/store/store";
import styled from "styled-components";
import { loadMember } from "../API/teamApi";

const StyledBreadCrumbs = styled.section`
  background-color: light-dark(#eee, #2f2c2b);
  padding: 7px 13px;
  border-radius: 10px;
  margin-bottom: 20px;

  display: flex;
  margin-bottom: 20px;

  h1 {
    margin: 0;
    font-weight: normal;
    color: light-dark(#444, #bbb);
    font-size: 16px;
  }

  .crumb:after {
    content: ">";
    margin: 0 10px;
  }

  .crumb:last-child:after {
    display: none;
  }

  .crumb:last-child {
    color: light-dark(#000, #fff);
    font-weight: bold;
  }

  .crumb:hover {
    color: light-dark(#000, #fff);
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  color: light-dark(#222, #bbb);
  text-decoration: none;
  &:hover {
    color: light-dark(#000, #fff);
  }
`;

export const BreadCrumbs = () => {
  const location = useLocation();
  const { teamHashId, memberId } = useParams<{ teamHashId: string; memberId: string }>();
  const teams = mobileAppStore.use.teams();
  const team = teamHashId ? teams[teamHashId] : null;

  const teamMember = mobileAppStore.use.member();

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const path = location.pathname.split("/");
  let currentLink = "";

  const crumbs = path
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      let displayName = crumb;

      if (crumb === teamHashId && team) {
        displayName = team.name;
      } else if (crumb === memberId && teamMember) {
        displayName = teamMember.name || "...";
      } else {
        displayName = crumb.split("-").map(capitalize).join(" ");
      }

      currentLink += `/${crumb}`;

      return (
        <h1 key={crumb} className="crumb">
          <StyledLink to={currentLink}>{displayName}</StyledLink>
        </h1>
      );
    });

  if (crumbs.length === 0) {
    return null;
  }
  return <StyledBreadCrumbs>{crumbs}</StyledBreadCrumbs>;
};
