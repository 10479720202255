import { storeFlowWithData } from "../store/store";
import { getAndSaveFromApi } from "./ApiUtil";

export const getFlowWithData = async (hashId: string): Promise<AppFlow> => {
  const key = `flows/${hashId}`;
  return getAndSaveFromApi(key, key, true).then((result) => {
    const flowWithData = result as AppFlow;
    storeFlowWithData(flowWithData);
    return flowWithData;
  });
};
