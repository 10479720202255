import React from "react";
import { mobileAppStore } from "../../../MobileApp/store/store";
import {
  createWarmspaceAtWorkList,
  FeaturedFlowWrapper,
  renderAppFlows,
  ScrollableFlowRow,
} from "../../../MobileApp/Home";
import { useNavigate } from "react-router";
import { styled } from "styled-components";

const FlowsWrapper = styled.div`
  section {
    background: none !important;
    background-color: none !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  h2 {
    font-size: 13px;
    font-weight: normal;
    text-transform: uppercase;
  }

  ${ScrollableFlowRow} {
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin: 0px;
  }

  ${FeaturedFlowWrapper} {
    padding: 0px;
    margin: 0px;
    width: 300px;
    height: auto;

    overflow: hidden;

    .description {
      height: auto;
    }

    .description div {
      height: unset;
      overflow: hidden;
    }

    .description p {
      height: unset;

      font-size: 12px;
      text-overflow: ellipsis;
    }

    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const Flows: React.FC = () => {
  const flows = mobileAppStore.use.flows();
  const navigate = useNavigate();

  const warmspaceAtWork = createWarmspaceAtWorkList(flows);

  return (
    <FlowsWrapper>
      {renderAppFlows(flows, navigate, false, () => {}, true)}
      {warmspaceAtWork && renderAppFlows(warmspaceAtWork, navigate, false, () => {}, true)}
    </FlowsWrapper>
  );
};
