import React, { useEffect, useState } from "react";
import { appUrlPrefix, mobileAppStore } from "../../../MobileApp/store/store";
import styled from "styled-components";
import { Checkbox } from "../../Checkbox/Checkbox";
import { getAppApiCall } from "../../../MobileApp/apiRequests/ApiUtil";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Chart, Bar, Line } from "react-chartjs-2";

// Register only required components
ChartJS.register(
  CategoryScale, // X-axis (categorical)
  LinearScale, // Y-axis (numeric)
  BarElement, // Bar chart elements
  LineElement, // Line chart elements
  PointElement, // Data points for Line chart
  Title,
  Tooltip,
  Legend,
);

interface LobbySpace {
  name: string;
  hashId: string;
}

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FiltersWrapper = styled.div`
  width: 100%;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Filter = styled.span`
  width: fit-content;
  padding: 5px;
`;

function toggleString(array: string[], str: string): string[] {
  return array.includes(str) ? array.filter((item) => item !== str) : [...array, str];
}

export const Analytics: React.FC = () => {
  const myTeamHashIds = mobileAppStore.use.myTeams();
  const teams = mobileAppStore.use.teams();
  const personalLobbySpaceId = mobileAppStore.use.authResponse()?.lobbySpaceHashId;

  const lobbySpaces: LobbySpace[] = [];
  if (personalLobbySpaceId) {
    lobbySpaces.push({ name: "Personal", hashId: personalLobbySpaceId });
  }
  myTeamHashIds?.map((teamHashId) => {
    const team = teams[teamHashId];
    return lobbySpaces.push({ name: team.name, hashId: team.lobbySpaceHashId });
  });

  const [checkedLobbySpaceHashIds, setCheckedLobbySpaceHashIds] = useState<string[]>(
    lobbySpaces.map((lobbySpce) => lobbySpce.hashId),
  );
  const [analyticsReport, setAnalyticsReport] = useState<AnalyticsReportResponse | undefined>();
  const [apiUrl, setApiUrl] = useState<string>(`analytics/${checkedLobbySpaceHashIds.join(",")}`);

  function toggleCheckedSpace(lobbySpaceHashId: string) {
    setCheckedLobbySpaceHashIds(toggleString(checkedLobbySpaceHashIds, lobbySpaceHashId));
  }

  useEffect(() => {
    getAppApiCall(apiUrl).then((response: AnalyticsReportResponse) => setAnalyticsReport(response));
  }, [apiUrl]);

  function removeParam(filter: ChartFilterResponse) {}

  return (
    <div>
      <div>Analytics Page</div>
      <div>
        <a href={appUrlPrefix + apiUrl}>{apiUrl}</a>
      </div>
      <InnerWrapper>
        <div>
          {lobbySpaces.length >= 2 && <div>Select Spaces(s)</div>}
          {lobbySpaces.map((lobbySpace) => (
            <Checkbox key={lobbySpace.hashId}>
              {lobbySpace.name}
              <input
                type="checkbox"
                checked={checkedLobbySpaceHashIds.includes(lobbySpace.hashId)}
                onClick={() => toggleCheckedSpace(lobbySpace.hashId)}
                onChange={() => toggleCheckedSpace(lobbySpace.hashId)}
              />
              <span className="checkmark"></span>
            </Checkbox>
          ))}
        </div>
        <FiltersWrapper>
          <Filters>
            {analyticsReport &&
              analyticsReport.filters.map(
                (filter) =>
                  filter.activeFilter && (
                    <Filter key={filter.activeFilter?.displayName}>
                      {filter.activeFilter!.displayName}
                      {filter.activeFilter!.removable && (
                        <span
                          style={{ cursor: "pointer", padding: "0 10px 0 5px", color: "orange" }}
                          onClick={() => removeParam(filter)}
                        >
                          x
                        </span>
                      )}
                    </Filter>
                  ),
              )}
          </Filters>
          <Filters>
            {analyticsReport &&
              analyticsReport.filters.map((filter) => (
                <Filter key={filter.filterMenuData.filterMenuName}>
                  {(filter.filterMenuData as SelectFilterResponse).selectOptions && (
                    <select defaultValue={filter.activeFilter?.id}>
                      <option disabled={true}>{filter.filterMenuData.filterMenuName}</option>
                      {(filter.filterMenuData as SelectFilterResponse).selectOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.displayName}
                        </option>
                      ))}
                    </select>
                  )}
                </Filter>
              ))}
          </Filters>
        </FiltersWrapper>

        <div>
          {analyticsReport?.basicCharts.map((chart, idx) => {
            const c = chart.data as ChartInfo;

            return (
              <div key={"basicChart-" + idx}>
                {c.type == "line" && <Line data={c.data} options={c.options} />}
                {c.type == "bar" && <Bar data={c.data} options={c.options} />}
              </div>
            );
          })}
        </div>
      </InnerWrapper>
    </div>
  );
};
