import React from "react";
import { Modal, ModalOverlay } from "./styles";
import { PrimaryButton } from "../FlowDetail";

export const EndFlow: React.FC<{ visible: boolean; hide: () => void; endFlow: () => void }> = ({
  visible,
  hide,
  endFlow,
}) => {
  return (
    <>
      <Modal style={{ display: visible ? "block" : "none" }}>
        <h2>Are you sure?</h2>
        <p>Do you want to end this flow and return to the home page?</p>
        <PrimaryButton
          onClick={() => {
            endFlow();
          }}
        >
          End flow
        </PrimaryButton>

        <a
          style={{ color: "black" }}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            hide();
          }}
        >
          Cancel{" "}
        </a>
      </Modal>
      <ModalOverlay
        style={{ display: visible ? "block" : "none" }}
        onClick={() => {
          hide();
        }}
      />
    </>
  );
};
