import React, { FC } from "react";
import styled from "styled-components";
import { secondsToMMSS } from "../../../lib/duration";
import { LargeClose } from "../../../assets/icons/Icons";

const circleRadius = 40;
const circleDashArrayLength = Math.PI * 2 * circleRadius;

const Wrapper = styled.div`
  font-size: 30px;
  font-weight: bold;
  opacity: 1;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.5s;

  &.transitioning {
    svg circle {
      transition-duration: 0s !important;
      transition-delay: 0s !important;
      transition: stroke-dashoffset 0s linear !important;
    }
  }

  &.smallText {
    font-size: 20px;
  }

  &.hidden {
    opacity: 0;
  }

  span {
    position: relative;

    display: flex;
    width: 100px;
    height: 100px;

    div {
      position: absolute;
      top: 51%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  svg {
    transform: rotate(-90deg);
  }

  svg circle {
    stroke-dasharray: ${circleDashArrayLength};

    stroke-linecap: round;
    stroke-width: 4px;
    fill: none;
    stroke: #fe5f25;
    z-index: 1;
    stroke-dashoffset: 0px;
    transition: stroke-dashoffset 1s linear;
  }

  svg circle.track {
    stroke: #d9cdb7;
    z-index: -1;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -12px !important;
  right: -12px;
  z-index: 10;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;

  background-color: #d9cdb5;
  width: 35px;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  svg {
    width: 14px;
  }
`;

interface Props {
  timeRemainingOnStep: number;
  totalTime: number;
  onClose: () => void;
  transitioning: boolean;
}

export const CountDownV2: FC<Props> = ({ timeRemainingOnStep, totalTime, onClose, transitioning }) => {
  let percentage = Math.min(timeRemainingOnStep / totalTime, 0.999); // 0.999 here avoids a flicker of a full circle appearing at the start

  if (transitioning) {
    percentage = 0.999;
  }

  const dashoffset = circleDashArrayLength - percentage * circleDashArrayLength;

  if (!totalTime || Number.isNaN(timeRemainingOnStep)) {
    return null;
  }

  const classes = [];
  if (timeRemainingOnStep > 60) classes.push("smallText");
  if (timeRemainingOnStep === 0) classes.push("hidden");
  if (transitioning) classes.push("transitioning");

  return (
    <Wrapper className={classes.join(" ")}>
      <CloseButton onClick={onClose} disabled={timeRemainingOnStep === 0 || transitioning}>
        <LargeClose stroke="#888" strokeWidth={4} />
      </CloseButton>
      <span>
        <div>{timeRemainingOnStep > 60 ? secondsToMMSS(timeRemainingOnStep) : timeRemainingOnStep}</div>
        <svg width="100%" height="100%">
          <circle
            className="track"
            data-testid="track-circle"
            r={circleRadius}
            cx="50%"
            cy="50%"
            style={{ strokeDashoffset: "0px" }}
          ></circle>
          <circle
            data-testid="countdown-circle"
            r={circleRadius}
            cx="50%"
            cy="50%"
            style={{ strokeDashoffset: -dashoffset }}
          ></circle>
        </svg>
      </span>
    </Wrapper>
  );
};
