import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { StyledBody } from "./Empty";
import { CircleQuestion } from "grommet-icons";
import { CheckBox } from "../../../Modal/ModalStyle";
import { Link, useNavigate, useParams } from "react-router";
import Button from "../../Button";
import { mobileAppStore } from "../../../../MobileApp/store/store";
import { loadUserSuggestions, updateTeam } from "../../API/teamApi";

export const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 500px;
  box-sizing: border-box;
  border-radius: 8px;
  border: #757575 1px solid;
  background-color: #333;
  padding: 15px;
  gap: 20px;
  h1 {
    font-size: 22px;
    margin: 0;
  }
  p {
    font-size: 16px;
    color: #fff;
    margin: 0;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledLabelHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
  align-items: center;
  button {
    padding: 8px;
    border-radius: 8px;
    border: none;
    background-color: #999999;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    font-weight: 600;
  }
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #292929;
  border: 1px solid #524f4f;
`;

export const StyledInput = styled.input`
  background-color: transparent;
  color: #fff;
  border: none;
  width: 100%;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #bbb;
    text-decoration: italic;
  }
`;

export const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  line-height: normal;
  gap: 10px;
`;

export const StyledLink = styled(Link)`
  color: #fff;
  cursor: pointer;
  font-size: 17px;
`;

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #292929;
  border-radius: 8px;
  border: 1px solid #524f4f;
  position: absolute;
  z-index: 999;
  max-height: 200px;
  overflow-y: auto;
  top: 100%;
  left: 0;
  width: 100%;
`;

export const EditTeam = () => {
  const teams = mobileAppStore.use.teams();
  const allTeamMembers = mobileAppStore.use.teamMembers();
  const params = useParams();
  const teamHashId = params.teamHashId as string | undefined;
  const currentTeam = teamHashId ? teams[teamHashId] : null;
  const [curTeamMembers, setCurTeamMembers] = useState<TeamMember[]>(
    teamHashId ? allTeamMembers[teamHashId] || [] : [],
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [suggestedUsers, setSuggestedUsers] = useState<SuggestedUser[]>([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState<boolean>(false);

  const teamNameRef = useRef<HTMLInputElement>(null);
  const nameOrEmailRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = React.useState("");
  const [teamLead, setTeamLead] = React.useState<TeamMember[]>(curTeamMembers.filter((m) => m.lead));
  const [selectedUsers, setSelectedUsers] = React.useState<string[]>([]);
  const [showDropdown, setShowDropDown] = React.useState(false);

  const handleSelect = (user: any) => {
    if (user === "Add User") {
      setSelectedUsers([...selectedUsers, searchTerm]);
      setSearchTerm("");
      addMember(undefined, searchTerm);
    } else {
      const userLabel = `${user.name} (${user.email})`;
      setSelectedUsers([...selectedUsers, userLabel]);
      const suggestedUser = suggestedUsers.find((u) => u === user);
      addMember(suggestedUser);
    }
    setShowDropDown(false);
    nameOrEmailRef.current!.value = "";
  };

  useEffect(() => {
    if (teamNameRef?.current) {
      teamNameRef.current.value = currentTeam ? currentTeam.name : "";
    }
  }, []);

  function saveTeam() {
    const updatedTeam = Object.assign({}, currentTeam);
    const teamLeadIds = teamLead.map((u) => u.id);
    const newTeamMembers = curTeamMembers.map((m) => {
      return { ...m, lead: teamLeadIds.includes(m.id) };
    });
    const updateTeamRequest = { members: newTeamMembers };

    updateTeam(updatedTeam, updateTeamRequest).then(() => navigate("/teams/" + teamHashId));
  }

  function addMember(suggestedUser?: SuggestedUser, searchString?: string) {
    // TODO: validate that the searchString is an actual email address.
    //  If it includes a name in the format: name<some@email.com>, then pass the name along in the request
    const email = suggestedUser ? null : searchString;

    const memberToAdd = {
      id: suggestedUser?.id,
      name: suggestedUser?.name,
      email: suggestedUser?.email || email!,
      lead: curTeamMembers.length == 0,
      facilitator: false,
      observer: false,
      active: true,
    };

    const newTeamMembersToAdd = [...curTeamMembers];
    newTeamMembersToAdd.push(memberToAdd);
    setCurTeamMembers(newTeamMembersToAdd);
  }

  const handleChangeTeamLead = (user: TeamMember) => {
    if (teamLead.includes(user)) {
      setTeamLead(teamLead.filter((u) => u !== user));
    } else {
      setTeamLead([...teamLead, user]);
    }
  };

  function searchUsers() {
    if (loadingSuggestions) return;
    setLoadingSuggestions(true);
    setTimeout(() => {
      setLoadingSuggestions(false);
      const searchString = nameOrEmailRef?.current?.value;
      setSearchTerm(searchString || "");

      if (searchString && searchString.length >= 3) {
        loadUserSuggestions(searchString).then((suggestedUsers) => {
          const filteredSuggestions =
            suggestedUsers.length > 0 ? suggestedUsers.filter((u) => !curTeamMembers.find((t) => t.id == u.id)) : [];
          setSuggestedUsers(filteredSuggestions);
        });
      } else {
        setSuggestedUsers([]);
      }
    }, 200);
  }

  const handleKeyDown = (e: { key: any; preventDefault: () => void }) => {
    if (!showDropdown || suggestedUsers.length === 0) return;

    switch (e.key) {
      case "ArrowDown":
        setSelectedIndex((prev) => Math.min(prev + 1, suggestedUsers.length - 1));
        e.preventDefault();
        break;
      case "ArrowUp":
        setSelectedIndex((prev) => Math.max(prev - 1, 0));
        e.preventDefault();
        break;
      case "Enter":
        if (selectedIndex >= 0) {
          handleSelect(suggestedUsers[selectedIndex]);
        }
        break;
      case "Escape":
        setShowDropDown(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (searchTerm.length > 0) {
      setShowDropDown(true);
    }
  }, [searchTerm]);

  return (
    <StyledBody>
      <StyledLabelHeader>
        <h1>{"Add a new member"}</h1>
        <CircleQuestion color="#fff" />
      </StyledLabelHeader>
      <StyledMessage>
        <StyledForm>
          <div>
            <label>New members:</label>
            <div>
              {selectedUsers.map((user, index) => (
                <StyledDiv key={index}>
                  <StyledInputWrapper>
                    {/* TODO: if the user doesn't exist yet, then show the mail icon */}
                    {/* {suggestedUsers.find((u) => u.name === user)?.email ? "" : <MailOption />} */}
                    <StyledInput type="search" value={user} style={{ width: "100%" }} readOnly />
                  </StyledInputWrapper>
                  <CheckBoxLabel>
                    <CheckBox
                      type="checkbox"
                      onChange={() => {
                        const member = curTeamMembers[index + 1];
                        if (!member) return;
                        handleChangeTeamLead(member);
                      }}
                      checked={teamLead.find((u) => u.id === curTeamMembers[index + 1].id) ? true : false}
                    />
                    Lead
                  </CheckBoxLabel>
                </StyledDiv>
              ))}
            </div>
            <div style={{ position: "relative" }}>
              <StyledInputWrapper>
                <StyledInput
                  ref={nameOrEmailRef}
                  type="search"
                  placeholder="Enter a name or email"
                  onFocus={() => setShowDropDown(true)}
                  onBlur={() => setTimeout(() => setShowDropDown(false), 200)}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    searchUsers();
                  }}
                  onKeyDown={handleKeyDown}
                />
              </StyledInputWrapper>
              {showDropdown && (
                <StyledList onMouseDown={(e) => e.preventDefault()}>
                  {suggestedUsers.length > 0
                    ? suggestedUsers.map((user, index) => (
                        <li
                          key={index}
                          onClick={() => handleSelect(user)}
                          style={{
                            padding: "8px",
                            cursor: "pointer",
                            backgroundColor: index === selectedIndex ? "#3f3f3f" : "transparent",
                          }}
                          tabIndex={0}
                        >
                          {user.name} ({user.email})
                        </li>
                      ))
                    : searchTerm.length >= 3 && (
                        <li onClick={() => handleSelect("Add User")} style={{ padding: "8px", cursor: "pointer" }}>
                          Invite <span style={{ color: "#DF8A00" }}>{searchTerm}</span> to the Team
                        </li>
                      )}
                </StyledList>
              )}
            </div>
          </div>
          <StyledDiv>
            <StyledLink to={`/teams/${teamHashId}`}>Cancel</StyledLink>
            <Button
              onClick={(e) => {
                e.preventDefault();
                saveTeam();
              }}
            >
              Save
            </Button>
          </StyledDiv>
        </StyledForm>
      </StyledMessage>
    </StyledBody>
  );
};
