import React, { useEffect, useRef, useState } from "react";
import {
  ButtonGroup,
  CalendarEvent,
  FieldGroup,
  FormActions,
  FormLabel,
  InlineFieldGroup,
  ScheduleFormWrapper,
  SecondaryButton,
} from "./Styles";
import { Select } from "../../../../UI/Select/Select";
import { FormattedMessage } from "react-intl";
import { TextInput } from "../../../TextInput/TextInput";
import Button from "../../Button";
import { mobileAppStore } from "../../../../MobileApp/store/store";
import { useParams } from "react-router";
import { scheduleFlow } from "../../API/calendarApi";
import toast from "react-simple-toasts";

interface ScheduleFormProps {
  videoProviders: VideoProvider[];
  setSelectedDate: (date: Date | undefined) => void;
  selectedDate: Date | undefined;
  setSelectedEvent: (event: CalendarEvent | undefined) => void;
  selectedEvent: CalendarEvent | undefined;
  setSelectedFlow: (flow: AppFlow | undefined) => void;
  selectedFlow: AppFlow | undefined;
  groupSizeRef: React.RefObject<HTMLSelectElement | null>;
  flowSelectRef: React.RefObject<HTMLSelectElement | null>;
  calendarEvents: CalendarEvent[];
  setCalendarEvents: (events: CalendarEvent[]) => void;
  _loadCalendar: () => Promise<void>;
}

export function getFlows(flows: AppFlows | undefined, list: AppFlow[]) {
  flows?.flows?.forEach((f) => list.push(f));
  flows?.flowLists?.forEach((fl) => getFlows(fl, list));
  return list;
}

export const ScheduleForm: React.FC<ScheduleFormProps> = ({
  videoProviders,
  setSelectedDate,
  selectedDate,
  setSelectedEvent,
  selectedEvent,
  setSelectedFlow,
  selectedFlow,
  groupSizeRef,
  flowSelectRef,
  calendarEvents,
  setCalendarEvents,
  _loadCalendar,
}) => {
  const params = useParams();
  const selectedEventDate = selectedEvent ? new Date(selectedEvent.date) : undefined;

  const authResponse = mobileAppStore.use.authResponse()!;
  const teams = mobileAppStore.use.teams();
  const myTeams = mobileAppStore.use.myTeams(); // Allow this to be undefined. An empty array means we have no teams and need to redirect to the teams page.
  const selectedTeamId = mobileAppStore.use.selectedTeamId() ?? params.teamHashId;
  const teamsFlows = mobileAppStore.use.teamsFlows();

  const teamAppFlows = teamsFlows && selectedTeamId ? teamsFlows[selectedTeamId] : undefined;
  const flows = getFlows(teamAppFlows, []);
  const uniqueFlows = Array.from(new Set(flows.map((a) => a.hashId))).map((hashId) => {
    return flows.find((a) => a.hashId === hashId) as AppFlow;
  });

  const [saving, setSaving] = useState(false);
  const [recurring, setRecurring] = useState<"Weekly" | "Monthly" | "One-off">("Weekly");
  const [series, setSeries] = useState<"All Future Events" | "Just Once">("All Future Events");
  const [timeInput, setTimeInput] = useState(
    selectedDate?.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: false }),
  );
  const [videoProvider, setVideoProvider] = useState<VideoProvider>("Warmspace");
  const [personalVideoLink, setPersonalVideoLink] = useState<string | undefined>(
    authResponse?.currentUser?.personalVideoLink,
  );

  const refVideoLinkInput = useRef<HTMLInputElement>(null);

  const groupSizes: number[] = [];
  if (selectedFlow) {
    for (let i = selectedFlow.min; i <= selectedFlow.max; i++) {
      groupSizes.push(i);
    }
  }

  useEffect(() => {
    if (selectedDate) {
      setTimeInput(selectedDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: false }));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (teamAppFlows && !selectedFlow) {
      const flows = getFlows(teamAppFlows, []);

      // If there's a flow paramter, then use that one
      if (params.flowHashId) {
        const foundFlow = flows.find((f) => f.hashId === params.flowHashId);
        if (foundFlow) {
          setSelectedFlow(foundFlow);
          flowSelectRef.current!.value = foundFlow.hashId;
          return;
        }
      }

      // Otherwise, pick the first one from the list
      setSelectedFlow(flows[0]);
    }
  }, [teamAppFlows]);

  function doScheduleFlow() {
    if (!selectedFlow) return;

    setSaving(true);

    const preferred = groupSizeRef.current?.value;
    const request: ScheduleFlowRequest = {
      flowHashId: selectedFlow!.hashId,
      teamHashId: selectedTeamId,
      preferred: preferred ? parseInt(preferred) : undefined,
      hashId: selectedEvent?.scheduledFlow?.hashId,
    };

    if (selectedDate) {
      switch (recurring) {
        case "Weekly":
          request.period = "week";
          break;
        case "Monthly":
          request.period = "month";
          break;
        case "One-off":
          request.period = "one_off";
          break;
      }
      request.repeat = 1;
      request.date = selectedDate;
      request.preferredVideoProvider = videoProvider;
      request.personalVideoLink = personalVideoLink ?? refVideoLinkInput.current?.value;
    } else {
      if (selectedEvent!.seriesMasterId && series == "All Future Events") {
        request.eventId = selectedEvent!.seriesMasterId;
        request.eventInstanceId = selectedEvent?.id;
        request.date = new Date(selectedEvent!.date);
      } else {
        request.eventId = selectedEvent!.id;
      }
    }

    scheduleFlow(request)
      .then((newEvent) => {
        const newCalendarEvents = [...calendarEvents];
        newCalendarEvents.push(newEvent);
        setCalendarEvents(newCalendarEvents);
      })
      .then(() => {
        return _loadCalendar();
      })
      .then(() => {
        setSelectedDate(undefined);
        setSelectedEvent(undefined);
        toast("Scheduled flow successfully", { duration: 5000 });
      })
      .catch((e) => {
        console.error(e);
        toast("Failed to schedule flow", { duration: 5000 });
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <ScheduleFormWrapper onSubmit={(e) => e.preventDefault()}>
      <InlineFieldGroup>
        <FormLabel htmlFor="flow">Flow:</FormLabel>
        <Select
          id="flow"
          ref={flowSelectRef}
          onChange={() => setSelectedFlow(flows.find((f) => f.hashId === flowSelectRef.current!.value))}
        >
          {uniqueFlows.map((f) => (
            <option key={"flow-option-" + f.hashId} value={f.hashId}>
              {f.name}
            </option>
          ))}
        </Select>
      </InlineFieldGroup>

      <InlineFieldGroup>
        <FormLabel htmlFor="groupSize">
          <FormattedMessage id="calendar.group_size" defaultMessage="Preferred Group Size:" />
        </FormLabel>
        <Select
          id="groupSize"
          ref={groupSizeRef}
          disabled={groupSizes.length === 1}
          style={{
            width: "50px",
            textAlignLast: "center",
            textAlign: "center",
            cursor: groupSizes.length === 1 ? "default" : "pointer",
          }}
        >
          {" "}
          {groupSizes.map((size) => (
            <option key={"group-size-" + size} value={size}>
              {size}
            </option>
          ))}
        </Select>
      </InlineFieldGroup>
      <hr />
      {selectedEvent && selectedEventDate ? (
        <>
          {selectedFlow?.duration} min{" "}
          {selectedEvent.scheduledFlow?.duration != selectedEvent.duration ? "at the start of:" : ""}
          <br />
          <CalendarEvent style={{ display: "inline-block", position: "relative", height: "70px" }}>
            <div className="eventTitle">{selectedEvent?.title}</div>
            <div className="eventTime">
              {selectedEventDate.toLocaleDateString("en-US", {
                weekday: "short",
                month: "short",
                day: "numeric",
              })}
              <br />
              {selectedEventDate.toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })}{" "}
              -{" "}
              {new Date(selectedEventDate.getTime() + selectedEvent.duration * 60 * 1000).toLocaleTimeString("en-US", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })}
            </div>
          </CalendarEvent>
          {selectedEvent?.seriesMasterId && (
            <ButtonGroup>
              {["All Future Events", "Just Once"].map((i) => {
                return (
                  <button
                    style={{ fontSize: "14px" }}
                    className={series === i ? "active" : ""}
                    onClick={() => setSeries(i as "All Future Events" | "Just Once")}
                    key={i}
                  >
                    {i}
                  </button>
                );
              })}
            </ButtonGroup>
          )}
        </>
      ) : (
        <>
          <InlineFieldGroup>
            <TextInput
              id="date"
              type="date"
              onChange={(e) => {
                const newDate = new Date(e.target.value);

                const oldDate = selectedDate ?? newDate;

                newDate.setHours(oldDate.getHours(), oldDate.getMinutes(), 0);

                if (!isNaN(newDate.getTime())) {
                  setSelectedDate(newDate);
                }
              }}
              value={selectedDate ? selectedDate.toISOString().split("T")[0] : ""}
            />
            <TextInput
              id="time"
              type="text"
              pattern={"[0-9]{2}:[0-9]{2}"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  (e.target as HTMLInputElement).blur();
                }
              }}
              onChange={(e) => {
                // Only allow numbers and colons

                if (!/^[0-9:]*$/.test(e.target.value)) {
                  return;
                }

                setTimeInput(e.target.value);
              }}
              onBlur={(e) => {
                setTimeInput(e.target.value);
                const newDate = new Date(selectedDate!);
                const time = e.target.value.split(":");
                newDate.setHours(parseInt(time[0]), parseInt(time[1]), 0);

                if (!isNaN(newDate.getTime())) {
                  setSelectedDate(newDate);
                }

                setTimeInput(
                  newDate.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: false }),
                );
              }}
              value={timeInput ?? ""}
            />
          </InlineFieldGroup>

          <ButtonGroup>
            {["Weekly", "Monthly", "One-off"].map((i) => {
              return (
                <button
                  className={recurring === i ? "active" : ""}
                  onClick={() => setRecurring(i as "Weekly" | "Monthly" | "One-off")}
                  key={i}
                >
                  {i}
                </button>
              );
            })}
          </ButtonGroup>
          {videoProviders.length >= 2 && (
            <>
              <hr />
              <FormLabel>After session go to</FormLabel>
              <ButtonGroup>
                {videoProviders.map((v) => {
                  return (
                    <button className={videoProvider == v ? "active" : ""} onClick={() => setVideoProvider(v)} key={v}>
                      {v}
                    </button>
                  );
                })}
              </ButtonGroup>
              {videoProvider == "Zoom" && !authResponse?.currentUser?.personalVideoLink?.includes("zoom.us") && (
                <input ref={refVideoLinkInput} type="text" placeholder={"enter personal Zoom link..."} />
              )}
            </>
          )}
        </>
      )}
      <hr />

      {/*eslint-disable-next-line no-constant-binary-expression*/}
      {false && (
        <>
          <FieldGroup>
            <FormLabel htmlFor="customPrompt1">Customize Prompts:</FormLabel>
            <input id="customPrompt1" type="text" />
          </FieldGroup>
          <hr />
        </>
      )}

      <FormActions>
        <SecondaryButton
          onClick={() => {
            setSelectedEvent(undefined);
            setSelectedDate(undefined);
          }}
        >
          Cancel
        </SecondaryButton>
        <Button disabled={saving} onClick={doScheduleFlow}>
          {selectedEvent ? "Update Flows" : "Schedule Flows"}
        </Button>
      </FormActions>
    </ScheduleFormWrapper>
  );
};
