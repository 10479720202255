import styled from "styled-components";

export const ModalOverlay = styled.div`
  background-color: #000000b2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
`;

export const Modal = styled.div`
  text-align: center;
  background-color: #ffeecc;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  z-index: 101;
  h2 {
    font-size: 32px;
    font-family: "ITC Clearface";
  }

  p {
    font-size: 20px;
    margin-top: 20px;
  }
`;
