import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormLeft, Spinner, WarmspaceIcon } from "../../../../../assets/icons/Icons";
import { PageContainer } from "../../../../../dashboard/core";
import { loadCalendar, scheduleFlow } from "../../API/calendarApi";
import { mobileAppStore } from "../../../../MobileApp/store/store";
import { loadMyTeamList, loadTeamFlows } from "../../API/teamApi";
import { hours } from "./hours";
import {
  CalendarBody,
  CalendarContainer,
  CalendarEvent,
  CalendarHeader,
  CalendarLoading,
  CalendarWrapper,
  DateHeader,
  Dates,
  Day,
  HOUR_HEIGHT,
  HourBox,
  Hours,
  IconButton,
  InlineFieldGroup,
  SecondaryButton,
  SideHourLabels,
  TopRightCornerCover,
  Wrapper,
} from "./Styles";
import { addOverlapInfo } from "./addOverlapInfo";
import toast from "react-simple-toasts";
import { useNavigate, useParams } from "react-router";
import { Select } from "../../../Select/Select";
import { useEscapeKey } from "../../../../../hooks/useEscapeKey";
import { Plan } from "grommet-icons";
import { getFlows, ScheduleForm } from "./ScheduleForm";

// Initial suggested date is Tuesday at 9am
const initialSelectedDate = new Date();
initialSelectedDate.setDate(initialSelectedDate.getDate() - initialSelectedDate.getDay() + 2);
initialSelectedDate.setHours(9, 0, 0, 0);

export const Calendar: React.FC<{ initialShowScheduleForm: boolean }> = ({ initialShowScheduleForm }) => {
  const params = useParams();

  const [showWeekend, setShowWeekend] = useState(false);
  const [weekOffset, setWeekOffset] = useState(initialShowScheduleForm ? 1 : 0);
  const [selectedFlow, setSelectedFlow] = useState<AppFlow | undefined>();
  const groupSizeRef = useRef<HTMLSelectElement>(null);

  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | undefined>();
  const [loading, setLoading] = useState(true);
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const teamSelectRef = useRef<HTMLSelectElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const flowSelectRef = useRef<HTMLSelectElement>(null);

  // Get an array of days in this actual week
  const days: {
    dayOfWeek: string;
    date: Date;
  }[] = [];
  const currentDate = new Date();

  // Build the week
  const date = new Date();
  date.setDate(date.getDate() + weekOffset * 7);
  date.setHours(0, 0, 0, 0);
  const monday = date.setDate(date.getDate() - date.getDay() + 1);

  [0, 1, 2, 3, 4, 5, 6].forEach((i) => {
    const day = new Date(monday + 1000 * 60 * 60 * 24 * i);

    days.push({
      dayOfWeek: day.toLocaleString("en-US", { weekday: "short" }),
      date: day,
    });
  });

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    initialShowScheduleForm ? new Date(monday + 1000 * 60 * 60 * 9) : undefined,
  );

  const authResponse = mobileAppStore.use.authResponse()!;
  const organization = authResponse.organizations.find((o) => o.hashId == authResponse.organizationHashId);
  const videoProviders = organization?.videoProviders ?? ["Warmspace"];
  const teams = mobileAppStore.use.teams();
  const myTeams = mobileAppStore.use.myTeams(); // Allow this to be undefined. An empty array means we have no teams and need to redirect to the teams page.
  const selectedTeamId = mobileAppStore.use.selectedTeamId() ?? params.teamHashId;
  const teamsFlows = mobileAppStore.use.teamsFlows();
  const teamAppFlows = teamsFlows && selectedTeamId ? teamsFlows[selectedTeamId] : undefined;
  const flows = getFlows(teamAppFlows, []);

  const showScheduleForm = selectedDate || selectedEvent;
  const navigate = useNavigate();

  // If we're hitting the calendar tab, refresh the data that we're using here
  useEffect(() => {
    loadMyTeamList();
  }, []);

  useEffect(() => {
    if (selectedTeamId) {
      loadTeamFlows(selectedTeamId);
    }
  }, [selectedTeamId]);

  if (!showWeekend) {
    days.splice(5, 2);
  }

  useEffect(() => {
    if (myTeams && myTeams.length === 0) {
      navigate("/teams");
    }
  }, [myTeams]);

  // Scroll to a more relevant starting position.
  useEffect(() => {
    const eightAM = document.getElementById("08:00");
    const activeDate = document.getElementById("activeDate");

    if (activeDate && eightAM) {
      calendarRef.current?.scrollTo(activeDate.offsetLeft + 120, eightAM?.offsetTop - 60);
    }
  }, []);

  useEscapeKey(() => {
    setSelectedDate(undefined);
    setSelectedEvent(undefined);
  });

  const _loadCalendar = async () => {
    const fromDate = new Date(date);
    fromDate.setDate(fromDate.getDate() - 30);
    const untilDate = new Date(date);
    untilDate.setDate(untilDate.getDate() + 30);

    return loadCalendar(fromDate, untilDate, selectedTeamId)
      .then((events: CalendarEvent[]) => {
        if (!events) return;
        const eventsWithOverlap = addOverlapInfo(events);
        setCalendarEvents(eventsWithOverlap);
      })
      .catch((e) => {
        if (e.status === 401) {
          // Unauthorized, right now we are just redirecting directly to the login page
          // because apiUtil will unset the current user
          toast("Unable to load calendar events. Your Microsoft login probably expired. Please log in again.", {
            duration: 10000,
          });
        } else {
          toast("Failed to load calendar events");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    _loadCalendar();
  }, [weekOffset]);

  const listEvents = useCallback(() => {
    const events = [...calendarEvents];
    if (selectedDate) {
      const tentativeDateString = selectedDate.toISOString();
      const tentativeEvent = {
        title: selectedFlow?.name ?? "Error",
        date: tentativeDateString,
        duration: selectedFlow?.duration,
        overlaps: 0,
        overlapIndex: 0,
        className: "tentative",
        id: tentativeDateString,
      };
      events.push(tentativeEvent as CalendarEvent);
    }
    return events;
  }, [selectedFlow, selectedDate, calendarEvents]);

  return (
    <PageContainer>
      <Wrapper>
        <CalendarWrapper>
          <CalendarLoading className={loading ? "loading" : ""}>
            <Spinner />
            Loading calendar...
          </CalendarLoading>
          <CalendarHeader>
            <div>
              {teams && Object.values(teams).length > 0 && (
                <InlineFieldGroup>
                  <Plan />
                  <Select id="team" ref={teamSelectRef}>
                    <option key={"team-personal"} value={""}>
                      {"Personal"}
                    </option>
                    {myTeams?.map((t) => {
                      const team = teams[t];
                      return (
                        <option key={"team-" + team.hashId} value={team.hashId}>
                          {team.name}
                        </option>
                      );
                    })}
                  </Select>
                </InlineFieldGroup>
              )}
            </div>

            <div className="actions">
              <IconButton onClick={() => setWeekOffset(weekOffset - 1)}>
                <FormLeft stroke={"light-dark(#000, #fff)"} />
              </IconButton>
              <h2>
                {date.toLocaleString("default", { month: "long" })} {date.getFullYear()}
                <br />
                <small>
                  {days[0].date.toLocaleString("default", { day: "2-digit" })} -{" "}
                  {days[days.length - 1].date.toLocaleString("default", { day: "2-digit" })}
                </small>
              </h2>
              <IconButton className="flipped" onClick={() => setWeekOffset(weekOffset + 1)}>
                <FormLeft stroke={"light-dark(#000, #fff)"} />
              </IconButton>
            </div>

            <div>
              <div>
                <SecondaryButton onClick={() => setWeekOffset(0)} disabled={weekOffset === 0}>
                  Today
                </SecondaryButton>
              </div>
              <SecondaryButton onClick={() => setShowWeekend(!showWeekend)}>
                {showWeekend ? "Hide Weekend" : "Show Weekend"}
              </SecondaryButton>
              {/* <SecondaryButton style={{ marginLeft: "10px" }} onClick={() => {}}>
            Settings
          </SecondaryButton> */}
            </div>
          </CalendarHeader>
          <CalendarContainer ref={calendarRef}>
            <CalendarBody>
              <SideHourLabels>
                <TopRightCornerCover></TopRightCornerCover>
                {hours.map((i) => {
                  return (
                    <span className={i === "00:00" ? "hidden" : ""} key={i} id={i}>
                      {i}
                      <hr />
                    </span>
                  );
                })}
              </SideHourLabels>
              <Dates>
                {listEvents()
                  .filter((event) => {
                    // only events in this week
                    const eventDate = new Date(event.date);

                    return (
                      eventDate.getTime() >= days[0].date.setHours(0, 0, 0, 0) &&
                      eventDate.getTime() <= days[days.length - 1].date.setHours(23, 59, 59, 999)
                    );
                  })
                  .map((event) => {
                    const eventDate = new Date(event.date);
                    const height = (Math.max(event.duration ?? 0, 10) / 60) * HOUR_HEIGHT - 6;
                    const width = "calc(" + 100 / days.length + "% - " + event.overlaps * 3 + 8 + "px)";
                    const top =
                      (eventDate.getHours() + 1) * HOUR_HEIGHT + (eventDate.getMinutes() / 60) * HOUR_HEIGHT + 13;
                    const left = `calc(${(eventDate.getDay() - 1) * (100 / days.length)}% + ${event.overlapIndex * 30}px)`;

                    const needsExpand = height < 45;
                    const classNames = [];
                    if (needsExpand) classNames.push("expandOnHover");
                    if (event === selectedEvent) classNames.push("selected");
                    if (event.scheduledFlow) classNames.push("scheduled");
                    classNames.push(event.className);

                    return (
                      <CalendarEvent
                        style={{ top, left, position: "absolute", width, height }}
                        key={event.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedEvent(event);
                          setSelectedDate(undefined);
                          if (event.scheduledFlow) {
                            const flow = flows.find((f) => f.hashId === event.scheduledFlow!.flowHashId);
                            setSelectedFlow(flow);
                            setTimeout(() => {
                              groupSizeRef.current!.value =
                                event.scheduledFlow!.preferred?.toString() ?? flow?.preferred;
                              flowSelectRef.current!.value = flow!.hashId!;
                            }, 1);
                          }
                        }}
                        className={classNames.join(" ")}
                      >
                        {(event.scheduledFlow || event.className === "tentative") && <WarmspaceIcon fill={"#cb7411"} />}
                        <div className="eventTitle">{event.title}</div>
                        <div className="eventTime">
                          {eventDate.toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          })}{" "}
                          -{" "}
                          {new Date(eventDate.getTime() + event.duration * 60 * 1000).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          })}
                        </div>
                        {event.scheduledFlow && event.scheduledFlow.duration < event.duration ? (
                          <div className="flowInEvent">
                            {event.scheduledFlow.name} {event.scheduledFlow.duration} min at start
                          </div>
                        ) : (
                          event === selectedEvent &&
                          event?.scheduledFlow?.duration !== event.duration && (
                            <CalendarEvent
                              className="tentative"
                              style={{ width: "100%", height: "auto", position: "relative", display: "block" }}
                            >
                              {selectedFlow?.name}
                            </CalendarEvent>
                          )
                        )}
                      </CalendarEvent>
                    );
                  })}
                {days.map((i) => {
                  return (
                    <Day
                      days={days.length}
                      className={i.dayOfWeek === "Sun" || i.dayOfWeek === "Sat" ? "weekend" : ""}
                      key={i.dayOfWeek}
                    >
                      <DateHeader>
                        {i.dayOfWeek}
                        <span
                          className={i.date.toDateString() === currentDate.toDateString() ? "active" : ""}
                          id="activeDate"
                        >
                          {i.date.getDate()}
                        </span>
                      </DateHeader>
                      <Hours>
                        {hours.map((j) => {
                          return (
                            <HourBox
                              onClick={() => {
                                setSelectedDate(
                                  new Date(i.date.setHours(parseInt(j.split(":")[0]), parseInt(j.split(":")[1]))),
                                );
                                setSelectedEvent(undefined);
                              }}
                              key={j}
                            ></HourBox>
                          );
                        })}
                      </Hours>
                    </Day>
                  );
                })}
              </Dates>
            </CalendarBody>
          </CalendarContainer>
        </CalendarWrapper>
        {showScheduleForm && (
          <ScheduleForm
            videoProviders={videoProviders}
            setSelectedDate={setSelectedDate}
            setSelectedEvent={setSelectedEvent}
            selectedDate={selectedDate}
            selectedEvent={selectedEvent}
            setSelectedFlow={setSelectedFlow}
            selectedFlow={selectedFlow}
            groupSizeRef={groupSizeRef}
            flowSelectRef={flowSelectRef}
            calendarEvents={calendarEvents}
            setCalendarEvents={setCalendarEvents}
            _loadCalendar={_loadCalendar}
          />
        )}
      </Wrapper>
    </PageContainer>
  );
};
