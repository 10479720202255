import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { mobileAppStore, saveToStoreAndDevice } from "../store/store";
import styled from "styled-components";
import { LargeClose } from "../../../assets/icons/Icons";
import confetti from "canvas-confetti";
import { postSessionStat } from "../apiRequests/postSessionStat";
import { FormattedMessage } from "react-intl";

const defaults = {
  spread: 360,
  ticks: 100,
  gravity: 0,
  decay: 0.97,
  startVelocity: 10,
  zIndex: 0,
  colors: ["900", "ccc", "FFBD00", "E89400", "FFCA6C", "FDFFB8"],
};

function shoot() {
  confetti({
    ...defaults,
    particleCount: 40,
    scalar: 1.2,
    shapes: ["star"],
  });

  confetti({
    ...defaults,
    particleCount: 10,
    scalar: 1.25,
    shapes: ["circle"],
  });
}

const fireworks = () => {
  setTimeout(shoot, 300);
  setTimeout(shoot, 400);
  setTimeout(shoot, 500);
};

const SessionEndWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  width: 100%;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
`;

const Top = styled.div`
  #close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

const CompletionMessage = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 40px;

  h1 {
    margin: 0;
    font-family: "ITC Clearface";
  }
`;

const Section = styled.div`
  padding: 35px;
  border-bottom: 1px solid #000;

  p {
    font-size: 20px;
    margin-bottom: 15px;
  }

  &.completed-warmspaces {
    h1 {
      font-size: 50px;
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const SessionEnd: React.FC = () => {
  const params = useParams();
  const flowId = params.flowId;
  const navigate = useNavigate();

  const flowsWithData = mobileAppStore.use.flowsWithData();
  const participants = mobileAppStore.use.participants();
  const sessionStartTime = mobileAppStore.use.sessionStartTime();
  const stats = mobileAppStore.use.stats();

  const flow = flowsWithData[flowId as string] as AppFlow;

  useEffect(() => {
    saveToStoreAndDevice("showOnboarding", false);
    fireworks();
  }, []);

  useEffect(() => {
    if (sessionStartTime) {
      const seconds = (new Date().getTime() - (sessionStartTime as Date).getTime()) / 1000;
      // TODO: save this somewhere in case it fails due to lack of internet and resend it when there's internet again
      postSessionStat({
        date: sessionStartTime,
        seconds: seconds,
        partnerNames: participants.map((p) => p.name),
        flowHashId: flowId as string,
      });

      // Clear the store of the current session state
      mobileAppStore.setState({
        participants: [],
        sessionStartTime: undefined,
      });
    }
  }, []);

  return (
    <SessionEndWrapper>
      <Top>
        <a
          id="close"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
        >
          <LargeClose />
        </a>
      </Top>
      <CompletionMessage>
        <Section>
          <p>
            <FormattedMessage id="session_end.completed_flow" defaultMessage="You've completed" />
          </p>
          <h1>{flow.name}</h1>
        </Section>

        <Section className="completed-warmspaces">
          <p>
            <FormattedMessage id="session_end.warmspaces_completed" defaultMessage="Warmspaces completed" />
          </p>
          <h1>
            <FormattedMessage id="session_end.placeholder_count" defaultMessage={`${stats.sessions + 1}`} />
          </h1>
        </Section>
      </CompletionMessage>
    </SessionEndWrapper>
  );
};
