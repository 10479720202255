import React, { useEffect } from "react";
import { Empty } from "./Empty";
import { TeamsList } from "./List";
import { mobileAppStore } from "../../../../MobileApp/store/store";
import { loadMyTeamList } from "../../API/teamApi";
import { useNavigate } from "react-router";
import { FormAdd } from "grommet-icons";
import { PageHeader } from "../PageHeader";

export const Teams = () => {
  const navigate = useNavigate();
  const myTeamsHashedIds = mobileAppStore.use.myTeams() ?? [];
  const teams = mobileAppStore.use.teams();

  // If we're hitting the teams tab, refresh the data that we're using here
  useEffect(() => {
    loadMyTeamList();
  }, []);

  let myTeams = myTeamsHashedIds.map((team) => teams[team]).filter((team) => team);

  const archivedTeams = myTeams.filter((team) => !team.active);

  const filterActiveTeams = (team: Team) => {
    if (!team || !team.active) return null;

    if (team.subTeams && Array.isArray(team.subTeams)) {
      team.subTeams = team.subTeams
        .map((subteam) => filterActiveTeams(teams[subteam.hashId]))
        .filter((subteam) => subteam !== null);
    }

    return team;
  };

  myTeams = myTeamsHashedIds
    .map((teamId) => filterActiveTeams(teams[teamId]))
    .filter((team) => team !== null)
    .sort((a, b) => a.name.localeCompare(b.name));

  const [view, setView] = React.useState("teams");
  const [filteredTeams, setFilteredTeams] = React.useState<Team[]>([]);
  const [filteredArchivedTeams, setFilteredArchivedTeams] = React.useState<Team[]>([]);
  const [hasUserSearched, setHasUserSearched] = React.useState(false); // New state to track search usage

  useEffect(() => {
    if (filteredTeams.length !== myTeams.length && !hasUserSearched) {
      setFilteredTeams(myTeams);
    }
    if (filteredArchivedTeams.length !== archivedTeams.length && !hasUserSearched) {
      setFilteredArchivedTeams(archivedTeams);
    }
  }, [myTeams, archivedTeams]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    setHasUserSearched(true);

    if (view === "teams") {
      setFilteredTeams(
        myTeams.filter((team) => {
          return team.name.toLowerCase().includes(searchValue);
        }),
      );
    } else {
      setFilteredArchivedTeams(
        archivedTeams.filter((team) => {
          return team.name.toLowerCase().includes(searchValue);
        }),
      );
    }
  };

  return (
    <>
      <PageHeader
        searchBarPresent={true}
        handleSearch={handleSearch}
        searchBarPlaceholder="Search by name"
        buttons={[
          {
            label: "Teams",
            count: myTeamsHashedIds.length || "0",
            isActive: view === "teams",
            onClick: () => setView("teams"),
          },
          {
            label: "Archived",
            count: archivedTeams.length || "0",
            isActive: view === "archived",
            onClick: () => setView("archived"),
          },
        ]}
        extraButton={{
          icon: <FormAdd color="#fff" />,
          show: myTeamsHashedIds.length > 0,
          label: "Create Team",
          onClick: () => navigate("create-team"),
        }}
      />

      {myTeamsHashedIds.length === 0 ? (
        <Empty />
      ) : (
        <TeamsList myTeams={view === "teams" ? filteredTeams : filteredArchivedTeams} />
      )}
    </>
  );
};
