import React from "react";
import styled from "styled-components";
import Button from "../../Button";
import { useNavigate } from "react-router";

const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 500px;
  box-sizing: border-box;
  border-radius: 8px;
  border: #757575 1px solid;
  background-color: #333;
  padding: 15px;
  gap: 5px;
  h1 {
    font-size: 22px;
    margin: 0;
  }
  p {
    font-size: 16px;
    color: #fff;
    margin: 0;
    text-align: center;
    margin-bottom: 15px;
  }
`;

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

export const Empty = () => {
  const navigate = useNavigate();

  return (
    <StyledBody>
      <StyledMessage>
        <h1>No Teams yet</h1>
        <p>Create a team to start inviting your colleagues to Warmspace and scheduling flows.</p>
        <Button
          onClick={() => {
            navigate("/teams/create-team");
          }}
        >
          Create Team
        </Button>
      </StyledMessage>
    </StyledBody>
  );
};
