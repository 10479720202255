import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import warmspaceLogoColor from "../../assets/warmspace_logo_color.svg";
import participantCountIcon from "../../assets/icons/participant_count_icon.svg";
import timeIcon from "../../assets/icons/time_icon.svg";
import { mobileAppStore, refreshStoreFromApi } from "./store/store";
import { NavigateFunction, useNavigate } from "react-router";
import { FormattedMessage } from "react-intl";
import { truncate } from "../InPersonSession/InPersonSession";

import align from "../../assets/flowImages/align.png";
import commit from "../../assets/flowImages/commit.png";
import feedback from "../../assets/flowImages/feedback.png";
import ignite from "../../assets/flowImages/ignite.png";
import integrate from "../../assets/flowImages/integrate.png";
import resolve from "../../assets/flowImages/resolve.png";
import { TeamsPromo } from "./Modals/TeamsPromo";

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh);
  box-sizing: border-box;
  z-index: 0;
`;

export const Inner = styled.div`
  padding-bottom: 250px;
`;

export const Section = styled.section`
  box-sizing: border-box;
  padding: 0px 20px;
  width: 100%;

  #logo {
    margin-top: 10px;
  }

  h1 {
    font-family: "ITC Clearface", sans-serif;
    font-size: 40px;
    font-weight: 500;
    margin: 0;
    margin-top: 15px;
  }

  h2 {
    font-size: 22px;
    font-weight: bold;
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    display: none;
    font-size: 24px;
    margin-bottom: 30px;
  }

  &.onboarding {
    text-align: center;

    p {
      display: block;
      border: 1px solid #64646402;
      background-color: #0000000f;
      border-radius: 10px;
      padding: 20px;
      // animation: pulse 3s infinite;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }

    h1 {
      margin-top: 30px;
    }
  }
`;

export const FeaturedFlowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;

  background-color: light-dark(#fff, #232323);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;

  max-width: 100%;
  max-height: 110px;
  flex-shrink: 0;

  &.small {
    margin-bottom: 10px;
    max-width: 85%;
    .image {
      width: 80px;
    }

    &.teamsPromo {
      .image {
        width: 90px;
        padding: 10px;
        padding-right: 0px;
      }
    }

    .description {
      h3 {
        font-size: 16px;
        margin-bottom: 3px;
      }

      p {
        font-size: 12px;

        height: 32px;

        overflow: hidden;
        text-overflow: ellipsis;
      }

      .stats {
        font-size: 14px;
      }
    }
  }

  .image {
    width: 135px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 5px;
    padding-right: 10px;
    justify-content: space-between;

    h3 {
      font-family: "ITC Clearface", sans-serif;
      font-size: 23px;
      margin: 0;
    }

    p {
      font-size: 16px;
      margin: 0;
    }

    .stats {
      margin-top: 3px;
      margin-bottom: 3px;
      display: flex;
      gap: 25px;
      font-size: 16px;
      width: 100%;

      padding-right: 14px;
      box-sizing: border-box;

      span {
        display: flex;
        gap: 5px;
      }

      img {
        width: 15px;
      }
    }
  }
`;

export const ScrollableFlowRow = styled.div`
  display: flex;
  overflow-x: scroll;
  gap: 15px;
  padding: 0px 20px;
`;

export const VerticalFlowRow = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

// Random nice css gradient
function randomColor() {
  return "#000000".replace(/0/g, function () {
    return (~~(Math.random() * 16)).toString(16);
  });
}

export function randomGradient() {
  return `linear-gradient(135deg, ${randomColor()}, ${randomColor()})`;
}

export function renderAppFlows(
  appFlows: AppFlows,
  navigate: NavigateFunction,
  showOnboarding: boolean,
  showTeamsPromoModal: () => void,
  scrollHorizontally: boolean = true,
): ReactNode {
  if (appFlows.flowLists && appFlows.flowLists.length > 0) {
    return appFlows.flowLists.map((a) =>
      renderAppFlows(a, navigate, showOnboarding, showTeamsPromoModal, scrollHorizontally),
    );
  } else {
    if (appFlows.listName === "Teams") {
      return; // Skip teams because we make our own special one that has a different behaviour for all flows except ignite.
    }
    const renderedFlows = appFlows.flows!.map((flow: AppFlow) => {
      return (
        <FeaturedFlowWrapper
          className={[flow.teamsPromo ? "teamsPromo" : "", "small"].join(" ")}
          key={flow.hashId}
          onClick={() => {
            if (flow.teamsPromo && flow.name != "Ignite") {
              showTeamsPromoModal();
              return;
            }

            if (showOnboarding) {
              navigate(`/flows/${flow.hashId}/start`);
            } else {
              navigate(`/flows/${flow.hashId}`);
            }
          }}
        >
          <div className="image">
            <img src={(flow.teamsPromo ? "" : process.env.WARMSPACE_SCHEDULE_BACKEND_URL) + flow.imageUrl + "?w=512"} />
          </div>
          <div className="description">
            <div>
              <h3>{flow.name}</h3>
              <p dangerouslySetInnerHTML={{ __html: truncate(flow.description || "no description", 53) }}></p>
            </div>
            <div className="stats">
              {/* <span>
                <img src={starIcon} />
                {flow.rating}
              </span> */}
              <span>
                <img src={timeIcon} />
                <FormattedMessage
                  defaultMessage="{flowDuration}m"
                  id="mobile_home.flow_duration"
                  values={{ flowDuration: flow.duration }}
                />
              </span>
              <span>
                <img src={participantCountIcon} />
                {flow.min != flow.max && (
                  <FormattedMessage
                    defaultMessage="{minParticipants}-{maxParticipants}"
                    id="mobile_home.participant_count"
                    values={{ minParticipants: flow.min, maxParticipants: flow.max }}
                  />
                )}
                {flow.min == flow.max && (
                  <FormattedMessage
                    defaultMessage="{minParticipants}"
                    id="mobile_home.participant_count"
                    values={{ minParticipants: flow.min }}
                  />
                )}
              </span>
            </div>
          </div>
        </FeaturedFlowWrapper>
      );
    });
    return (
      <div key={appFlows.listName}>
        <Section>
          <h2>{appFlows.listName}</h2>
        </Section>
        {scrollHorizontally ? (
          <ScrollableFlowRow className={appFlows.teamsPromo ? "teamsPromo" : ""}>{renderedFlows}</ScrollableFlowRow>
        ) : (
          <VerticalFlowRow>{renderedFlows}</VerticalFlowRow>
        )}
      </div>
    );
  }
}

function findTeamsList(flows: AppFlows): AppFlows | undefined {
  if (!flows.flowLists) {
    return undefined;
  }

  if (flows.flowLists && flows.flowLists.length > 0) {
    return flows.flowLists.find((a) => findTeamsList(a));
  }

  return flows.listName === "Teams" ? flows : undefined;
}

export const createWarmspaceAtWorkList = (flows: AppFlows) => {
  // Find teams list if it exists
  if (!flows.flowLists) {
    return null;
  }

  const originalTeamsList = findTeamsList(flows);
  const igniteFlow = originalTeamsList?.flowLists
    ?.find((a) => a.listName === "Teams")
    ?.flows?.find((flow) => flow.name === "Ignite");

  if (!originalTeamsList) {
    return null;
  }

  const teamsList = {} as AppFlows;

  teamsList.listName = "Use Warmspace at work";
  teamsList.teamsPromo = true;
  teamsList.flows = [];
  teamsList.flowLists = [];

  teamsList.flows.push(
    {
      name: "Ignite",
      hashId: igniteFlow?.hashId || "ignite",
      description:
        "Increase connection, trust and meeting presence with this quick check-in. Use Warmspace every day with the Check-in flow, such as in a stand-up or to start or end a normal meeting. Ensure that everyone gets a little dose of personal contact and progressively deepens their relationship with each other and the team as a whole.",
      imageUrl: ignite,
      teamsPromo: true,
      rating: 4.8,
      duration: 5,
      min: 2,
      max: 10,
    } as AppFlow,
    {
      name: "Align",
      hashId: "align",
      description:
        "Strengthen team purpose, alignment and engagement. Take your team deeper with an Align flow and have groups big and small get clear on where you’re at and what you really need. You will all come out the other end excited and engaged and ready to collaborate.",
      imageUrl: align,
      teamsPromo: true,
      rating: 4.7,
      duration: 30,
      min: 3,
      max: 60,
    } as AppFlow,
    {
      name: "Commit",
      hashId: "commit",
      description:
        "Build clarity, confidence and commitment. When action is called for, your team launches the Commit flow. Get clear on where you want to go and how each team member will contribute to the goal. Empower everyone to feel inspired and committed to doing their part.",
      imageUrl: commit,
      teamsPromo: true,
      rating: 4.9,
      duration: 30,
      min: 3,
      max: 60,
    } as AppFlow,
    {
      name: "Integrate",
      hashId: "integrate",
      description:
        "Activate learning and growth, and build resilience. When something big happens in your business, whether good or bad, activate the Integrate flow. Use your shared knowledge and unique capacities to process the news together and turn it into learnings and actions.",
      imageUrl: integrate,
      teamsPromo: true,
      rating: 4.6,
      duration: 30,
      min: 2,
      max: 12,
    } as AppFlow,
    {
      name: "Feedback",
      hashId: "feedback",
      description:
        "Build relationships instead of straining them. When a team member has a blind spot and their actions are harming others, the Feedback flow offers a powerful, yet kind process to share without causing pain or defensiveness. It surprisingly deepens the relationship between the giver and receiver.",
      imageUrl: feedback,
      teamsPromo: true,
      rating: 4.8,
      duration: 30,
      min: 2,
      max: 2,
    } as AppFlow,
    {
      name: "Resolve",
      hashId: "resolve",
      description:
        "Identify and resolve critical issues on demand. When the issues are systemic, the Resolve flow facilitates a rapid exploration of your challenges. Generate creative and innovative solutions to bring the team back on track, with everyone rowing in the same direction.",
      imageUrl: resolve,
      teamsPromo: true,
      rating: 4.7,
      duration: 45,
      min: 3,
      max: 10,
    } as AppFlow,
  );

  return teamsList;
};

const Home: React.FC = () => {
  const flows = mobileAppStore.use.flows();
  const warmspaceAtWork = createWarmspaceAtWorkList(flows);

  const currentUser = mobileAppStore.use.authResponse()?.currentUser;
  const showOnboarding = mobileAppStore.use.showOnboarding();
  const [showTeamsPromoModal, setShowTeamsPromoModal] = React.useState(false);

  // Commented out, in dev I uncomment to trigger onboarding again.
  // saveToStoreAndDevice("showOnboarding", true);

  const navigate = useNavigate();

  // If we arrive here without a current user, then login first
  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
    return () => {
      // Cleanup
    };
  }, [currentUser]);

  useEffect(() => {
    const refreshStore = async () => {
      await refreshStoreFromApi();
    };

    refreshStore();
  }, []);

  if (!currentUser) {
    return <div></div>;
  }

  return (
    <Wrapper>
      <TeamsPromo
        visible={showTeamsPromoModal}
        hide={() => {
          setShowTeamsPromoModal(false);
        }}
      />
      <Inner>
        <Section className={showOnboarding ? "onboarding" : ""}>
          <img id="logo" src={warmspaceLogoColor} width="150px" style={{ position: "relative", left: "-5px" }} />
        </Section>
        <Section className={showOnboarding ? "onboarding" : ""}>
          <h1>
            <FormattedMessage
              defaultMessage="Hi {userName}"
              id="mobile_home.hi_user"
              values={{ userName: currentUser.fullName }}
            />
          </h1>
          <p>
            Welcome to Warmspace👋 <br />
            Let’s try running your first session. Select any of our Flows below to continue 👇
          </p>
        </Section>

        {renderAppFlows(
          flows,
          navigate,
          showOnboarding,
          () => {
            setShowTeamsPromoModal(true);
          },
          flows.flowLists && flows.flowLists.length >= 2,
        )}

        {warmspaceAtWork &&
          renderAppFlows(warmspaceAtWork, navigate, showOnboarding, () => {
            setShowTeamsPromoModal(true);
          })}
      </Inner>
    </Wrapper>
  );
};

export default Home;
