import React from "react";
import styled from "styled-components";
import SearchBar from "../SearchBar";
import Button from "../Button";

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;

  &.no-subtitle {
    margin-bottom: 20px;
  }
`;

const FilterArea = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  button {
    background-color: var(--clr-bg-150);
    color: var(--clr-fg-500);
    border: none;
    border-radius: 8px;
    padding: 3px 10px;
    cursor: pointer;
    box-sizing: border-box;
    margin-right: -10px;
    border: 1px solid var(--clr-fg-900);
    border-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      background-color: var(--clr-bg-200);
      scale: 1.05;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px solid var(--clr-fg-900);
    }

    font-size: 16px;

    &.active {
      color: var(--clr-fg-300);
      background-color: var(--clr-bg-400);
      font-weight: bold;
    }

    @media (max-width: 768px) {
      span {
        display: none;
      }
    }
  }
`;

const StyledTitle = styled.div`
  h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }
`;

const StyledSubTitle = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
`;

export const PageHeader = ({
  searchBarPresent,
  searchBarPlaceholder,
  handleSearch,
  title,
  subtitle,
  buttons,
  extraButton,
}: {
  searchBarPresent: boolean;
  searchBarPlaceholder?: string;
  handleSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  subtitle?: string;
  buttons: {
    label: string | React.ReactNode;
    count?: number | string;
    isActive: boolean;
    onClick: () => void;
  }[];
  extraButton?: {
    icon?: React.ReactNode;
    show: boolean;
    label: string;
    onClick: () => void;
    type?: "main" | "secondary";
  };
}) => {
  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "numeric" };
    return `created ${date.toLocaleDateString("en-US", options)}`;
  };

  return (
    <>
      <StyledHeader className={!subtitle ? "no-subtitle" : ""}>
        <FilterArea>
          {searchBarPresent && handleSearch ? (
            <SearchBar placeholder={searchBarPlaceholder || ""} handleSearch={handleSearch} />
          ) : (
            title && (
              <StyledTitle>
                <h1>{title}</h1>
              </StyledTitle>
            )
          )}
          {buttons.map(({ label, count, isActive, onClick }, index) => (
            <button key={index} className={isActive ? "active" : ""} onClick={onClick}>
              {count && <span>{count} </span>}
              {label}
            </button>
          ))}
        </FilterArea>
        {extraButton?.show && (
          <Button onClick={extraButton.onClick} className={extraButton.type === "secondary" ? "secondary" : ""}>
            {extraButton.icon && extraButton.icon}
            <span>{extraButton.label}</span>
          </Button>
        )}
      </StyledHeader>
      {subtitle && <StyledSubTitle>{formatDate(subtitle)}</StyledSubTitle>}
    </>
  );
};
