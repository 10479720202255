import styled from "styled-components";

export const HOUR_HEIGHT = 50;

export const SecondaryButton = styled.button`
  border: 1px solid #555;
  background-color: transparent;
  color: light-dark(#000, #fff);
  padding: 0px 14px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  height: 36px;
  font-weight: bold;

  &:disabled {
    background-color: light-dark(#eee, #444);
    border: 1px solid light-dark(#aaa, #aaa);
    color: light-dark(#777, #aaa);
    cursor: default;
    &:hover {
      background-color: light-dark(#eee, #444);
      border: 1px solid light-dark(#aaa, #aaa);
    }
  }

  &:hover {
    background-color: light-dark(#ccc, #333);
    border-color: light-dark(#222, #fff);
  }
`;

export const IconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  width: 32px;
  border: 1px solid light-dark(#333, #555);

  border-radius: 8px;

  svg {
    position: relative;
    fill: light-dark(#000, #fff);
    stroke: light-dark(#000, #fff);
    left: -1px;
  }

  &:hover {
    background-color: light-dark(#ccc, #333);
    border: 1px solid light-dark(#000, #fff);
  }

  &.flipped {
    transform: rotate(180deg);
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  border-radius: 8px;
  overflow: hidden;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CalendarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 600px;
  overflow-x: auto;
  border-radius: 8px;
  scrollbar-color: light-dark(#ccc, #444) light-dark(#eee, #222);
  z-index: 1;
  position: relative;

  @media (max-width: 600px) {
    height: 300px;
  }

  /* WebKit and Chromiums */

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: light-dark(#eee, #222);
  }

  &::-webkit-scrollbar-thumb {
    background: light-dark(#ddd, #444);
    border-radius: 5px;
  }
`;

export const CalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  gap: 6px;

  @media (max-width: 600px) {
    padding-right: 0px;
    h2 {
      font-size: 16px;
    }
  }

  select {
    border-radius: 8px;
    padding: 4px 10px;
    background-color: transparent;
    font-size: 16px;
    font-weight: bold;
    text-overflow: ellipsis;
    width: 120px;
  }

  div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .actions {
    display: flex;

    align-items: center;
    gap: 10px;
  }

  h2 {
    margin: 0px;

    text-align: center;
    line-height: 1em;
    font-size: 20px;
    width: 200px;
    small {
      font-size: 14px;
      color: light-dark(#777, #bbb);
    }
  }
`;

export const CalendarBody = styled.div`
  background-color: light-dark(#ccc, #222);
  min-height: 400px;
  flex-shrink: 0;
  width: 100%;
  min-width: 600px;
  display: flex;
  flex-direction: row;
`;

export const Dates = styled.div`
  display: flex;
  background-color: light-dark(#eee, #222);
  z-index: 200;
  width: 100%;
  position: relative;
`;

export const Day = styled.div<{ days: number }>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: calc(100% / ${({ days = 7 }) => days});
  text-align: center;
  align-items: center;

  &.weekend {
    background-color: light-dark(#e9e9e9, #262626);
  }

  &:last-child {
    div {
      border-right: none;
    }
  }
`;

export const DateHeader = styled.div`
  border-bottom: 1px solid light-dark(#e1e1e1, #262626);
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 60px;

  position: sticky;
  top: 0;
  background-color: light-dark(#e1e1e1, #262626);
  z-index: 120;
  border-right: 1px solid light-dark(#e1e1e1, #262626);
  box-sizing: border-box;

  &:last-child {
    border-right: none;
  }

  span {
    font-size: 18px;
  }

  span.active {
    background-color: rgb(255, 94, 0);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
`;

export const Hours = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HourBox = styled.div`
  height: ${HOUR_HEIGHT}px;
  width: 100%;
  border-right: 1px solid light-dark(#ccc, #282828);
  border-bottom: 1px solid light-dark(#ccc, #282828);
  position: relative;
  box-sizing: border-box;
  display: flex;
  gap: 5px;
  cursor: pointer;

  span {
    position: absolute;
    bottom: -10px;
    right: 12px;
  }

  hr {
    position: absolute;
    bottom: -8px;
    right: -3px;
    width: 10px;
    height: 1px;
    background-color: #333;
    border: none;
  }
`;

export const CalendarEvent = styled.div`
  position: absolute;
  border-left: 5px solid light-dark(#bbb, #b6b2b1);
  border-radius: 0px;
  background-color: light-dark(#ddd, #4a4747);
  z-index: 90;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  overflow: hidden;
  min-width: 25px;
  min-height: 30px;
  padding: 5px;
  transition: opacity 0.2s;
  opacity: 0.9;
  width: calc(100% - 5px);
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-right: 20px;

  div {
    flex-shrink: 0;
  }

  .flowInEvent {
  }

  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
    height: 16px;
  }

  &.tentative {
    background-color: light-dark(#e1e1e1, rgb(90, 81, 72));
    font-weight: bold;
    border: 1.5px dashed #cb7411;
  }

  &.scheduled {
    border-left: 5px solid #cb7411;
  }

  &.selected {
    border-left: 5px solid #cb7411;
    background-color: #cb7411 !important;
  }

  .eventTitle {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .eventTime {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: light-dark(#e8e8e8, rgb(90, 81, 72));
    cursor: pointer;
  }

  transition: height 0.7s;

  &.expandOnHover:hover {
    height: auto;
    z-index: 100;
  }
`;

export const CalendarLoading = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: light-dark(#eee, #262626);
  border-radius: 8px;
  font-size: 20px;
  color: light-dark(#333, #eee);
  font-weight: bold;
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.7s;

  &.loading {
    opacity: 0.8;
  }
`;

export const Form = styled.form`
  flex-shrink: 0;
  width: 100%;
  background-color: light-dark(#eee, #222);
  border-radius: 8px;
  display: inline-flex;
  gap: 20px;
  flex-direction: column;
  padding: 14px;
  box-sizing: border-box;
  border: 1px solid light-dark(#ccc, #444);
  box-sizing: border-box;

  hr {
    width: 100%;
    border: none;
    height: 1px;
    background-color: light-dark(#c9c9c9, #262626);
  }
`;

export const ScheduleFormWrapper = styled(Form)`
  width: 285px;
  height: 100%;
  gap: 10px;
  margin-top: 50px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const SideHourLabels = styled.div`
  display: flex;
  flex-direction: column;

  box-sizing: border-box;
  position: sticky;
  left: 0;
  z-index: 300;
  background-color: light-dark(#e1e1e1, #262626);
  font-size: 14px;
  color: light-dark(#333, #eee);
  text-align: right;

  span {
    height: ${HOUR_HEIGHT}px;
    position: relative;
    padding-right: 10px;
  }

  span.hidden {
    opacity: 0;
  }

  hr {
    position: absolute;
    right: 0px;
    top: 1px;
    height: 1px;
    border: 0;
    background-color: light-dark(#eee, #333);
    width: 5px;
  }
`;

export const TopRightCornerCover = styled.div`
  position: sticky;
  flex-shrink: 0;
  top: 0;
  left: 0;
  background-color: light-dark(#e1e1e1, #262626);

  width: 60px;

  box-sizing: border-box;
  height: 51px;
  z-index: 350;
`;

export const FormLabel = styled.label``;

export const InlineFieldGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  justify-content: space-between;

  ${FormLabel} {
  }

  div {
    display: flex;
    gap: 5px;
    flex-direction: column;
    width: 100%;
  }
`;

export const FieldGroup = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

export const ButtonGroup = styled.div`
  display: flex;

  button {
    background-color: light-dark(#ccc, #333);
    color: light-dark(#000, #fff);
    border: 1px solid light-dark(#bbb, #666);
    padding: 8px 10px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;

    &.active {
      background-color: #cb7411;
      color: #fff;

      &:hover {
        background-color: #cb7411;
      }
    }

    &:hover {
      background-color: light-dark(#bbb, #555);
    }

    &:first-child {
      border-radius: 5px 0px 0px 5px;
      border-right: none;
    }

    &:last-child {
      border-radius: 0px 5px 5px 0px;
      border-left: none;
    }
  }
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
