import React, { FC } from "react";
import { styled } from "styled-components";

const StyledSelect = styled.select`
  font-weight: normal;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #888;
  border-radius: 5px;
  width: 100%;

  box-sizing: border-box;

  padding: 8px 9px;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #666;
  box-sizing: border-box;
  font-size: 16px;
  -webkit-appearance: none;
  appearance: none;

  cursor: pointer;
  background-color: light-dark(#e8e8e8, #333);
`;

interface Props {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  placeholder?: string;
  ref?: React.RefObject<HTMLSelectElement | null>;
  onFocus?: (e: React.FocusEvent<HTMLSelectElement>) => void;
  className?: string;
  id?: string;
  type?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  disabled?: boolean | undefined;
}

export const Select: FC<Props> = ({ children, ...props }) => {
  return <StyledSelect {...props}> {children} </StyledSelect>;
};
