import { useEffect, useRef } from "react";

export function useTripleCPress(callback: () => void, delay = 500) {
  const pressTimesRef = useRef<number[]>([]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key.toLowerCase() !== "c") return;

      const now = Date.now();
      pressTimesRef.current.push(now);

      // Keep only the last 3 presses
      if (pressTimesRef.current.length > 3) {
        pressTimesRef.current.shift();
      }

      // Check timing between the 3 presses
      if (pressTimesRef.current.length === 3 && pressTimesRef.current[2] - pressTimesRef.current[0] <= delay) {
        pressTimesRef.current = []; // reset after triggering
        callback();
      }
    };

    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, [callback, delay]);
}
