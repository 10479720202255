import { getAppApiCall, postAppApiCall } from "../../../MobileApp/apiRequests/ApiUtil";

function dateToParam(from: Date) {
  const dateString = JSON.stringify(from).replace(/"/g, "");
  const suffixIdx = dateString.indexOf(".000Z");
  return suffixIdx > 0 ? dateString.substring(0, suffixIdx) : dateString;
}

export const loadCalendar = async (from: Date, until: Date, teamHashId?: string): Promise<CalendarEvent[]> => {
  let path = "calendar";
  if (teamHashId) path += `/${teamHashId}`;
  path += `?calendar-from=${dateToParam(from)}`;
  path += `&calendar-until=${dateToParam(until)}`;

  return getAppApiCall(path);
};

export const scheduleFlow = async (scheduleFlowRequest: ScheduleFlowRequest): Promise<CalendarEvent> => {
  return postAppApiCall("calendar", scheduleFlowRequest);
};
