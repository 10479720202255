import React from "react";
import { mobileAppStore } from "../../../../MobileApp/store/store";
import { styled } from "styled-components";
import { getLevel } from "../../../../../components/UI/Participants/getLevel";
import { Dot, Flame } from "../../../../../assets/icons/Icons";
import { useNavigate } from "react-router";

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #403c3c;
  color: var(--col-fg-0);
  font-weight: bold;
  font-size: 16px;
`;

export const StyledHeader = styled.thead`
  background-color: var(--clr-bg-300);
  border-bottom: 4px solid #797373;

  td {
    padding: 7px 18px;
  }
`;

const StyledRow = styled.tr<{ level?: number }>`
  background-color: ${({ level }) =>
    level === 2 ? "var(--clr-bg-200)" : level === 3 ? "var(--clr-bg-300)" : "var(--clr-bg-400)"};
  cursor: pointer;

  &:hover {
    background-color: light-dark(#ccc, #777);
  }
`;

export const StyledCell = styled.td<{ level?: number }>`
  padding: 14px 18px;
  text-align: center;

  &:first-child {
    text-align: left;
    width: 70%;
    padding-left: ${({ level }) => (level === 2 ? "35px" : level === 3 ? "59px" : "18px")};
  }
`;

const TeamRow = ({
  team,
  level = 1,
  newRecommendations,
  navigate,
  heatTypeToLevels,
}: {
  team: Team;
  level?: number;
  newRecommendations: boolean;
  navigate: (path: string) => void;
  heatTypeToLevels: any;
}) => {
  const teamLevel = getLevel(team.avgCumulativeHeat, heatTypeToLevels).level;

  return (
    <>
      <StyledRow level={level} onClick={() => navigate(`/teams/${team.hashId}`)}>
        <StyledCell level={level} style={{ gap: "5px", display: "flex", alignItems: "center" }}>
          {newRecommendations && <Dot fill="#FF0000" />}
          {team.name}
        </StyledCell>
        {team.active && (
          <>
            <StyledCell>{team.avgRecentHeat}</StyledCell>
            <StyledCell>{teamLevel}</StyledCell>
            <StyledCell>{`${Math.round(team.engagement * 100)}%`}</StyledCell>
          </>
        )}
      </StyledRow>

      {team.active &&
        team.subTeams?.map((subTeam) => (
          <TeamRow
            key={subTeam.hashId}
            team={subTeam}
            level={level + 1}
            newRecommendations={newRecommendations}
            navigate={navigate}
            heatTypeToLevels={heatTypeToLevels}
          />
        ))}
    </>
  );
};

export const TeamsList = ({ myTeams }: { myTeams: Team[] }) => {
  const navigate = useNavigate();
  const settings = mobileAppStore.use.settings();
  const heatType = mobileAppStore.use.heatType();
  const heatTypeToLevels = settings.heatTypeToLevels[heatType];
  const [newRecommendations] = React.useState<boolean>(false);

  const hasActiveTeams = myTeams?.some((team) => team.active);

  return (
    <StyledTable>
      <StyledHeader>
        <tr>
          <StyledCell>Name</StyledCell>
          {hasActiveTeams && (
            <>
              <StyledCell>
                <div style={{ display: "flex", gap: "5px", alignItems: "center", justifyContent: "center" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                    <span>Recent</span>
                    <small>Avg</small>
                  </div>
                  <Flame fill="#FF8C1A" width="20px" height="20px" />
                </div>
              </StyledCell>
              <StyledCell>Level</StyledCell>
              <StyledCell>Engagement</StyledCell>
            </>
          )}
        </tr>
      </StyledHeader>
      <tbody>
        {myTeams?.map((team) => (
          <TeamRow
            key={team.hashId}
            team={team}
            newRecommendations={newRecommendations}
            navigate={navigate}
            heatTypeToLevels={heatTypeToLevels}
          />
        ))}
      </tbody>
    </StyledTable>
  );
};
