import React, { useEffect, useState, useCallback } from "react";
import { PageHeader } from "../PageHeader";
import { useNavigate, useParams } from "react-router";
import { loadTeam, updateTeam } from "../../API/teamApi";
import toast from "react-simple-toasts";
import { styled } from "styled-components";
import { TextInput } from "../../../TextInput/TextInput";

const SettingsContainer = styled.div`
  gap: 18px;
  max-width: 800px;
  width: 100%;
`;

export const SettingsSection = styled.div`
  background-color: var(--clr-bg-200);
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.danger {
    border: 1px solid #bc5a5a;

    b {
      color: #e56767;
    }

    button {
      color: light-dark(#ea5555, #df9d9d);
      border: 1px solid light-dark(#ea5555, #df9d9d);
    }
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  line-height: normal;
  gap: 8px;
  font-weight: 600;
`;

export const CheckBox = styled.input`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 14px;
  border: 2px solid #ff9900;
  position: relative;
  cursor: pointer;
  background: transparent;
  display: inline-block;
  border-radius: 4px;
  margin: 3px 0;

  &:checked {
    &:before {
      display: block;
    }
    &:after {
      content: "";
      position: absolute;
      top: -6px;
      left: 5px;
      width: 6px;
      height: 12px;
      border-bottom: 2px solid white;
      border-right: 2px solid white;
      transform: rotate(40deg);
    }
  }
`;

export const StyledButton = styled.button`
  background-color: transparent;
  color: var(--clr-fg-200);
  font-weight: 600;
  padding: 5px 8px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid var(--clr-fg-200);
  height: fit-content;
`;

export const StyledInfo = styled.div`
  color: var(--clr-fg-300);
  font-size: 16px;
  width: 100%;

  a {
    text-decoration: underline;
  }
`;

export const SectionTitle = styled.div`
  color: light-dark(#000, #d3d3d3);
  font-size: 16px;
  margin-bottom: 5px;

  &.danger {
    font-weight: bold;
    color: light-dark(#bc5a5a, #df9d9d);
  }
`;

export const SectionStyling = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  p {
    margin: 0;
  }

  @media (max-width: 600px) {
    span {
      display: none;
    }
  }
`;

const ResponsiveRow = styled.div`
  display: flex;
  width: 50%;
  gap: 10px;
  align-items: center;

  @media (max-width: 600px) {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
`;

export const Settings = () => {
  const navigate = useNavigate();
  const params = useParams();
  const teamHashId = params.teamHashId as string;

  const [team, setTeam] = useState<Team | undefined>();
  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState({
    teamName: "",
    teamVisibility: true,
  });

  const handleChange = (key: keyof typeof formState, value: any) => {
    setFormState((prev) => {
      const newState = { ...prev, [key]: value };
      return newState;
    });
  };

  // Load team data
  useEffect(() => {
    if (teamHashId) {
      loadTeam(teamHashId)
        .then((team) => {
          if (team) {
            setTeam(team);
            setFormState({
              teamName: team.name,
              teamVisibility: team.active,
            });
          }
        })
        .catch(() => toast("Failed to load team"))
        .finally(() => setLoading(false));
    }
  }, [teamHashId]);

  const handleSaveTeam = useCallback(
    (updatedFormState: { teamName: any; teamVisibility: any }) => {
      if (!team) return;

      const updatedTeam = {
        ...team,
        name: updatedFormState.teamName,
        active: updatedFormState.teamVisibility,
      };

      updateTeam(team, {
        name: updatedFormState.teamName,
        active: updatedFormState.teamVisibility,
      })
        .then(() => {
          toast("Team updated successfully");
          setTeam(updatedTeam);
        })
        .catch(() => toast("Failed to update team"));
    },
    [team],
  );

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <PageHeader
        searchBarPresent={false}
        title={team?.name}
        subtitle={team?.created}
        buttons={[
          {
            label: "Overview",
            isActive: false,
            onClick: () => navigate(`/teams/${teamHashId}`, { replace: true }),
          },
          {
            label: "Settings",
            isActive: true,
            onClick: () => navigate(`/teams/${teamHashId}/settings`, { replace: true }),
          },
        ]}
      />

      <SettingsContainer>
        <SettingsSection>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <div>Team Name:</div>
            <ResponsiveRow>
              <TextInput
                type="text"
                value={formState.teamName}
                onChange={(e) => handleChange("teamName", e.target.value)}
              />
              <StyledButton
                onClick={() => {
                  handleSaveTeam(formState);

                  // TODO: Find a better way to update the page
                  setTimeout(() => window.location.reload(), 500);
                }}
              >
                Rename
              </StyledButton>
            </ResponsiveRow>
          </div>
        </SettingsSection>

        <SectionTitle>Visibility</SectionTitle>
        <SettingsSection>
          <SectionStyling>
            <ResponsiveRow style={{ width: "100%" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                <b>{formState.teamVisibility ? "Archive" : "Activate"} Team</b>
                {formState.teamVisibility ? (
                  <p>
                    Archive this team and <b>all sub teams</b>.
                    <br />
                    Progression is no longer tracked and any reminder notifications are disabled.
                  </p>
                ) : (
                  <p>
                    Activate this team and <b>all sub teams</b>.
                    <br />
                    Progression is tracked and reminder notifications are enabled.
                  </p>
                )}
              </div>
              <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                <StyledButton
                  onClick={() => {
                    setFormState((prev) => {
                      const newState = { ...prev, teamVisibility: !prev.teamVisibility };

                      setTimeout(() => handleSaveTeam(newState), 0);

                      return newState;
                    });
                  }}
                >
                  {formState.teamVisibility ? "Archive Team" : "Activate Team"}
                </StyledButton>
              </div>
            </ResponsiveRow>
          </SectionStyling>
        </SettingsSection>
      </SettingsContainer>
    </>
  );
};
