import { FormSearch } from "grommet-icons";
import React from "react";
import styled from "styled-components";

const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 44px;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  background-color: var(--clr-bg-100);
  border: var(--clr-fg-800) 1px solid;
`;

const StyledSearchBar = styled.input`
  background-color: transparent;
  color: var(--clr-fg-100);
  border: none !important;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: var(--clr-fg-600);
  }
`;

const SearchBar = ({
  placeholder,
  handleSearch,
}: {
  placeholder: string;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <SearchBarWrapper>
      <FormSearch color="var(--clr-fg-700)" />
      <StyledSearchBar placeholder={placeholder} onChange={handleSearch} />
    </SearchBarWrapper>
  );
};

export default SearchBar;
