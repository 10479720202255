import { postAppApiCall } from "./ApiUtil";

export interface SignupRequest {
  name: string;
  email: string;
  school: string | undefined; // Which community is this person joining? e.g. Ridhwan (InqWell). Empty defaults to Warmspace.
  timeZone: string;
}

export const postSignup = async (signupRequest: SignupRequest) => {
  return postAppApiCall("auth/signup", signupRequest);
};
