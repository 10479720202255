import React from "react";
import styled from "styled-components";
import { SigninWithMicrosoft } from "../../../../../assets/icons/Icons";
import { appUrlPrefix, mobileAppStore } from "../../../../MobileApp/store/store";
import { Branding } from "../../../Branding/Branding";
import { Link, Navigate } from "react-router";

export const LoginWrapper = styled.div`
  width: 350px;
  align-self: center;
  margin: auto;
  background-color: light-dark(#eee, #222);
  border-radius: 8px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 25px;
  box-sizing: border-box;
  border: 1px solid light-dark(#ccc, #444);
  align-items: center;
  justify-content: space-evenly;

  input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #444;
    box-sizing: border-box;
    font-size: 18px;
  }

  button {
    margin: 0px;
    font-size: 22px;
    padding: 12px;
  }

  h2 {
    margin: 0px;
  }

  h3 {
    margin: 0px;
  }

  @media (max-width: 400px) {
    width: 100%;
    border-radius: 0px;
    border: none;
    height: 100%;
  }
`;

const MsLoginButton = styled.a`
  cursor: pointer;
`;

export function getMicrosoftLoginUrl() {
  const params = new URLSearchParams(location.search);
  const redirect = params.get("redirect-uri") || "";
  return `${appUrlPrefix}/ms/auth?&redirect-uri=${encodeURIComponent(redirect)}`;
}

export const Login: React.FC = () => {
  const currentUser = mobileAppStore.use.authResponse()?.currentUser;

  if (currentUser) return <Navigate to="/" />;

  return (
    <LoginWrapper>
      <Branding fill={"light-dark(#000, #fff)"} style={{ width: "180px", marginBottom: "0px", marginTop: "0px" }} />
      <h3>Please sign in to continue</h3>
      {/* TODO: figure out what to do with organizationId */}
      <MsLoginButton href={getMicrosoftLoginUrl()}>
        <SigninWithMicrosoft />
      </MsLoginButton>
      <Link to="/email">Sign in with e-mail</Link>
    </LoginWrapper>
  );
};
